
.Hair_theme_img_main img{
  width: 100%;
}


/*----------BAnner Style of DJDesign------*/
.bw_djdesign_banner {
  background-color: #eaecf3 !important;
  background-image: none !important;
  /* background: url("../images/diamond-jewelry-designs/djdesign-banner.png");
  width: 100%;
  height: 1000px;
  background-repeat: repeat;
  margin-top: 110px;
  padding-top: 42px;
  background-size: contain; */
}

.bw_djdesign_newsite.hair_solon_design h2 {
	font-weight: 700;
	font-size: 45px;
	line-height: 50px;
	color: #000;
	margin-bottom: 20px;
	text-align: center;
	margin-top: 150px;
}
.bw_djdesign_leftimg {
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-items: center;
}
.bw_djdesign_banner_center h6 {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #000;
}
.bw_djdesign_banner_center h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
  text-transform: capitalize;
  text-align: center;
  justify-content: center;
  color: #000;
  margin-bottom: 50px;
}
.bw_djdesign_banner_btn_box svg {
  fill: #2c52b0;
  font-size: 20px;
  display: inline-block;
  justify-content: center;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 3px;
}
.bw_djdesign_banner_btn_box {
  text-align: center;
}
.bw_djdesign_btn_explore {
  text-transform: uppercase;
  text-align: center;
  background: #ffffff;
  border-radius: 35px;
  padding: 12px 40px;
  color: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  border: 1px solid#FFF;
  margin-right: 25px;
}
.bw_djdesign_btn_explore:hover, .bw_djdesign_btn_explore:active, .bw_djdesign_btn_explore:focus {
    outline: 0 !important;
    box-shadow: 1px 2px 20px #e1e0e0 !important;
}
.bw_djdesign_checkdemo_link { 
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2px;
  border-bottom: 2px solid#000;
}
.bw_djdesign_checkdemo_link:hover {
  color: #000;
}
.bw_banner_theme_img1 {
  position: absolute;
  top: -23%;
  left: 0;
  right: 0;
}
.bw_banner_theme_img2 {
  position: relative;
  top: -45%;
  left: 0;
  right: 0;
}
.bw_banner_theme_img3 {
  position: absolute;
  top: -27%;
  left: 0;
  right: 0;
}
.bw_djdesign_threeimg_section {
  height: 400px;
}

/*    --------New Site section style -----------*/

.bw_djdesign_newsite {
  background: #fbfbfb !important;
  padding: 40px 0 !important;
}
.bw_djdesign_newsite h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: #000;
  margin-bottom: 20px;
}
.bw_djdesign_newsite p {
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #000;
  padding-bottom: 50px;
  margin-bottom: 0;
}
.bw_djdesign_theme_img_box {
  margin-bottom: 30px;
}
.bw_djdesign_theme_img_box img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  transform: scale(0.9);
}
.bw_djdesign_theme_img_box img:hover {
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.bw_djdesign_theme_img_box h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    align-items: center;
    color: #000;
    margin-bottom: 0;
    text-transform: capitalize;
}
.bwn_djdesign_them_img_outerbox {
  margin-bottom: 20px;
  position: relative;
}
.bwn_djdesign_them_img_outerbox:hover .bw_djdesign_view_more_btn {
  display: block !important;
  transition: all 0.4s ease-in;
}
.bw_djdesign_view_more_btn {
  color: #fff !important;
  background-color: #000000a3;
  border: 1px solid #000000cf;
  padding: 8px 20px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  display: none;
  text-align: center;
  position: absolute;
  bottom: 50%;
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
}
.bw_djdesign_view_more_btn:hover,
.bw_djdesign_view_more_btn:focus,
.bw_djdesign_view_more_btn:active {
  box-shadow: none;
  outline: 0;
  color: #000;
}
.bw_djdesign_theme_img_box:hover .bw_djdesign_view_more_btn {
  display: block !important;
}
.bw_djdesign_theme_img_box {
  position: relative;
}
.bw_djdesign_theme_img_box:focus {
  box-shadow: none;
  outline: 0;
}
/*-------online store section --------*/
.bw_djdesign_online_store_section {
  background-color: #eaecf3;
  padding: 40px 0;
}
.bw_djdesign_onlinestore_text h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: #000;
  margin-bottom: 20px;
  width: 80%;
}
.bw_djdesign_onlinestore_text h6 {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #000;
  margin-bottom: 10px;
}
.bw_djdesign_onlinestore_text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000;
  max-width: 730px;
  width: 100%;
}
.bw_djdesign_onlinestore_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------Website Owners section style-------------*/

.bw_djdesign_website_owner_section {
  padding: 85px 0;
}
.bw_djdesign_website_owner_section h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 70px;
  color: #000000;
}
.bw_djdesign_website_owner_section span {
  color: #2c52b0;
}
.bw_djd_onner_inner_box {
  text-align: center;
  padding: 40px 22px;
  transition: all 0.5s ease-in-out;
  border-bottom: 3px solid transparent;
  margin-bottom: 30px;
  cursor: pointer;
}
.bw_djd_onner_inner_box:hover {
  background: #ffffff;
  box-shadow: 0px 4px 54px rgba(36, 68, 4, 0.1);
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  border-color: #2c52b0;
}
.bw_djd_onner_inner_box img {
  margin-bottom: 20px;
}
.bw_djd_onner_inner_box h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #000;
}
.bw_djd_onner_inner_box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #393939;
  text-align: center;
}
/*---------Collection of Multiple Layouts Style----------*/
.bw_djdesign_slider_section {
  background: #151515;
  padding: 75px 0;
}
.bw_djdesign_slider_heading h6 {
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 12px;
}
.bw_djdesign_slider_heading h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  margin-bottom: 50px;
}
.bw_djd_slider_padding {
  padding-left: 7% !important;
  padding-right: 0;
}
/*------- Layout Slider Style---------*/
.bw_djd_layouts_slider .slick-next {
  right: 10% !important;
  top: -16% !important;
}
.bw_djd_layouts_slider .slick-next {
  width: 27px !important;
  height: 27px !important;
}
.bw_djd_layouts_slider .slick-prev {
  top: -16% !important;
  left: 93% !important;
}
.bw_djd_layouts_slider .slick-prev {
  width: 30px !important;
  height: 30px !important;
}
.bw_djd_layouts_slider img.slick-arrow.slick-prev.slick-disabled {
  opacity: 0.4;
}
.bw_djd_layouts_slider img.slick-arrow.slick-next.slick-disabled {
  opacity: 0.4;
}

/*-------Responsive device section style------*/
.bw_djdesign_responsive_devices_section {
  padding: 100px 0 300px 0;
  background-color: #fbfbfb;
}
.bw_djdesign_pixle_box {
  background: #ffffff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 677px;
  margin: 0 auto;
  padding: 32px 50px;
  text-align: center;
  position: absolute;
  bottom: -45%;
  left: 0;
  right: 0;
}
.bw_djdesign_pixle_box h4 {
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  color: #000;
}
.bw_djdesign_pixle_box p {
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  color: #828282;
  padding-bottom: 15px;
}
.bw_djd_res_icon_box {
  width: 55px;
  height: 55px;
  background-color: #3d63c321;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.bw_djd_res_icon_box svg {
  font-size: 25px;
  color: #2c52b0;
}
/* -----------tooltrip style ------------ */
.bw_toltip_bg_color {
  background-color: #2c52b0 !important;
  color: #fff !important;
  font-size: 12px !important;
}
.tooltiptextt {
  display: none;
  color: #000;
  font-size: 14px;
  z-index: 1;
}
.tooltipp:hover {
  display: block;
}

/*---------Admin Panal section style------*/
.bwn_djdesign_admin_section {
  padding: 50px 0 10px 0;
  background-color: #fbfbfb;
}
.bwn_djdesign_admin_heading h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #000;
  text-align: center;
}
.bwn_djdesign_admin_heading p {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #828282;
  width: 914px;
  margin: 0 auto;
}
.bw_djde_admin_icon {
  width: 90px;
  height: 70px;
  background-color: #7531cc;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.bw_djde_admin_icon svg {
  color: #fff;
  font-size: 30px;
}
.bw_djd_admin_text h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}
.bw_djd_admin_text p {
  color: #828282;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 300px;
}
.bwn_djdesign_admin_icon {
  border-right: 1px solid#ccc;
}
.bwn_djdesign_admin_icon img {
  width: 80px;
  height: 80px;
  margin-right: 15px;
}
.bwn_djdesign_admin_icon {
  margin: 65px 0;
  justify-content: center;
}
.bw_admin_desktop_img {
  margin: 60px 0;
}

/* Mobile view slider */
.bw_djdesign_new_site_mobile_view {
  padding: 20px 0 45px 0;
}
/* .bw_djdesign_new_site_mobile_view .slick-next,
.bw_djdesign_new_site_mobile_view .slick-prev {
  display: block !important;
} */
.bw_djdesign_new_site_mobile_view .slick-next {
	width: 45px !important;
	height: 45px !important;
	top: 100% !important;
	right: -60px !important;
	left: 0 !important;
	margin: 0 auto;
}
.bw_djdesign_new_site_mobile_view .slick-prev {
	width: 45px !important;
	height: 45px !important;
	top: 100% !important;
	left: -60px  !important;
	right: 0 !important;
	margin: 0 auto !important;
}
.bw_djdesign_new_site_mobile_view .slick-dots li.slick-active {
  border: 1px solid #000 !important;
  border-radius: 50% !important;
}
.bw_djdesign_website_owner_section_movile_view .bw_djd_onner_outbox {
  padding: 20px !important;
}

/*----- owner section slider mobile view ---------*/
.bw_djdesign_website_owner_section_movile_view
  .slick-slide.slick-active
  .bw_djd_onner_inner_box {
  background: #ffffff !important;
  box-shadow: 0px 4px 54px rgba(36, 68, 4, 0.1) !important;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  border-color: #244404;
}
.bw_djdesign_website_owner_section_movile_view .slick-next,
.bw_djdesign_website_owner_section_movile_view .slick-prev {
  display: none !important;
}

/*------- button visite demo------ */
.bw_djd_visitdemo_btn {
  background: #244404;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.bw_djd_visitdemo_btn:hover {
  color: #244404 !important;
  border-color: #244404 !important;
  background-color: #fff !important;
}
.bwn_djdesign_admin_icon.bw_design_admin_border_right {
  border-bottom: 0 !important;
  border-right: 0 !important;
}

/*---------Responsive style --------*/
@media (min-width: 320px) and (max-width: 767px) {
  .bw_djdesign_leftimg img{
    display: none;
  }
  .bw_banner_theme_img3, .bw_banner_theme_img1{
     display: none;
  }
  
  .bw_djdesign_newsite h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 150px;
    text-align: center;
  }
  .bw_djdesign_newsite p {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 5px;
    text-align: center;
  }
  .bw_djdesign_theme_img_box h6 {
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 50px;
  }
  .bw_djdesign_onlinestore_text h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    margin: 10px auto;
  }
  .bw_djdesign_onlinestore_text h6 {
    font-size: 15px;
    line-height: 22px;
  }
  .bw_djdesign_pixle_box p {
    line-height: 22px;
}
  .bw_djdesign_onlinestore_text p {
    font-size: 15px;
    line-height: 20px;
  }
  .bw_djdesign_website_owner_section h2 {
    font-size: 20px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  .bw_djd_onner_inner_box h6 {
    font-size: 17px;
    line-height: 40px;
  }
  .bw_djd_onner_inner_box p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
  }
  .bw_djd_onner_inner_box {
    padding: 20px 15px;
    margin-bottom: 10px;
  }
  .bw_djdesign_website_owner_section {
    padding: 55px 0 0 0;
  }
  .bw_djdesign_online_store_section {
    padding: 50px 0;
  }
  .bw_djdesign_slider_heading h2 {
    font-size: 22px;
    line-height: 30px;
  }
  .bw_djd_layouts_slider .slick-next {
    right: 21% !important;
  }
  .bw_djd_layouts_slider .slick-next {
    width: 20px !important;
    height: 20px !important;
  }
  .bw_djd_layouts_slider .slick-prev {
    left: 89% !important;
  }
  .bw_djd_layouts_slider .slick-prev {
    width: 20px !important;
    height: 20px !important;
  }
  .bwn_djdesign_admin_heading h2 {
    font-size: 22px;
    line-height: 32px;
  }
  .bwn_djdesign_admin_heading p {
    width: unset;
    line-height: 22px;
  }
  .bwn_djdesign_admin_icon {
    border-right: none;
  }
  .bw_djd_admin_text p {
    font-size: 16px;
    width: unset;
  }
  .bw_djd_admin_text h6 {
    font-size: 15px;
    line-height: 25px;
  }
  .bwn_djdesign_admin_icon {
    margin: 25px 0 0 0;
    border-bottom: 1px solid #ccc;
  }
  .bwn_djdesign_admin_icon img {
    width: 60px;
    height: 60px;
    margin-top: 3px;
  }
  .bw_djdesign_onlinestore_text {
    text-align: center;
  }
  .bw_djdesign_theme_img_box img {
    padding: 0;
    margin-bottom: 0;
  }
  .bw_djdesign_pixle_box {
    width: unset !important;
    padding: 30px 20px !important;
    position: unset;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.04) !important;
    position: relative;
    top: -80px;
    margin: 10px;
    z-index: 1;
  }
  .bw_djdesign_responsive_devices_section img {
    border-radius: 4px;
  }
  .bw_djdesign_responsive_devices_section {
    padding: 40px 0 0px 0;
  }
  .bw_djdesign_pixle_box h4 {
    font-size: 19px;
    line-height: 30px;
  }
  .bwn_djdesign_admin_section {
    padding: 10px 0 5px 0 !important;
  }
  .bw_djd_res_icon_box {
    width: 45px;
    height: 45px;
    margin-right: 5px;
  }
  .bw_djd_visitdemo_btn {
    padding: 7px 25px;
    font-weight: 600;
    font-size: 14px;
  }

  /*------ Banner Mobile-------- */
  .bw_djdesign_banner_center h2 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .bw_djdesign_btn_explore {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 15px;
    margin-right: 10px;
  }
  .bw_djdesign_banner_btn_box svg {
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .bw_djdesign_banner_btn_box {
    display: none;
  }
  .bw_djdesign_banner_center h6 {
    font-size: 10px;
    line-height: 25px;
    margin-bottom: 5px;
  }
  .bw_all_banner {
    padding: 120px 0 60px;
  }
  .bw_djdesign_threeimg_section {
    height: 10px;
  }
.bw_all_banner.bw_djdesign_banner {
	padding: 120px 0 120px;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bw_djdesign_banner_center h2 {
    font-size: 23px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .bw_djdesign_btn_explore {
    padding: 7px 25px;
    font-size: 16px;
    line-height: 25px;
  }
  .bw_djdesign_checkdemo_link {
    font-size: 16px;
    line-height: 25px;
  }
  .bw_djdesign_banner_center h6 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .bw_djdesign_banner_btn_box svg {
    font-size: 16px;
    margin-right: 10px;
  }
  .bw_djdesign_threeimg_section {
    height: 210px;
  }
  .bw_djdesign_newsite h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
  }
  .bw_djdesign_newsite p {
    padding-bottom: 30px;
    text-align: center;
  }
  .bw_djdesign_theme_img_box h6 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  .bw_djdesign_onlinestore_text h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .bw_djdesign_onlinestore_text h6 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .bw_djdesign_website_owner_section h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .bw_djd_onner_inner_box h6 {
    font-size: 20px;
    line-height: 40px;
  }
  .bw_djd_onner_inner_box {
    padding: 20px 22px;
    margin-bottom: 20px;
  }
  .bw_djdesign_slider_heading h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
  .bw_djdesign_slider_heading h6 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .bw_djdesign_slider_section {
    padding: 55px 0;
  }
  .bw_djdesign_website_owner_section {
    padding: 75px 0;
  }
  .bw_djdesign_pixle_box {
    width: 600px;
    bottom: -70%;
  }
  .bw_djdesign_responsive_devices_section {
    padding: 60px 0 210px 0;
  }
  .bw_djdesign_pixle_box h4 {
    font-size: 32px;
    line-height: 38px;
  }
  .bwn_djdesign_admin_heading h2 {
    font-size: 28px;
    line-height: 45px;
  }
  .bwn_djdesign_admin_heading p {
    width: 650px;
  }
  .bwn_djdesign_admin_icon {
    margin: 25px 0;
    justify-content: center;
  }
  .bwn_djdesign_admin_icon {
    border-right: 0;
  }
  .bw_djdesign_theme_img_box img {
    padding: 0;
  }
  .bwn_djdesign_admin_icon {
    border-right: 0;
    border-bottom: 1px solid #ccc !important;
    width: 400px;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .bw_djdesign_banner_btn_box {
    padding-bottom: 50px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1060px) {
  .bw_car_tech_box {
    min-height: 251px;
  }
}

@media (min-width: 1025px) and (max-width: 1149px) {
  .bw_djd_admin_text p {
    width: 200px;
  }
.bwn_djdesign_admin_section {
	padding: 200px 0 0px;
}
.bw_djdesign_banner_center h2 {
	font-size: 29px;
	line-height: 35px;
	margin-bottom: 20px;
}
.bw_djdesign_responsive_devices_section {
	padding: 70px 0 80px;
}
 .bw_djd_onner_inner_box h6 {
	font-size: 20px;
	line-height: 30px;
}
.bw_djdesign_pixle_box h4 {
	font-size: 35px;
	line-height: 40px;
}
  .bw_djdesign_threeimg_section {
	height: 250px;
}
.bw_djdesign_onlinestore_text h2 {
	font-size: 35px;
	line-height: 45px;
	margin-bottom: 10px;
	width: 95%;
}
.bw_djdesign_website_owner_section h2 {
	font-size: 40px;
	line-height: 50px;
	margin-bottom: 50px;
}
.bw_djd_onner_inner_box img {
	width: 90px;
}
.bw_djdesign_online_store_section {
	padding: 60px 0;
}
.bw_djdesign_website_owner_section {
	padding: 65px 0 20px;
}
.bw_djd_onner_inner_box {
	margin-bottom: 0;
}
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_djdesign_banner_center h2 {
    font-size: 30px !important;
    line-height: 45px !important;
    margin-bottom: 20px !important;
  }
  .bw_djd_onner_inner_box h6 {
    font-size: 15px;
    line-height: 25px;
  }
  .bw_djd_admin_text p {
    font-size: 12px;
    width: 240px;
  }
  .bw_djd_admin_text h6 {
    font-size: 16px;
  }
  .bw_djdesign_newsite h2 {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 15px;
  }
  .bw_djdesign_newsite p {
    font-size: 13px;
    line-height: 25px;
    padding-bottom: 30px;
  }
  .bw_djdesign_theme_img_box h6 {
    font-size: 18px;
    line-height: 30px;
  }
  .bw_djdesign_onlinestore_text h2 {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .bw_djdesign_onlinestore_text h6 {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .bw_djdesign_onlinestore_text p {
    font-size: 13px;
    line-height: 30px;
    max-width: 500px;
  }
  .bw_djdesign_website_owner_section h2 {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 50px;
  }
  .bw_djd_onner_inner_box p {
    font-size: 13px;
    line-height: 23px;
  }
  .bw_djd_onner_inner_box {
    padding: 20px 12px;
  }
  .bw_djd_onner_inner_box img {
    max-width: 90px;
    height: 60px;
  }
  .bw_djdesign_slider_heading h6 {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px;
  }
  .bw_djdesign_slider_heading h2 {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 30px;
  }
  .bw_djd_layouts_slider .slick-next {
    width: 20px !important;
    height: 20px !important;
  }
  .bw_djd_layouts_slider .slick-prev {
    width: 20px !important;
    height: 20px !important;
  }
  .bw_djdesign_pixle_box h4 {
    font-size: 25px;
    line-height: 32px;
  }
  .bw_djdesign_pixle_box p {
    font-size: 13px;
    line-height: 27px;
    padding-bottom: 10px;
  }
  .bw_djd_res_icon_box {
    width: 45px;
    height: 45px;
    margin-right: 15px;
  }
  .bw_djd_res_icon_box svg {
    font-size: 20px;
  }
  .bw_djdesign_responsive_devices_section {
    padding: 60px 0 220px 0;
  }
  .bwn_djdesign_admin_heading h2 {
    font-size: 30px;
    line-height: 45px;
  }
  .bwn_djdesign_admin_heading p {
    font-size: 13px;
    line-height: 25px;
  }
  .bw_djd_admin_text h6 {
    font-size: 14px;
  }
  .bwn_djdesign_admin_icon img {
    width: 60px;
    height: 60px;
  }
  .bwn_djdesign_admin_icon {
    margin: 35px 0 0 0;
  }
  .bw_djdesign_website_owner_section {
    padding: 65px 0;
  }
  .bw_djdesign_online_store_section {
    padding: 50px 0;
  }
  .bw_djdesign_threeimg_section {
    height: 250px;
  }
}

@media (max-width: 1024px) {
  .bw_djdesign_layout_desktop_view {
    display: none;
  }
  .bw_djdesign_website_owner_section_Desktop_view {
    display: none;
  }
}

@media (min-width: 1025px) {
  .bw_djdesign_new_site_mobile_view {
    display: none;
  }
  .bw_djdesign_website_owner_section_movile_view {
    display: none;
  }
  .bw_visit_demo_btn_section {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .bw_djd_admin_text p {
    font-size: 16px;
    line-height: 22px;
    width: 170px;
  }
  .bw_djdesign_pixle_box {
    bottom: -54%;
  }
  .bwn_djdesign_admin_icon img {
    width: 60px;
    height: 60px;
  }
  .bw_djd_admin_text h6 {
    font-size: 15px;
    line-height: 25px;
  }
  .bwn_djdesign_admin_icon {
    width: 323px !important;
    border-right: 1px solid #ccc !important ;
    border-bottom: 0 !important;
  }
}

@media (min-width: 1351px) and (max-width: 1440px) {
  .bw_djdesign_banner_center h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .bw_djdesign_onlinestore_text h2 {
    font-size: 38px;
    line-height: 50px;
    width: 100%;
  }
  .bw_djdesign_onlinestore_text h6 {
    font-size: 19px;
  }
  .bw_djdesign_onlinestore_text p {
    font-size: 14px;
  }
  .bw_djdesign_pixle_box h4 {
    font-size: 32px;
    line-height: 40px;
  }
  .bw_djdesign_pixle_box p {
    font-size: 14px;
  }
  .bw_djd_admin_text p {
    font-size: 12px;
    width: 95%;
  }
  .bwn_djdesign_admin_heading h2 {
    font-size: 32px;
    line-height: 45px;
  }
}

@media(max-width:1024px){
  .bw_loade_more_design_btn_desktop_view{
    display: none;
  }
}

