/************************* new seo design style start ********************/
.bw_seo_progress_circle_outer ul {
	display: flex;
	justify-content: center;
	padding-left: 0;
}
.bw_seo_progress_circle_outer ul li {
	display: inline-block;
	width: 33%;
	padding: 15px;
	margin: 0 auto;
	justify-content: center;
	text-align: center;
}
.bw_seo_progress_circle_outer .CircularProgressbar {
	width: 100px;
	height: auto;
	margin: 30px auto;
}
.bw_seo_progress_circle_outer .CircularProgressbar .CircularProgressbar-path {
	stroke: #3960c0 !important;
}
.bw_seo_progress_circle_outer .CircularProgressbar .CircularProgressbar-text {
	fill: #3960c0 !important;
	font-size: 20px;
  font-weight: 500 !important;
}
.bw_wdd_dervices_box.benefits {
	min-height: 360px !important;
}
.bw_seo_progress_circle p {
	width: 92%;
	margin: 0 auto;
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
}
.result_with_seo_section.bw_faq_section {
	background-color: #f3f6fc;
}
.seo_services_section .bw_new_branding_main_title h2 {
	font-weight: 700;
	font-size: 40px;
	color: #3D63C3;
	margin-top: 5px;
	position: relative;
	text-align: center;
	padding-bottom: 40px;
	line-height: 45px;
}
.bw_seo_Agency_Section_box  h2 {
	font-weight: 700;
	font-size: 40px;
	line-height: 45px;
	color: #fff;
  text-align: center;
}
.bw_seo_Agency_Section_box p {
	color: #fff;
	font-weight: 300;
	font-size: 16px;
	padding: 0px 30px;
	line-height: 23px;
	text-align: center; 
	margin-bottom: 0;
}
.bw_seo_Agency_Section{
  background-image: url('../images/seo-img/seo-agency-banner.png');
  background-color: #000;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: 100% bottom;
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
 .button_blueee {
	background-color: #2c52b0;
	color: #fff;
	padding: 12px 30px;
  min-width: 180px;
	font-size: 16px;
	text-align: center;
	text-transform: capitalize;
	margin: 0 auto;
	font-weight: 600;
	margin-top: 20px;
	border-color: transparent;
	transition: all 0.2s ease-in-out;
	border: 1px solid #2c52b0;
}
/************************* new seo design style end ********************/

/* .bw_wdd_banner {
  background: #44781b;
} */
.bw_wdd_banner h1 {
	font-size: 40px;
	line-height: 50px;
}
.bw_wdd_dervices_box_outer {
	padding: 15px;
}
.bw_website_desing {
  padding: 100px 0;
}
.bw_wdd_banner a {
  font-size: 16px;
  line-height: 30px;
}
.bw_website_deigns_innerbox1 {
  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: middle;
}
.bw_website_deigns_innerbox1 h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: #000;
  text-transform: capitalize;
}
.bw_website_deigns_innerbox1 p {
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	width: 606px;
	text-align: justify;
	color: #737373;
}
.bw_wdd_img_right_box {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.bwn_website_design_image {
  width: 80%;
  height: auto;
  margin-bottom: 0 auto 20px;
}
/*-------- Why Choose Us Style--------- */
.bw_why_choose_us {
  background: #000000;
  padding: 100px 0;
}
.bw_why_choose_us h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 103px;
  color: #fff;
}
.bw_why_choose_us ul li p {
  padding-left: 40px;
  margin-bottom: 0;
  font-size: 15px;
}
.bw_why_choose_us ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
  position: relative;
  background: #d9d9d933;
  margin-bottom: 20px;
  padding: 20px 15px;
  border-radius: 10px;
}
.bw_why_choose_us ul li::before {
  position: absolute;
  content: "";
  background: #d9d9d93b
    url("../images/web-development-and-design/white-right-arrow.webp")
    center/cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-repeat: no-repeat;
  padding: 5px;
  background-size: 65%;
  background-position: center 76%;
}
/* .bw_why_choose_us ul li svg {
  width: 22px;
  height: 22px;
  background: #d9d9d93b;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  margin-right: 15px;
  padding: 2px;
} */
.bw_wdd_imagebox2 {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.bw_wdd_imagebox2 img {
  width: 300px !important;
  margin: 0 auto !important;
}

/*------ Web devepment design service style------ */
.bw_wdd_dervices_box {
  padding: 30px;
  border-bottom: 3px solid transparent;
  background: #3d63c30f;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  min-height: 265px;
  margin-bottom: 30px;
  cursor: pointer;
}


.bw_wdd_dervices_box:hover {
  border-bottom: 3px solid #2c52b0;
  background: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.09);
}
.bw_wdd_dervices_box:hover img {
  filter: grayscale(0);
}
.bw_wdd_dervices_box img {
  margin-bottom: 20px;
  filter: grayscale(1);
}

.bw_wdd_dervices_box p {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 33px;
  color: #000;
}
.search_engin_optimizationnn .bw_wdd_dervices_box h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.search_engin_optimizationnn .bw_wdd_dervices_box p {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #000;
}
.search_engin_optimizationnn .slider_seo_main  .slick-prev, .slick-next {
	width: 40px !important;
	height: 40px !important;
	z-index: 9;
}

.bw_faq_section h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  color: #000;
  margin-bottom: 30px;
}
.bw_faq_section {
  padding: 100px 0;
}

/* -------- FAQ Accordian Style --------------*/
.bw_wdd_faq_accordian svg {
  fill: #000 !important;
  font-size: 28px !important;
  font-weight: 600 !important;
}
.bw_wdd_faq_accordian .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-radius: 0 !important;
  margin-bottom: 20px !important;
  padding: 5px 15px !important;
  border: 1px solid #ccc;
  box-shadow: none !important;
}
.bw_wdd_faq_accordian
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-top: 0 !important;
  color: #000 !important;
  background: #3d63c321 !important;
  border: 1px solid transparent;
  box-shadow: 0 0 1px #ccc;
}
.bw_wdd_faq_accordian .bw_accordian_heading {
	font-weight: 600 !important;
	font-size: 22px !important;
	line-height: 35px !important;
	font-family: "Poppins", sans-serif !important;
	margin-bottom: 0;
}
.bw_wdd_faq_accordian .bw_accordian_ans {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 30px !important;
  color: #000 !important;
  padding-top: 20px !important;
  font-family: "Poppins", sans-serif !important;
}

/* -------- server style start --------- */
.bw_wdd_faq_accordian .css-67l5gl {
  border-radius: 0 !important;
  margin-bottom: 20px !important;
  padding: 5px 15px !important;
  border: 1px solid #ccc;
  box-shadow: none !important;
}
.bw_wdd_faq_accordian .css-67l5gl:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.bw_wdd_faq_accordian .css-17o5nyn.Mui-expanded {
  margin: 20px 0px;
}
.bw_wdd_faq_accordian .css-67l5gl.Mui-expanded {
  margin-top: 0 !important;
  color: #000 !important;
  background: #3d63c321 !important;
  border: 1px solid transparent;
}

/* -------- server style ending --------- */

/* --------- Responsive Style ------ */
@media (max-width: 767px) {
  .bw_wdd_banner h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .bw_website_deigns_innerbox1 p {
    font-size: 14px;
    line-height: 25px;
    width: unset;
  }
  .bw_website_desing {
    padding: 20px 0;
  }
  .bw_website_deigns_innerbox1 h2 {
    font-size: 25px;
    line-height: 45px;
    text-align: center;
  }
  .bw_why_choose_us h2 {
    font-size: 25px;
    line-height: 65px;
    text-align: center;
  }
  .bw_why_choose_us {
    padding: 50px 0;
  }
  .bw_why_choose_us ul li {
    font-size: 14px;
    padding: 12px 15px;
  }
  .bw_why_choose_us ul li::before {
    background-size: 69%;
  }
  .bw_wdd_imagebox2 img {
    width: 250px !important;
    margin-top: 30px !important;
  }
  .bw_faq_section h2 {
    font-size: 25px;
    line-height: 34px;
  }
  .bw_faq_section {
    padding: 30px 0;
  }
  .bw_wdd_dervices_box p {
    font-size: 16px;
  }
  .bw_wdd_dervices_box {
    padding: 20px;
    min-height: 235px;
  }
  .bw_wdd_faq_accordian .bw_accordian_heading {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .bw_wdd_faq_accordian .bw_accordian_ans {
    font-size: 16px !important;
    padding-top: 10px !important;
  }
  .bw_wdd_faq_accordian svg {
    font-size: 20px !important;
  }
  .bw_wdd_faq_accordian .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin-bottom: 15px !important;
    padding: 0px 15px !important;
  }
  .bwn_website_design_image {
    width: 100%;
    margin: 0 auto 20px;
  }
  .bw_website_deigns_innerbox1 {
    order: 2;
  }
  .bw_wdd_img_right_box {
    order: 1;
  }
  .bw_wdd_img_right_box img {
    margin-bottom: 20px;
  }
  .bw_seo_progress_circle_outer ul {
    display: flex;
    justify-content: center;
    padding-left: 0;
    flex-wrap: wrap;
  }
  .bw_seo_progress_circle_outer ul li {
    width: 100%;
  }
  .bw_seo_progress_circle_outer .CircularProgressbar {
    width: 80px;
    margin: 20px auto;
  }
  .search_engin_optimizationnn .slider_seo_main .slick-prev {
   left: 0;
  }
  .search_engin_optimizationnn .slider_seo_main  .slick-next {
    right: 0;
   }
   .search_engin_optimizationnn .slider_seo_main .slick-prev, .slick-next {
    width: 30px !important;
    height: 30px !important;
  }
  .bw_seo_progress_circle p {
    width: 100%;
    font-size: 14px;
  }
  .search_engin_optimizationnn .bw_wdd_dervices_box p {
    font-size: 14px !important;
    line-height: 25px;
  }
  .bw_wdd_dervices_box.benefits {
    min-height: 280px !important; 
  }
  .seo_services_section .bw_new_branding_main_title h2 {
    font-size: 25px;
    padding-bottom: 30px;
    line-height: 30px;
  }
  .bw_seo_Agency_Section {
    height: 350px;
  }
  .bw_seo_Agency_Section_box h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .bw_seo_Agency_Section_box p {
    font-size: 14px;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_seo_progress_circle p {
    width: 95%;
    font-size: 14px;
    line-height: 23px;
  }
  .bw_seo_Agency_Section {
    height: 300px;
  }
  .bw_seo_Agency_Section_box h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .bw_seo_Agency_Section_box p {
    font-size: 14px;
  }
  .seo_services_section .bw_new_branding_main_title h2 {
    font-size: 30px;
    margin-top: 15px;
    line-height: 40px;
  }
  .bw_seo_progress_circle_outer .CircularProgressbar {
    width: 80px;
    margin: 15px auto;
  }
  .search_engin_optimizationnn .bw_wdd_dervices_box p {
    font-size: 14px !important;
    line-height: 24px;
  }
  .bw_wdd_dervices_box.benefits {
    min-height: 320px !important;
  }
  .bw_wdd_banner h1 {
    font-size: 35px;
    line-height: 40px;
  }
  .bw_faq_section {
    padding: 30px 0;
  }

  .bw_services_form_section.book_an_App_seo {
    padding: 40px 0 0 0 ;
  }
  .bw_website_deigns_innerbox1 p {
    font-size: 15px;
    line-height: 24px;
    width: unset;
  }
  .bw_new_branding_box p {
    font-size: 14px;
  }
  .bw_website_deigns_innerbox1 h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .bw_website_desing {
    padding: 50px 0;
  }
  .bw_why_choose_us {
    padding: 50px 0;
  }
  .bw_why_choose_us h2 {
    font-size: 30px;
    line-height: 55px;
  }
  .bw_why_choose_us ul li {
    font-size: 16px;
    line-height: 25px;
    padding: 10px 15px;
  }
  .bw_why_choose_us ul li::before {
    background-size: 60%;
  }
  .bw_faq_section h2 {
    font-size: 30px;
    line-height: 60px;
  }
  .bw_wdd_faq_accordian .bw_accordian_heading {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .bw_wdd_faq_accordian .bw_accordian_ans {
    font-size: 17px !important;
  }
  .bw_wdd_faq_accordian svg {
    font-size: 24px !important;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_seo_Agency_Section {
    height: 350px;
  }
  .bw_seo_Agency_Section_box h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .bw_seo_Agency_Section_box p {
    font-size: 14px;
  }
  .bw_website_deigns_innerbox1 p {
    font-size: 12px;
    line-height: 25px;
    width: unset;
  }
  .bw_design_150_img {
    width: 80% !important;
  }
  .bw_website_deigns_innerbox1 h2 {
    font-size: 30px;
    line-height: 45px;
  }
  .bw_website_desing {
    padding: 50px 0;
  }
  .bw_why_choose_us {
    padding: 50px 0;
  }
  .bw_why_choose_us h2 {
    font-size: 35px;
    line-height: 65px;
  }
  .bw_wdd_imagebox2 img {
    width: 270px !important;
  }
  .bw_why_choose_us ul li {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 15px;
    padding: 15px 15px;
  }
  .bw_faq_section {
    padding: 40px 0;
  }
  .bw_faq_section h2 {
    font-size: 30px;
    line-height: 45px;
  }
  .bw_wdd_dervices_box p {
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    color: #000;
  }
.bw_wdd_dervices_box {
    padding: 15px;
    min-height: 230px;
}
  .bw_wdd_faq_accordian .bw_accordian_heading {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .bw_wdd_faq_accordian .bw_accordian_ans {
    font-size: 14px !important;
    padding-top: 10px !important;
  }
  .bw_wdd_faq_accordian svg {
    font-size: 20px !important;
  }
  .bw_wdd_faq_accordian .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    padding: 2px 15px !important;
  }
  .bw_wdd_faq_accordian .css-67l5gl {
    padding: 2px 15px !important;
  }
}
@media (min-width: 1150px) and (max-width: 1350px) {
.bw_djd_onner_inner_box h6 {
	font-size: 14px;
	line-height: 24px;
}
}
@media (min-width: 1350px) and (max-width: 1440px) {
  .bw_website_deigns_innerbox1 p {
    font-size: 15px;
    line-height: 31px;
    width: 516px;
  }
  .bw_website_deigns_innerbox1 h2 {
    font-size: 30px;
    line-height: 35px;
}
}
@media (min-width: 1440px) and (max-width: 1650px) {
.bw_djd_onner_inner_box img {
	margin-bottom: 10px;
	width: 60px;
}
.bw_djd_onner_inner_box h6 {
	font-size: 14px;
	line-height: 24px;
}
}

@media(min-width:1150px) and (max-width:1440px){
  .search_engin_optimizationnn .bw_wdd_dervices_box h3 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  .search_engin_optimizationnn .bw_wdd_dervices_box p {
    font-size: 14px !important;
    line-height: 24px;
  }
  .bw_seo_progress_circle_outer .CircularProgressbar {
    width: 80px;
    height: auto;
    margin: 20px auto;
  }
  .bw_seo_progress_circle p {
    font-size: 14px;
  }
  .bw_wdd_dervices_box.benefits {
    min-height: 330px !important;
  }
  .bw_seo_Agency_Section_box h2 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}