/*********** media query css start ***********/
@media (min-width: 1200px) and (max-width: 1440px) {
.bw_insta_content_left ul li.bw_insta_box {
    height: 400px;
    margin-left: 80px;
}
.bw_insta_content_left ul li h2 {
    left: -175px;
    font-size: 55px;
    top: 158px;
}
  .bw_custome_container.container {
    max-width: 1140px;
  }

  .bw_custome_container_banner {
    max-width: 1150px !important;
  }

  .bw_news_box_Content_title_img {
    width: 13%;
  }

  .bw_custome_footer_container.container {
    max-width: 1150px !important;
  }
  .bw_banner_content_inner h1 strong span::before {
    bottom: 12px;
  }

  .bw_about_comany_title_text h6 {
    font-weight: 300;
    font-size: 20px;
  }

  .bw_footer.position-relative .bw_news_div {
    top: 40px;
  }

  .bw_about_comany_title_text h2 {
    font-size: 40px;
    line-height: 1.1;
  }

  .bw_about_comany_title_text p {
    font-size: 14px;
  }

  .bw_about_comany_logo_img ul {
    width: 80%;
  }

  .bw_about_box_main h2 {
    font-size: 36px;
  }

  .bw_about_box_main h6 {
    font-size: 16px;
  }

  .bw_about_box_main p {
    font-size: 14px;
  }

  .bw_about_comany_logo_img ul li img {
    width: 100%;
  }

  .bw_about_box_main {
    padding: 10px 30px;
  }

  section.bw_about_comany_section {
    padding: 80px 0px;
  }

section.bw_new_branding_section {
	padding: 50px 0px;
}

  .bw_new_branding_main_title_dots p {
    font-size: 16px;
  }

  .bw_new_branding_main_title h2 {
    font-weight: 700;
    font-size: 40px;
  }

 .bw_new_branding_box h2 {
	font-size: 18px;
	margin: 20px 0px 10px 0px;
}
.bw_new_branding_box h3 {
	font-size: 18px;
	margin: 20px 0px 10px 0px;
}

.bw_new_branding_box p {
	opacity: 0.5;
	font-size: 14px;
	padding: 0px 0px;
}

  .bw_new_branding_main_title {
    margin-bottom: 35px;
  }

  .bw_new_branding_box {
    margin-bottom: 10px;
  }

  .bw_branding_text_font_style h2 {
    font-size: 80px;
  }

  .bw_branding_text_row1_img1 {
    width: 120px;
    height: 120px;
  }

  .bw_branding_text_div2 {
    margin-top: 10px;
    padding-right: 20px;
  }

  .bw_branding_text_agency_img_circle {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -20px;
    right: -75px;
  }

  .bw_branding_text_studio_img_circle {
    width: 70px;
    height: 70px;
    right: -56px;
    top: -15px;
  }

  .bw_branding_text_agency_img2 {
    width: 140px;
    height: 75px;
  }

  .bw_branding_text_btn_img {
    margin-left: 10px;
    top: -2px;
  }

  .bw_branding_text_btn_img img {
    width: 50%;
  }

.bw_new_branding_box_icon img {
	width: 50px;
}

  section.bw_branding_text_section {
    height: 650px;
  }

  .bw_branding_text_design_img1 {
    width: 75px;
    height: 90px;
    margin: 0px 20px;
  }

  .bw_branding_text_div3 {
    padding-right: 0px;
    margin-left: 20px;
  }

  .bw_branding_text_btn_main {
    margin-top: 30px;
  }

  .bw_brand_slider_left_text_main h2 {
    font-size: 40px;
  }
  .bwn_clint_logo_tabs_design_bbox {
    padding: 10px 0;
  }

  section.bw_brand_slider_section {
    display: flex;
    align-items: center;
  }
  .bwn_client_tab_btton {
    width: 15%;
    padding: 8px 5px;
    font-size: 12px;
  }

  .bw_brand_slider_left_text_main p {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }

  .bw_brand_slider_item img {
    max-width: 100%;
  }

  .bw_brand_slider_right_slide_main .slick-prev,
  .slick-next {
    width: 50px !important;
    height: 50px !important;
  }

  .bw_brand_slider_right_slide_main {
    margin-top: 0px;
  }

  .bw_brand_slider_item {
    margin-bottom: 10px;
  }

  .bw_brand_slider_right_slide_main .slick-next {
    right: -55px;
  }

  .bw_innovation_video_text h2 {
    font-size: 55px;
    line-height: 60px;
  }

  .bw_innovation_video_text p {
    font-size: 20px;
    margin: 20px 0px 20px 0px;
  }

  .bw_innovation_video_section {
    background-image: url(../images/home/video-bg-img.png) !important;
    height: 650px !important;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
/* 
  .bw_project_slider_section {
    background-image: url(/static/media/recent-project.e62c3d9f.png);
    height: 700px;
  } */

  .bw_project_slider_text h2 {
    font-size: 40px;
  }

.bw_project_slider_text p {
	font-size: 14px;
	line-height: 25px;
}
.bw_project_slider_item_text_paragraph p {
	font-size: 14px;
	line-height: 25px;
}

  .bw_project_slider_item_text_title h4 {
    font-size: 18px;
  }

.bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
	top: inherit;
	bottom: -50px;
	left: -315px;
	width: 55px !important;
	height: 55px !important;
}

.bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
	top: inherit;
	bottom: -50px;
	width: 55px !important;
	height: 55px !important;
	left: -247px;
}


  .bw_banner_img_main img {
    width: 100%;
  }

  .bw_banner_content_inner h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }
.bw_banner_content_inner p {
	width: 85%;
	font-weight: 400;
	font-size: 14px;
}

  .bw_google_review_title h2 {
    font-size: 40px;
  }

  .google_review_slider_box p {
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
  }
.bwn_review_nevigate_link{
  font-size: 15px;
}
  .google_review_slider_box_rating span {
    font-size: 30px;
  }

  .google_review_slider_box {
    text-align: center;
    padding: 20px 30px;
    margin-bottom: 0px;
  }

.google_review_slider_box h4 {
	font-size: 16px;
	margin-bottom: 5px;
  line-height: 35px;
}
.google_review_slider_box .bwn_review_user_image {
	width: 40px;
	margin-bottom: 10px;
}
.google_review_slider_box img {
	width: 65px;
}
.google_review_slider_box {
	min-height: 300px;
}

  .bw_brand_slider_right_slide_main.google_review_slider_main .slick-next {
    width: 55px !important;
    height: 55px !important;
    right: -65px;
  }

  .bw_brand_slider_right_slide_main.google_review_slider_main .slick-prev {
    width: 55px !important;
    height: 55px !important;
  }

  .bw_news_featured_box_text h2 {
    font-size: 30px;
    margin: 15px 0px 15px 0px;
  }

  .bw_news_featured_box_text p {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .bw_news_featured_box_text h3 {
    font-weight: 400;
    font-size: 16px;
  }

  .bw_news_featured_box_main {
    padding: 50px 0px;
  }

  .bw_news_featured_box_img img {
    width: 100%;
  }

  .bw_news_featured_box_text a,
  .bw_news_featured_box_text a:hover,
  .bw_news_featured_box_text a:active,
  .bw_news_featured_box_text a:focus {
    font-weight: 400;
  }

  .bw_news_featured_box_text img {
    max-width: 15px;
  }

  .bw_news_box_Content_img {
    width: 20%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .bw_branding_text_font_style h2 {
    font-size: 76px;
  }
  .google_review_slider_box {
    margin-bottom: 30px;
  }
  .bw_google_review_title {
    margin-bottom: 20px;
  }
  .bw_brand_slider_left_text_main {
    padding: 0px 30px;
  }

  .bw_innovation_video_section {
    height: 700px !important;
  }

  .bw_innovation_video_text h2 {
    font-size: 42px;
    line-height: 58px;
  }

  .bw_project_slider_text {
    padding: 0px 20px;
    margin-bottom: 15px;
  }

  .bw_news_box_Content_title_img {
    width: 14%;
  }

  .bw_footer_info_text h3 {
    font-size: 24px;
  }

  section.bw_news_section {
    padding: 110px 0px;
  }

  section.bw_branding_text_section {
    height: 830px;
  }

  .bw_news_featured_box_text h2 {
    font-size: 36px;
    margin: 15px 0px 15px 0px;
  }

  .bw_news_featured_box_text {
    padding: 0px 30px;
  }

  .bw_news_featured_box_main {
    padding: 60px 0px;
  }

  .aside-section.aside-left {
    height: 70vh !important;
  }
  .aside-section.aside-right {
    height: 70vh !important;
  }
  .bw_custome_container.container {
    max-width: 960px;
  }
  .bw_about_comany_title_text h2 {
    font-size: 45px;
  }
  .bw_new_branding_box p {
    font-size: 14px !important;
  }
  .bw_new_branding_box h2 {
    font-size: 17px;
    margin: 12px 0px 12px 0px;
  }
  .bw_new_branding_box h3 {
    font-size: 17px;
    margin: 12px 0px 12px 0px;
  }
}

/* @media (min-width: 1024px) and (max-width: 1199px) {
  
} */

@media (max-width: 991px) {
  ul.bw_menu_list li {
    display: none;
  }
}

/* @media (max-width: 767px){
    .bw_banner_img_main {
        display: none;
    }
} */
@media (min-width: 1024px) and (max-width: 1026px) {
 section.bw_brand_slider_section {
	height: 710px !important;
	padding: 50px 0 !important;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bw_about_comany_title_text h2{
    font-size: 35px;
  }
  section.bw_google_review_section {
	padding: 40px 0 130px 0;
}
  .bw_project_slider_section {
	padding: 20px 0 50px 0;
}
  section.bw_brand_slider_section {
	padding: 50px 0;
}
  .bw_branding_text_agency_img_circle {
    top: -17px;
    right: -93px;
  }
  .bw_branding_text_studio_img_circle {
    right: -94px;
    top: -15px;
  }
  .bw_banner_content_inner h1 strong span::before {
    bottom: 4px;
  }
  .bw_about_box_main {
    min-height: 402px;
  }

  ul.bw_first_menu li a {
    font-size: 20px;
    line-height: 35px;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }

  .bw_brand_slider_right_slide_main .slick-prev,
  .slick-next {
    width: 55px !important;
    height: 55px !important;
  }

  .bw_footer_info_text h3 {
    font-size: 24px;
  }

  .bw_news_box_Content_img {
    width: 100%;
  }

  .bw_news_box_Content_title_img {
    display: flex;
    align-items: center;
    width: 20%;
  }

  section.bw_about_comany_section {
    padding: 60px 0px;
  }

  section.bw_new_branding_section {
    padding: 20px 0px;
  }
  .bw_new_branding_box p {
    font-weight: 400;
    font-size: 18px;
    padding: 0px 0px;
  }

  .bw_new_branding_main_title h2 {
    font-weight: 700;
    font-size: 35px;
  }

  .bw_new_branding_main_title_dots p {
    font-size: 18px;
    line-height: 30px;
  }

  section.bw_branding_text_section {
    height: 600px;
  }

  .bw_branding_text_font_style h2 {
    font-weight: 600;
    font-size: 46px;
  }

  .bw_branding_text_div2 {
    padding-right: 0px;
    top: -10px;
  }

  .bw_branding_text_btn_main {
    margin-top: 20px;
  }

.bw_brand_slider_left_text_main h2 {
	font-size: 35px;
}

  .bw_brand_slider_left_text_main p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }

  .bw_brand_slider_right_slide_main {
    margin-bottom: 70px;
  }

  .bw_brand_slider_left_text_main {
    text-align: center;
  }
  .bw_new_branding_main_title {
    margin-bottom: 30px;
    padding-left: 0;
    text-align: center;
  }
  .bw_new_branding_box {
    text-align: center;
    padding: 10px 10px;
    margin-bottom: 10px;
  }

  .bw_innovation_video_text p {
    margin: 5px 0px 15px 0px;
  }

  .bw_innovation_video_section {
    background-image: url("../images/home/video-bg-768.webp");
    height: 710px !important;
  }

  .bw_innovation_video_text {
    text-align: center;
    width: 100%;
    margin: 0px auto;
  }

  .bw_innovation_video_text h2 {
    font-size: 40px;
    line-height: 48px;
  }

  .bw_banner_img_main img {
    width: 100%;
  }

  .bw_banner_content_inner {
    text-align: center;
    margin-top: 15px;
  }

.bw_banner_content_inner h1 {
	font-size: 35px;
	font-weight: 300;
	padding-right: 0px;
	margin-bottom: 10px;
	line-height: 43px;
}

.bw_banner_section {
  height:100vh;
}
  .bw_scroll_sec {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .bw_banner_content {
    padding: 10rem 0 9rem 0;
  }

  .bw_banner_content_inner p {
    margin: auto;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .bw_project_slider_text p {
    font-size: 16px;
  }
.bw_project_slider_item_text_paragraph p {
	font-size: 16px;
	line-height: 25px;
}
.bw_about_comany_title_text p {
	font-size: 16px;
}
  .bw_project_slider_text {
    width: 85%;
    margin-bottom: 20px;
  }
  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
    top: inherit;
    bottom: -110px;
    right: -85px;
    margin: auto;
    left: 0;
    width: 55px !important;
    height: 55px !important;
  }

  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
    top: inherit;
    bottom: -110px;
    left: 0;
    right: 85px;
    margin: auto;
    width: 55px !important;
    height: 55px !important;
  }

  .bw_project_slider_text h2 {
    font-weight: 700;
    font-size: 35px;
  }

  .bw_innovation_video_btn {
    margin-top: 15px;
  }

  .bw_brand_slider_right_slide_main.google_review_slider_main {
    margin-bottom: 0px;
  }
  .bw_google_review_title {
    margin-bottom: 12px;
  }

  .google_review_slider_box {
    margin-bottom: 15px;
  }

  .google_review_slider_box p {
    font-size: 15px;
    font-weight: 400;
  }
  .google_review_slider_box h4 {
	font-size: 17px;
	line-height: 40px;
}

  .bw_news_featured_box_img img {
    width: 100%;
  }

  .bw_news_featured_box_text h2 {
    font-size: 30px;
    margin: 10px 0px 10px 0px;
  }

  .bw_news_featured_box_text p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  ul.bw_menu_list li {
    padding: 0 9px !important;
  }

  ul.bw_menu_list li a {
    font-size: 14px;
  }

  .bw_logo {
    width: 31%;
  }

  /* .bw_logo img {
    width: 100% !important;
  } */
  .bw_logo img {
    width: 70px;
  }

  .menuOpen {
    width: 42px;
    height: 42px;
  }

.bw_google_review_title h2 {
	font-size: 35px;
	margin-bottom: 20px;
}

  section.bw_news_section {
    padding: 70px 0px;
  }

  .bw_news_featured_box_text {
    padding: 0px 0px;
  }

  .bw_news_featured_box_main {
    padding: 40px 20px;
    border-radius: 7px;
  }

  .bw_news_box_Content_title h2 {
    font-size: 20px;
    line-height: 28px;
  }

  .bw_brand_slider_item img {
    margin: auto;
  }

  .slick-slide img {
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bw_custome_container.container {
    max-width: 720px;
  }
  .bw_new_branding_box p {
    font-size: 16px;
  }
  .bw_new_branding_box p {
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 380px) {
  .bw_banner_img_main {
    margin-bottom: 15px;
    margin-top: 50px;
  }
  .bw_logo img {
    width: 70px;
    position: relative;
    top: -1px;
  }
  /* mobile menu */
  .bw_menu_right_sec p {
    font-size: 10px !important;
    margin-bottom: 0px !important;
    line-height: 25px;
  }
  .bw_menu_right_sec img {
    width: 32% !important;
    top: -200px !important;
    left: 15px !important;
  }
  .bw_menu_right_sec a.bw_menu_btn,
  .bw_menu_right_sec a.bw_menu_btn:hover,
  .bw_menu_right_sec a.bw_menu_btn:focus,
  .bw_menu_right_sec a.bw_menu_btn:active {
    padding: 5px 10px !important;
    margin: 10px 0 !important;
    font-size: 14px !important;
  }
  /* .bw_footer_link h3 {
    font-size: 14px !important;
    line-height: 50px !important;
  } */
  .bw_menu_right_sec .bw_footer_link li a {
    width: 25px !important;
    height: 25px !important;
  }
  .bw_menu_right_sec .bw_footer_link li a svg {
    font-size: 12px !important;
  }

  .bw_header_menu_adderss_box h6 {
    margin-bottom: 0;
  }
  .bw_header_menu_adderss_box {
    margin-top: 80px !important;
  }
  ul.bw_first_menu li a {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 800 !important;
  }
  .bw_second_menu h4,
  .bw_third_menu h4 {
    font-size: 17px !important;
    font-weight: 800;
    margin-top: 30px;
  }
  /* mobile menu ending */
}

@media(min-width:768px){
  .bwn_view_all_mobile_btn{
    display: none;
  }
}

@media(max-width:767px){
  .bwn_desktop_view_all_btn{
    display: none;
  }
  .bw_banner_section {
    background-image: url("../images/home/blue-home-img.webp");
    /* background-image: url(/static/media/mob-banner.61c4c2a4.jpg); */
    background-size: cover;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 99;
    height: 100vh;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_contact_form_outer_box h2 {
    margin-top: 14px;
    margin-bottom: 20px !important;
  }
  body .apiSecUnder {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    margin-top: 40px;

  }
  .imgWrp {
    margin-bottom: 3px;
  }
  body button.mobBtn {
    padding: 13px !important;
    width: auto !important;
  }
  .btnsec > div:first-child{
    margin-bottom: 15px;
  }
  .btnsec{
    margin-bottom: 30px;
  }
  .btnsec button{
    margin-bottom: 30px;
  }
  .submitSection {
    padding-left: 15px;
    padding-right: 15px;
  }
  .hm_branding_box_main .bw_new_branding_box p {
    display: none;
}
  .bw_banner_content_inner h1 strong span::before {
    bottom: 3px;
  }
.google_review_slider_box img {
	width: 85px;
}
.google_review_slider_box .bwn_review_user_image {
	width: 50px;
	margin-bottom: 10px;
}
  .bw_brand_slider_right_slide_main.google_review_slider_main .slick-prev {
    top: inherit;
    width: 45px !important;
    height: 45px !important;
    bottom: -65px;
    left: -50px;
  }
  .header-main-d .left-content {
    width: 0;
    display: none;
  }
  .aside-section.aside-left {
    flex-direction: column;
  }
/* ul.bw_first_menu li a {
	font-size: 20px !important;
	line-height: 35px !important;
	font-weight: 600 !important;
  font-family:'poppins' !important;
} */
.bw_mobile_menu_top ul.bw_first_menu li a {
	font-size: 16px !important;
	line-height: 45px !important;
	font-weight: 600 !important;
	font-family: 'poppins' !important;
	text-transform: uppercase;
}
  ul.bw_second_menu li a,
  ul.bw_third_menu li a {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0;
    line-height: 32px;
  }
  .header-main-d .right-content {
    width: 100%;
    top: 0; 
  }
  .bw_menu_right_sec p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .bw_menu_right_sec h2 {
    line-height: 50px;
    font-size: 14px;
    line-height: 21px;
  }
  .bw_menu_right_sec button,
  .bw_menu_right_sec button:hover,
  .bw_menu_right_sec button:focus,
  .bw_menu_right_sec button:active {
    margin: 15px 0px;
    padding: 7px 15px;
    font-weight: 400;
  }
  .aside-section.aside-right {
    padding-left: 0;
    justify-content: center !important;
    padding-top: 20%;
    text-align: left !important;
    display: unset;
    align-items: center !important;
  }
  .bw_menu_right_sec img {
    position: absolute;
    bottom: 0;
    width: 60%;
    left: 10px;
    right: 0;
    top: 310px;
    margin: auto 0;
  }

  .bw_banner_content .order-xs-2 {
    order: 2;
  }
  .aside-section.aside-left {
    opacity: 0;
  }
  .left-content.l-open .aside-section.aside-left {
    opacity: 1;
  }
  .bw_banner_img_main {
    margin-bottom: 15px;
  }
  .bw_banner_content_inner h1 {
    font-size: 26px;
    font-weight: 300;
    padding-right: 0px;
    margin-bottom: 6px;
    line-height: 36px;
    text-align: center;
  }
  .bw_scroll_sec {
    display: none;
  }
  .bw_banner_content_inner {
    text-align: center;
  }
  .bw_banner_content_inner p {
    width: 100%;
    margin-bottom: 30px;
    font-size: 12px;
  }
  section.bw_branding_text_section.bw_branding_text_desktop {
    display: none;
  }

  .bw-branding-sec.bw_branding_text_mobile {
    display: block;
  }

  .bw_news_desktop_view {
    display: none !important;
  }

  .bw_news_box_Content_link a:before {
    bottom: 3px;
    width: 20%;
    height: 5px;
  }

  .bw_news_box_Content_link a,
  .bw_news_box_Content_link a:hover,
  .bw_news_box_Content_link a:active,
  .bw_news_box_Content_link a:focus {
    font-weight: 600;
    font-size: 12px;
    line-height: inherit;
  }

  .bw_news_Content_main {
    margin-top: 0px;
  }

  .bw_news_box_Content_link a:hover:before {
    width: 90%;
  }

  .bw_news_box_Content_link a img {
    width: 12%;
    margin-left: 5px;
  }

  .bw_news_Content_main ul li {
    padding: 20px 0px;
  }

  .bw_news_mobile_view {
    display: block !important;
  }

  .bw_news_box_Content_img {
    width: 100%;
  }

  .bw_news_box_Content_img img {
    width: 100%;
  }

  .bw_news_box_Content_title {
    padding: 0px 15px;
    width: 74%;
  }

  .bw_news_box_Content_title h4 {
    color: grey;
    font-weight: 500;
    font-size: 10px;
    margin: 0;
  }

  .bw_news_box_Content_title h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
  }

  .bw_news_box_Content_link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .bw_news_box_Content_title_img {
    display: flex;
    align-items: center;
    width: 30%;
  }

  .bw_brand_slider_right_slide_main.bw_project_slider_main .slick-list .col {
    padding-right: 0;
    padding-left: 10px;
  }

  .bw_project_slider_item_text {
    margin-top: 20px;
    padding: 0px 0px;
  }

.bw_project_slider_item_text_paragraph p {
	font-size: 14px;
	line-height: 25px;
	display: none;
}

.bw_insta_content_left ul li.bw_insta_box {
	height: 390px;
}
section.bw_insta_section {
	padding: 40px 0px 30px 0px;
}
  .bw_project_slider_text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  .bw_project_slider_text h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
  }

  .bw_project_slider_item_text_title h4 {
    font-weight: 700;
    font-size: 16px;
  }

  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
    top: inherit;
    bottom: -80px;
    right: -70px;
    margin: auto;
    left: 0;
    width: 50px !important;
    height: 50px !important;
  }

  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
    top: inherit;
    bottom: -80px;
    left: -60px;
    right: 0;
    margin: auto;
    width: 50px !important;
    height: 50px !important;
  }

  .bw_brand_slider_right_slide_main.bw_project_slider_main {
    margin-bottom: 60px;
  }
.bw_project_slider_section {
	height: auto;
	padding: 30px 0;
}

  .bw_brand_slider_right_slide_main.bw_project_slider_main {
    margin-top: 30px;
  }

  /* .bw_about_comany_logo_img {
    display: none;
  } */

  section.bw_about_comany_section {
    padding: 60px 0px 30px 0;
  }

  .bw_about_comany_title_text h2 {
    font-size: 30px;
    line-height: 1;
    text-align: center;
  }

  .bw_about_comany_title_text h6 {
    font-weight: 300;
    font-size: 20px;
    text-align: center;
  }

  .bw_about_comany_title_text p {
    margin-top: 15px;
  }

.bw_about_comany_title_text p {
	font-size: 14px;
	width: 100%;
	text-align: center;
	display: none;
}

  .bw_about_box_main {
    margin-top: 20px;
  }
.bw_about_comany_title_text .bw_btb_border_black {
	text-align: center;
}
  .bw_new_branding_main_title_dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bw_new_branding_main_title {
    margin-bottom: 0px;
    padding-left: 0px;
    text-align: center;
  }

  .bw_new_branding_box p {
    font-size: 14px;
    padding: 0px 0px;
    margin: 0;
    line-height: 23px;
  }

  .bw_new_branding_main_title h2 {
    font-size: 28px;
    margin-top: 5px;
    margin: 5px auto;
  }

section.bw_new_branding_section {
    padding: 50px 0px 50px 0px;
}

  .bw_new_branding_box_icon img {
    margin: auto;
    width: 65px;
  }

  .bw_brand_slider_right_slide_main.bw_new_branding_mobile_slider_main {
    margin-bottom: 0px;
  }

  .bw_new_branding_desktop {
    display: block;
  }

  .bw_new_branding_mobile {
    display: block;
  }

  .bw_new_branding_box {
    margin-bottom: 10px;
    padding: 10px 0px;
  }

  .bw_innovation_video_text {
    text-align: center;
  }

  .bw_innovation_video_text p {
    font-size: 16px;
    margin: 10px 0px 10px 0px;
  }

  .bw_innovation_video_text h2 {
    font-size: 31px;
    line-height: 40px;
    text-align: center;
  }

  .bw_innovation_video_section {
    background-image: url("../images/home/video-bg-480.webp");
    height: 500px !important;
  }

  .bw_innovation_video_text .bw_btb_border_white {
    display: none;
  }

  .bw_innovation_video_btn button {
    margin-top: 0;
  }

  .bw_innovation_video_btn {
    margin-top: 10px;
  }

  .bw_brand_slider_left_text_main p {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    margin-top: 10px;
  }

  .bw_brand_slider_left_text_main h2 {
    font-size: 25px;
  }

  .bw_brand_slider_right_slide_main {
    margin-bottom: 40px;
  }
/* 
  section.bw_brand_slider_section {
    height: 900px;
  } */
.bw_google_review_title h2 {
	font-size: 30px;
	margin-bottom: 30px;
}

  .google_review_slider_box_rating span {
    font-size: 28px;
  }

  .bw_google_review_title {
    margin-bottom: 20px;
  }

  .bw_brand_slider_right_slide_main.google_review_slider_main .slick-prev {
    width: 45px !important;
    height: 45px !important;
    bottom: -65px;
    left: -60px;
  }

.google_review_slider_box {
	padding: 25px 15px;
	margin-bottom: 28px;
}

  /* .google_review_slider_box h4 {
    margin-bottom: 10px;
  } */

  .bw_brand_slider_right_slide_main.google_review_slider_main .slick-next {
    right: -71px;
    width: 45px !important;
    height: 45px !important;
    bottom: -65px;
  }

  .google_review_slider_box p {
    font-weight: 400;
    font-size: 14px;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    /* -webkit-line-clamp: 5 !important; */
  }

  section.bw_google_review_section {
    padding: 50px 0;
  }

  .bw_new_branding_section.bw_new_branding_mobile
    .bw_brand_slider_right_slide_main
    .slick-prev {
    bottom: -80px;
    width: 45px !important;
    height: 45px !important;
    left: -60px;
  }

  .bw_new_branding_section.bw_new_branding_mobile
    .bw_brand_slider_right_slide_main
    .slick-next {
    bottom: -80px;
    width: 45px !important;
    height: 45px !important;
    right: -55px;
  }

  .bw_new_branding_box h2 {
    margin: 10px 0px 12px 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  .bw_new_branding_box h3 {
    margin: 10px 0px 12px 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  .bw_brand_slider_right_slide_main {
    margin-top: 0px;
  }

  .bw_brand_slider_left_text_main {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .bw_brand_slider_right_slide_main .slick-prev {
    bottom: -80px;
    left: -70px;
    width: 50px !important;
    height: 50px !important;
  }

  .bw_brand_slider_right_slide_main .slick-next {
    bottom: -80px;
    right: -60px;
    width: 50px !important;
    height: 50px !important;
  }

  section.bw_brand_slider_section:before {
    content: "";
    position: absolute;
    bottom: 0px;
    right: 0;
    width: 110px;
    height: 105px;
    background-image: url("../images/home/brand-bg-2.png");
    background-repeat: no-repeat;
    display: none;
  }

  section.bw_brand_slider_section:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 60px;
    background-image: url("../images/home/brand-bg-1.png");
    background-repeat: no-repeat;
    display: none;
  }

section.bw_brand_slider_section {
	position: relative;
	height: auto;
	padding: 30px 0;
}

  .bw_news_featured_box_text {
    padding: 0px 0px;
    padding-left: 10px;
  }

  .bw_news_featured_box_main {
    background: black;
    padding: 15px 0px;
    border-radius: 7px;
  }

  .bw_news_featured_box_img img {
    width: 100%;
  }

  section.bw_news_section {
    padding: 20px 0px;
  }

  .bw_news_featured_box_text h3 {
    font-weight: 500;
    font-size: 16px;
  }

  .bw_news_featured_box_text h3 {
    font-weight: 600;
    font-size: 12px;
  }

  .bw_news_featured_box_text h2 {
    margin: 10px 0px 10px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }

  .bw_news_featured_box_text p {
    color: #ffff;
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .bw_news_featured_box_text a,
  .bw_news_featured_box_text a:hover,
  .bw_news_featured_box_text a:active,
  .bw_news_featured_box_text a:focus {
    font-size: 12px;
  }

  .bw_branding_text_div2 {
    padding-right: 0px;
  }

  .bw_branding_text_agency_img2 {
    width: 80px;
    height: 40px;
    margin-left: 15px;
  }

  section.bw_branding_text_section {
    height: 430px;
  }

  .bw_branding_text_div1 {
    display: inherit;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .bw_branding_text_we_are_text {
    color: #54555b;
    float: left;
    margin-top: 5px;
    width: 63%;
    margin-left: 5px;
  }

  .bw_branding_text_font_style h2 {
    font-size: 45px;
    line-height: 1.2;
  }

  .bw_branding_text_row1_img1 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0px 8px;
    display: block;
  }

  .bw_animate_style_d123 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fec401;
  }

  .bw_branding_text_branding_text {
    width: 100%;
    display: inherit;
    float: left;
    text-align: inherit;
    padding-right: 20px;
  }

  .bw_branding_text_div2 {
    display: inherit;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-right: 0px;
    position: relative;
    top: -6px;
    float: left;
  }

  .bw_branding_text_agency_text {
    display: inherit;
    align-items: center;
    position: relative;
    margin-right: 0px;
    text-align: center;
    margin-top: 7px;
    margin-bottom: 13px;
    padding-right: 30px;
  }

  .bw_branding_text_agency_img_circle {
    width: 42px;
    height: 42px;
    overflow: hidden;
    margin: 0px 15px;
    background: #2ac1b7;
    box-shadow: 0px 4px 24px rgb(25 167 86 / 25%);
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: 60px;
    transform: translate3d(0.004%, -0.004%, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    will-change: transform;
    transition: all 0.5s;
  }

  .bw_branding_text_agency_icn_div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .bw_branding_text_agency_icn_div img {
    width: 17px;
  }

  .bw_branding_text_agency_and {
    display: flex;
    align-items: center;
    margin-left: 5px;
    top: -13px !important;
    text-align: center;
    justify-content: center;
  }

  .bw_branding_text_agency_img2 {
    width: 80px;
    height: 40px;
    margin-left: 15px;
  }

  .bw_branding_text_div3 {
    display: inherit !important;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    padding-right: 20px;
    display: table !important;
  }

  .bw_branding_text_design {
    display: block;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .bw_branding_text_design_img1.bw_branding_text_agency_img22 {
    float: left;
  }

  .bw_branding_text_studio_text {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 90px;
    width: 52%;
  }

  .bw_branding_text_design_img1 {
    width: 50px;
    height: 50px;
    margin: 0px 7px;
    margin-left: 45px;
  }

  .bw_branding_text_studio_img_circle {
    width: 45px;
    height: 45px;
    right: -24px;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box h1 {
    font-size: 37px !important;
    display: block;
    margin-bottom: 0px;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box h1 .were-img {
    padding: 0px 10px;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box h1 .were-img img {
    height: 50px;
    position: relative;
    top: -5px;
    border-radius: 100%;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .branding-txt {
    display: block;
  }
  .branding-box h1 span {
    line-height: 1.6;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box h1 .agency {
    padding-right: 20px;
    position: relative;
    display: block;
    width: fit-content;
    margin: auto;
  }

  .bw-branding-sec.bw_branding_text_mobile .agency-circle {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #2ac1b7;
    border-radius: 100%;
    right: 0;
    top: -20px;
    box-shadow: 0px 4px 24px rgba(25, 167, 86, 0.25);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  .bw-branding-sec.bw_branding_text_mobile .agency .agency-circle {
    right: -22px;
    top: -10px;
    width: 45px;
    height: 45px;
  }

  .bw-branding-sec.bw_branding_text_mobile .studio .agency-circle {
    right: -30px;
    top: -20px;
    width: 45px;
    height: 45px;
  }

  .bw-branding-sec.bw_branding_text_mobile .agency-circle svg {
    font-size: 22px;
    text-align: center;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    color: #fff;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .and-img {
    padding-left: 15px;
    display: inline-block;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .and-img img {
    height: 55px;
    position: relative;
    top: 0;
    width: 80px;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .design {
    display: block;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .studio-img {
    padding: 0 6px;
    display: inline-block;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .studio-img img {
    position: relative;
    top: 0;
    height: 52px;
  }

  .bw-branding-sec.bw_branding_text_mobile .branding-box .studio {
    position: relative;
    padding-right: 0px;
    display: inline-block;
  }

  .bw-branding-sec {
    padding: 40px 0 40px !important;
  }
  .bw_innovation_video_btn button::before {
    top: 60%;
    width: 50px;
    height: 50px;
  }
  .bw_innovation_video_btn button img {
    width: 50px;
  }
.bw_insta_content_left ul li h2 {
	left: -190px;
	font-size: 55px;
	bottom: 40px;
	top: 0;
}
  .bw_insta_content_left ul li.bw_insta_box {
    margin-left: 55px;
    margin-bottom: 40px;
  }
}


@media(min-width:768px){
  .bwn_insta_hading_in_mobile{
    display: none;
  }
  .pr-50.col-sm-8 {
    padding-right: 50px;
  }
}
@media (min-width: 320px) and (max-width: 359px) {
  .bw_insta_inner_content p {
    font-size: 13px;
  }
}
@media(min-width:320px) and (max-width:767px){
  .bw_about_comany_title_text .bw_btb_border_black .bw_custom_btn{
    display: none;
  }
  .bwn_insta_hading_in_mobile{
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #3D63C3;
    margin-bottom: 40px;
    font-weight: 700;
  }
  .bwn_insta_in_mobile_view{
    display: none;
  }
  .bwn_client_tab_logo ul li img {
    width: 100px;
    height: 45px;
}
  .bw_project_slider_text {
	margin: 0 auto;
	text-align: center;
}
ul.bw_client_logo_img_ul.only_home li {
	width: 50%;
	margin: 15px auto 15px;
	padding: 0 0;
}
}
@media (min-width: 768px) and (max-width: 1023px) {
  body .apiSecUnder {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    margin-top: 40px;

  }
  .bw_insta_content_left ul li.bw_insta_box {
    margin-left: 70px;
  }
  .bw_insta_content_left ul li h2 {
    left: -158px;
    font-size: 50px;
    top: 183px;
  }

  .bw_insta_inner_content button,
  .bw_insta_inner_content button:hover,
  .bw_insta_inner_content button:focus,
  .bw_insta_inner_content button:active {
    padding: 7px 18px;
  }
.google_review_slider_box img {
	width: 85px;
}
}
@media(min-width:1150px) and (max-width:1350px){
  section.bw_new_branding_section {
    background: #fff;
    padding: 20px 0px 0px;
  }
}
@media (min-width: 1025px) and (max-width: 1050px) {
  .bw_brand_slider_item img {
    width: 65%;
    padding: 5px 15px;
  }
}
@media (min-width: 1671px) and (max-width: 1850px) {
  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
    bottom: -45px !important;
    left: -412px !important;
  }
  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
    bottom: -45px !important;
    left: -335px !important;
  }
}

@media (min-width: 1440px) and (max-width: 1669px) {
  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
    bottom: -63px;
    left: -355px;
  }
  .bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
    bottom: -63px;
    left: -287px;
  }
}

@media (min-width: 1420px) and (max-width: 1250px) {
ul.bw_menu_list li a {
	font-size: 14px !important;
}
}
@media(min-width:1441px) and (max-width:1600px){
section.bw_header_section {
	padding: 5px 0px;
}
section.bw_header_section.is-sticky {
	padding: 5px 0;
}
}

@media(min-width:1200px) and (max-width:1440px){
section.bw_header_section {
	padding: 5px 0px;
}
.bw_header_dropdown-content {
	top: 27px;
}
section.bw_header_section.is-sticky {
	padding: 5px 0;
}
}