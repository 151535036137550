.bw_scroll_to_top .styles_scroll-to-top__2A70v svg {
  fill: #244604 !important;
  width: 100%;
  height: 25px;
}
.bw_scroll_to_top .styles_scroll-to-top__2A70v {
  bottom: 130px !important;
}

@media(max-width:767px) { 
  .bw_scroll_to_top .styles_scroll-to-top__2A70v {
    bottom: 90px !important;
    right: 20px;
  }
  .styles_scroll-to-top__2A70v {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bw_scroll_to_top .styles_scroll-to-top__2A70v {
    bottom: 130px !important;
    right: 35px !important;
  }
}
@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_scroll_to_top .styles_scroll-to-top__2A70v svg {
    height: 18px;
  }
  .bw_scroll_to_top .styles_scroll-to-top__2A70v {
    width: 33px;
    height: 33px;
  }
  .bw_scroll_to_top .styles_scroll-to-top__2A70v {
    bottom: 19%;
  }
}
