.bw_banner_sub_heading.gbp_text_new {
    width: 87%;
    margin: 0 auto;
}

.pricing_hading_box_section{
    padding: 50px 0;
background-color: #F5F7FC;
}
.pricing_img_text_innerbox {
	display: flex;
	vertical-align: middle;
	align-items: center;
	background: #FFFFFF;
	padding: 20px 20px;
}
.pricing_img_text_innerbox img{
    margin-right: 20px;
}
.pricing_img_text_innerbox h6 {
	font-weight: 500;
	font-size: 19px;
	line-height: 26px;
	color: #000;
	margin-bottom: 0;
}
.pricing_img_text_box {
	margin-bottom: 20px;
}
.\/listing-and-promotion-of-nventory .bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 10px 0 20px 0 !important;
}
/**************Responsive style********************/
@media(min-width:320px) and (max-width:767px){
.pricing_img_text_innerbox h6 {
	font-size: 16px;
}
.pricing_hading_box_section {
	padding: 30px 0;
}
.bw_banner_sub_heading.gbp_text_new {
    width: 100%;
    margin: 0 auto;
}

}

@media(min-width:1200px) and (max-width:1440px){
	.bw_banner_sub_heading.gbp_text_new {
		width: 83%;
		margin: 0 auto;
	}
.pricing_img_text_innerbox h6 {
	font-size: 15px;
}
.pricing_img_text_innerbox img {
	margin-right: 10px;
	width: 40px;
}
.bw_banner_heading.new_pricing_hading {
	font-size: 30px;
	padding-bottom: 3px;
	line-height: 30px;
}
.bw_banner_sub_heading {
	line-height: 23px;
}
}
