.bw_custome_footer_container.container {
  max-width: 1470px;
}

section.bw_footer_section {
  background-color: #1a1a1c;
  padding: 60px 0px 10px 0px;
  overflow: hidden;
  position: relative;
}
.bw_footer_img_left img {
  width: 70%;
  height: 70%;
}
.bw_footer_img_right img {
  width: 100%;
  height: 100%;
}
.bw_footer_link ul li a {
  text-decoration: none;
}

.bw_footer_link ul li a h3 {
  color: #ffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 43px;
  margin: 0;
}

.bw_footer_link ul {
  padding: 0;
  list-style: none;
}

.bw_footer_shape_div {
  margin-bottom: -5px;
}

.bw_footer_shape_div img {
  width: 100%;
}

.bw_footer_link h3 {
  color: #ffff;
  line-height: 53px;
  margin: 0;
  font-weight: 700;
  font-size: 23px;
}

.bw_footer_link ul li a {
  color: #fff;
  text-decoration: none;
}

.bw_footer_link ul li a h4 {
  line-height: 26px;
  font-weight: 400;
  font-size: 16px;
}

.bw_footer_link p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
  color: #ffff;
}

.bw_footer_img_left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bw_footer_logo_main ul li img {
  width: 80%;
}
.bw_footer_img_right {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 150px;
  height: 150px;
}

.bw_footer_social_icon ul li:nth-last-child(1) {
  margin: 0;
}

.bw_footer_social_icon ul li {
  display: inline-flex;
  margin-right: 10px;
  width: 40px;
  transition: all 0.5s ease-in-out;
}
.bw_footer_social_icon ul li:hover {
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}
.bw_footer_link ul li a img {
  width: 100%;
}

.bw_footer_info_text h3 {
  color: #ffff;
  margin: 0;
  font-weight: 700;
  font-size: 22px;
}
.bw_footer_info_text h3 a {
  color: #ffff;
  margin: 0;
  font-weight: 700;
  font-size: 22px;
}

.bw_footer_info_text {
  margin-top: 60px;
}

.bw_footer_logo_main ul {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.bw_footer_logo_main ul li {
  margin-right: 10px;
}

.bw_footer_logo_main {
  margin-top: 15px;
}

.bw_footer_logo_main p {
  font-weight: 400;
  font-size: 14px;
  color: #ffff;
}
img.bw_footer_logo_white {
  width: 100px;
}
/************ news letter box css ***********/

.bw_news_div {
  width: 74%;
  height: 300px;
  background: white;
  box-shadow: 0px 4px 94px rgb(0 0 0 / 11%);
  border-radius: 20px;
  margin: 0 auto;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
}
.bw_news_div ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 60px 0;
  margin: 0;
}
li.bw_news_second p {
  font-size: 16px;
  color: #000;
  text-align: center;
}
li.bw_news_second h2 {
  text-transform: uppercase;
  color: #30373e;
  font-weight: 700;
  font-size: 27px;
  line-height: 54px;
  text-align: center;
}
.bw_news_form button,
.bw_news_form button:focus,
.bw_news_form button:active,
.bw_news_form button:hover {
  position: absolute;
  top: 9px;
  right: 70px;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 0 !important;
}
.bw_news_form input,
.bw_news_form input:focus {
  width: 80%;
  margin: 0 auto;
  background: #ffffff;
  border: 4px solid rgba(222, 222, 222, 0.22);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
  border-radius: 50px;
  padding: 30px;
  outline: none;
}
.bw_news_form img {
  width: 50px;
}

/************ news letter box css ***********/
/* ------- link border bottom on hover start ------- */
.bw_footer_link ul li a h3 {
  display: inline-block;
}
.bw_footer_link ul li a h3:after {
  display: block;
  content: "";
  border-bottom: solid 1px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.bw_footer_link ul li a h3:hover:after {
  transform: scaleX(1);
}
.bw_footer_link ul li a h4 {
  display: inline-block;
}
.bw_footer_link ul li a h4:after {
  display: block;
  content: "";
  border-bottom: solid 1px #fff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  padding-bottom: 3px;
}
.bw_footer_link ul li a h4:hover:after {
  transform: scaleX(1);
}
/* ------- link border bottom on hover end ------- */

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_footer_link ul li a h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
  }
  .bw_footer_logo_white {
    width: 80px;
  }
  .bw_footer_info_text {
    margin-top: 22px;
  }

  .bw_footer_logo_main ul li {
    margin-right: 20px;
  }

  .bw_footer_logo_main p {
    text-align: center;
  }

  .bw_footer_logo_main {
    margin-top: 20px;
  }

  .bw_footer_logo_main ul {
    padding: 0;
    list-style: none;
    text-align: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_custome_footer_container.container {
    max-width: 390px;
  }
  .bw_footer_link ul li a h3 {
    font-size: 18px;
    line-height: 30px !important;
  }
  .bw_footer_info_text {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .bw_footer_link h3 {
    font-size: 18px;
  }
  .bw_footer_link ul li a h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .bw_footer_link p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0 !important;
    color: #ffff;
  }
  .bw_footer_link .bw_footer_address {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 25px !important;
    color: #fff !important;
  }
  .bw_footer_link ul li a img {
    max-height: 100%;
  }
  .bw_footer_logo_main {
    margin-top: 0px;
  }
  .bw_footer_logo_main ul {
    padding: 0;
    list-style: none;
    width: 100%;
    float: left;
  }
  .bw_footer_logo_main ul li {
    display: inherit;
    margin-right: 10px;
    width: 45%;
    float: left;
    margin-bottom: 15px;
  }
  .bw_footer_logo_main ul li img {
    width: 85%;
    object-fit: contain;
  }
  .bw_footer_logo_main p {
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
  .bw_footer_img_left {
    width: 35%;
    z-index: -1;
  }
  .bw_footer_img_left img {
    width: 100%;
  }
  .bw_footer_img_right {
    width: 35%;
    z-index: -1;
  }
  .bw_footer_img_right img {
    width: 100%;
  }
  section.bw_footer_section {
    z-index: 99;
    padding: 0px 0px;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_footer_link ul li a h3 {
    font-size: 20px;
    line-height: 35px;
  }
  .bw_footer_link h3 {
    line-height: 40px;
    font-size: 20px;
  }
  .bw_footer_link ul li a h4 {
    line-height: 25px;
    font-size: 14px;
  }
  .bw_footer_social_icon ul li {
    width: 30px;
  }
  .bw_footer_info_text {
    margin-top: 30px;
  }
  .bw_footer_logo_white {
    width: 70px;
  }
  .bw_footer_logo_main ul li img {
    width: 70%;
  }
  .bw_footer_logo_main p {
    font-size: 12px;
  }
  .bw_footer_link p {
    font-size: 12px;
    line-height: 17px;
  }
  .bw_footer_info_text h3 a {
    font-size: 18px;
  }
}


/******************** New Footer Style Start *******************************/
.bwn_footer_section {
	background: linear-gradient(180deg, #082262 0%, #3D63C3 100%);
	padding-top: 35px;
	font-family: 'Poppins' !important;
}
.bwn_footer_logo_box img {
	width: 250px;
	height: auto;
}
.bwn_footer_logo_subtext a p {
	color: #fff;
	font-weight: 400 !important;
	font-size: 16px;
	line-height: 30px;
}
.bwn_footer_section h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
    margin-bottom: 0;
    line-height: 64px;
}
.bwn_footer_quick_links_margin {
	margin-left: 70px;
}
.bwn_footer_quick_links_box ul li a {
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: #fff;
	display: inline-block;
	border-bottom: solid 1px transparent;
}
.bwn_footer_quick_links_box ul li a:after{
  display:block;
  content: '';
  border-bottom: solid 1px #fff;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  padding-bottom: 3px;
}
.bwn_footer_quick_links_box ul li a:hover:after { transform: scaleX(1); }
.bwn_get_in_touch_link_box img{
  margin-right: 20px;
  cursor: pointer;
}
.bwn_get_in_touch_link_box a {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
}
.bwn_client_logo_box {
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    position: relative;
}
.bwn_client_logo_box:before {
    content: '';
    position: absolute;
    border: 1px solid #ffffff4d;
    right: 103%;
    width: 100%;
}
.bwn_client_logo_box::after {
    content: '';
    position: absolute;
    border: 1px solid #ffffff4d;
    left: 103%;
    width: 100%;
}
.bwn_client_logo_box img {
	width: 100%;
	height: auto;
	cursor: pointer;
}
.bwn_sicon_width{
  width: 50px;
  height: 50px;
}
.bwn_footer_social_icon_box {
    width: 25%;
    margin: 0 auto;
    padding: 35px 0 20px 0;
    position: relative;
}
.bwn_footer_social_icon_box_new {
	width: 100%;
	margin: 0 auto;
	padding: 20px 0 20px 0;
	position: relative;
}
.bwn_footer_social_icon_box_new::before {
	position: absolute;
	content: '';
	border-bottom: 1px solid #e2e2e24d;
	width: 80%;
	left: -41%;
	bottom: 50%;
}
.bwn_footer_social_icon_box_new::after {
	position: absolute;
	content: '';
	border-bottom: 1px solid #e2e2e24d;
	width: 80%;
	right: -41%;
	bottom: 50%;
}
.bwn_footer_social_icon_inner_box {
	display: flex;
	justify-content: space-between;
	vertical-align: middle;
	align-items: center;
	width: 250px;
	margin: 0 auto;
}

.bwn_footer_social_icon_box::before {
    content: '';
    position: absolute;
    background: url('../images/footer-img/footer-left-img.png');
    width: 800px;
    height: 80px;
    left: -225%;
    background-repeat: no-repeat;
    opacity: 0.8;
    background-size: cover;
    object-fit: cover;
    bottom: 0;
}

.bwn_footer_social_icon_box::after {
    content: '';
    position: absolute;
    background: url('../images/footer-img/footer-right-img.png');
    width: 800px;
    height: 80px;
    right: -225%;
    background-repeat: no-repeat;
    opacity: .8;
    background-size: contain;
    bottom: 0;
    background-size: cover;
    object-fit: cover;
}
.bwn_footer_cpyright{
  background: #214190;
    
}
.bwn_footer_cpyright p {
  color: #ffffff40;
  font-size: 14px;
  text-align: center;
  line-height: 45px;
  margin: 0 auto;
}
.bwn_footer_iocn_circle {
	width: 50px;
	height: 50px;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
  cursor: pointer;
  transition: all .4s ease;
}
.bwn_footer_iocn_circle svg{
  font-size: 26px;
  color: #214190;
    transition: all .4s ease;
}
.bwn_footer_iocn_circle:hover svg{
color: #fff;
transition: all .4s ease;
}
.bwn_footer_iocn_circle.bwn_footer_bg_facebook:hover {
background-color: #1877F2;
transition: all .4s ease;
}
.bwn_footer_iocn_circle.bwn_footer_bg_insta:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
transition: all .4s ease;
}
.bwn_footer_iocn_circle.bwn_footer_bg_twitter:hover {
background-color: #55ACEE;
transition: all .4s ease;
}
.bwn_footer_iocn_circle.bwn_footer_bg_linkedin:hover {
background: #0a66c2 ;
transition: all .4s ease;
}
.bwn_footer_iocn_circle.bwn_footer_bg_youtube:hover {
background: #c4302b;
transition: all .4s ease;
}

.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle.bwn_footer_bg_facebook{
background-color: #1877F2;
transition: all .4s ease;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle.bwn_footer_bg_insta{
 background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
transition: all .4s ease;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle.bwn_footer_bg_twitter{
background-color: #55ACEE;
transition: all .4s ease;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle.bwn_footer_bg_linkedin{
background: #0a66c2 ;
transition: all .4s ease;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle.bwn_footer_bg_youtube{
background: #CD201F;
transition: all .4s ease;
}



.bwn_footer_address_box svg {
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
}
.bwn_footer_address_box.bwn_footer_mail_box svg{
 color: #fff;
    font-size: 20px;
    margin-right: 10px;
}
.bwn_footer_contact_box svg{
 color: #fff;
    font-size: 20px;
    margin-right: 20px;
}

.bwn_footer_contact_box {
    margin-bottom: 10px;
}
.bwn_footer_address_box {
    margin-bottom: 10px;
}
/* Responsive Style */
@media(min-width:320px) and (max-width:767px){
  .bwn_footer_section {
	padding-top: 15px;
}

.bwn_footer_logo_subtext a p {
	font-size: 14px;
	line-height: 22px;
	padding-left: 0;
}
.bwn_footer_cpyright p {
	font-size: 10px;
	line-height: 35px;
}
.bwn_footer_section h4 {
	font-size: 20px;
	line-height: 50px;
}
.bwn_footer_quick_links_box ul li a {
	font-size: 14px;
	line-height: 28px;
}
.bwn_get_in_touch_link_box a {
	font-size: 14px;
	line-height: 30px;
}
.bwn_get_in_touch_link_box img {
	margin-right: 15px;
}
.bwn_footer_quick_links_box{
  margin-left: 0 !important;
}
.bwn_client_logo_box img {
	width: 80%;
}
.bwn_client_logo_box {
	width: 100%;
}
.bwn_footer_iocn_circle {
	width: 35px;
	height: 35px;
}
.bwn_footer_social_icon_box {
	width: 80%;
  padding: 15px 0 15px 0;
}
.bwn_footer_iocn_circle svg {
	font-size: 17px;
}
.bwn_footer_social_icon_box::before {
	height: 60px;
	left: -345%;
  display: none;
}
.bwn_footer_social_icon_box::after {
	height: 60px;
	right: -345%;
  display: none;
}
.bwn_footer_quick_links_margin {
	margin-left: 0;
}
.bwn_footer_social_icon_inner_box {
	width: 170px !important;
}
.bwn_footer_social_icon_box_new::before {
display: none;
}
.bwn_footer_social_icon_box_new::after {
display: none;
}

}

@media(min-width:768px) and (max-width:1023px){
.bwn_footer_quick_links_margin {
	margin-left: 0 !important;
}
}
@media(min-width:768px) and (max-width:1024px){
  .bwn_footer_logo_box img {
	width: 65%;
}
.bwn_footer_section h4 {
	font-size: 22px;
	line-height: 50px;
}
.bwn_footer_logo_subtext a p {
	font-size: 14px;
	line-height: 32px;
	width: 85%;
  padding-left: 15px;
}
.bwn_footer_quick_links_box ul li a {
	font-size: 14px;
	line-height: 32px;
}
.bwn_get_in_touch_link_box a {
	font-size: 14px;
	line-height: 32px;
}
.bwn_get_in_touch_link_box img {
	margin-right: 15px;
}
.bwn_client_logo_box img {
	width: 75%;
}
.bwn_client_logo_box {
	width: 65%;
}
.bwn_footer_quick_links_box_tablate{
  padding-left: 15px;
}
.bwn_footer_iocn_circle {
	width: 35px;
	height: 35px;
}
.bwn_footer_iocn_circle svg {
	font-size: 19px;
}
.bwn_footer_social_icon_box {
	width: 35%;
	padding: 15px 0 10px 0;
}
.bwn_footer_social_icon_box::before {
	height: 50px;
	left: -305%;
}
.bwn_footer_social_icon_box::after {
	height: 50px;
	right: -305%;
}
.bwn_footer_cpyright p {
	font-size: 14px;
	line-height: 35px;
}
.bwn_footer_social_icon_inner_box {
	width: 190px;
}
.bwn_footer_social_icon_box_new::before {
	left: -45%;
}
.bwn_footer_social_icon_box_new::after {
	right: -45%;
}
}
@media(min-width:1150px) and (max-width:1440px){
  .bwn_client_logo_box img {
    width: 75%;
}
.bwn_footer_quick_links_margin {
	margin-left: 50px;
}
.bwn_footer_social_icon_box::before {
    height: 65px;
    left: -287%;
}
.bwn_footer_social_icon_box::after {
    height: 65px;
    right: -287%;
}
.bwn_footer_iocn_circle {
    width: 40px;
    height: 40px;
}
.bwn_footer_iocn_circle svg {
    font-size: 19px;
}
.bwn_footer_social_icon_box {
    padding: 30px 0 15px 0;
}
.bwn_footer_logo_box img{
   width: 200px;
}
.bwn_footer_logo_subtext a p {
    font-size: 12px;
    line-height: 22px;
}
.bwn_footer_quick_links_box ul li a {
    font-size: 12px;
    line-height: 15px;
}
.bwn_footer_section h4 {
    font-size: 18px;
    line-height: 50px;
}
.bwn_get_in_touch_link_box a {
    font-size: 12px;
    line-height: 22px;
}
.bwn_get_in_touch_link_box img {
    margin-right: 15px;
    width: 10px;
}
.bwn_footer_cpyright p {
    font-size: 14px;
    line-height: 35px;
}
.bwn_footer_social_icon_inner_box {
	width: 200px;
}
}
@media(min-width:1024px) and (max-width:1030px){
  .bwn_footer_quick_links_margin {
	margin-left: 0;
}
.bwn_footer_social_icon_box {
	width: 26% !important;
	padding: 15px 0 10px 0;
}
}

@media(min-width:1150px) and (max-width:1350px){}

/******************** New Footer Style end *******************************/