.bw_Moderate_text_box li{
	list-style: disc !important;
	font-family: 'poppins' !important;
	font-size: 12px !important;
	margin-bottom: 5px !important;
	padding-left: 5px !important;
	margin-left: 10px !important;
}
.bw_b2c_tool_tip_box .my-anchor-element svg, .bw_b2c_tool_tip_box .my-anchor-element1 svg, .bw_b2c_tool_tip_box .my-anchor-element2 svg, .bw_b2c_tool_tip_box .my-anchor-element3 svg, .bw_b2c_tool_tip_box .my-anchor-element4 svg, .bw_b2c_tool_tip_box .my-anchor-element5 svg, .bw_b2c_tool_tip_box .my-anchor-element6 svg   {
	width: 20px;
	height: 20px;
	color: #000 !important;
	cursor: pointer;
}



.bw_payment_getway_imgs_box{
	/* display: grid;
	flex-wrap: wrap;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	gap: 10px;
	margin: 10px auto 5px; */
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
	gap: 10px;
}
.bw_b2c_tool_tip_box span{
	margin-bottom: 20px;
}
.bw_payment_getway_imgs h6{
	font-size: 10px;
	margin-bottom: 5px;
}
.bw_payment_getway_imgs_box img{
	width: 25px;
	height: 25px;
	object-fit: contain;
	margin-bottom: 10px;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(230 235 247 / 55%);
}
.bw_pricing_note_table tbody tr td {
    text-align: left;
    vertical-align: middle;
    font-size: 14px !important;
    font-family: 'poppins' !important;
}
.bw_pricing_note_table thead tr th {
	font-size: 14px !important;
	font-family: 'poppins' !important;
}
.bw_pricing_note_table {
    width: 100%;
}
.tooltip_width {
	max-width: 300px;
	padding: 10px;
	font-size: 12px !important;
	border-radius: 8px;
	position: absolute;	
	z-index: 99999 !important;
	box-shadow: 0 0 1px #fff;
  }
.bw_b2c_tool_tip_box a {
	margin-left: 10px;
}
.bwn_pricing_dropdawn_box select {
	padding: 5px 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	float: right;
	color: #2c52b0;
	font-weight: 500 !important;
	background-color: #fff;
    font-size: 14px;
}
.bw_green_djdinr_list_style li {
	list-style: disc !important;
	font-family: 'poppins' !important;
	font-size: 14px !important;
	margin-bottom: 5px;
}
.bw_green_djdinr_list_style p {
    font-family: 'poppins' !important;
    font-size: 14px !important;
    margin-bottom: 5px;
}
.bwn_pricing_dropdawn_box select:focus{
    outline: none;
    box-shadow: 0;
}
.bwn_pricing_dropdawn_inner_box {
	position: absolute;
	top: 35px;
	right: 2.7%;
	z-index: 99;
}
.inr_input_main {
    margin-left: auto;
}
.inr_input_main .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
	color: #2c52b0 !important ;
}
.inr_input_main .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
	color: #2c52b0 !important ;
}
.inr_input_main .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #2c52b0 !important;
	border-width: 1px;
}
@media(min-width:320px) and (max-width:767px){
	.bw_green_djdinr_list_style li, .bw_green_djdinr_list_style p { 
	font-size: 14px !important;
}
.bwn_pricing_dropdawn_inner_box {
	position: absolute;
	top: 22px;
	right: 0;
	z-index: 99;
	left: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;
}
.list_inr_main{
    margin-bottom: 40px;
}
.inr_input_main {
    margin: auto;
	text-align: center;
}
.MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root {
	width: 200px; 
	margin: 0 auto;
   text-align: center;
}
.bw_pricing_tabs_section {
	padding: 55px 0 30px 0;
}
.bwn_pricing_dropdawn_box select {
	min-width: 200px;
}

}
@media(min-width:768px) and (max-width:1024px){
	.bw_green_djdinr_list_style li, .bw_green_djdinr_list_style p { 
		font-size: 13px !important;
		margin-bottom: 5px;
	}
.inr_input_main {
	margin-left: auto;
	margin-top: 46px;
	text-align: center;
}
.inr_input_main .MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root {
	width: 247px;
	text-align: center;
}
}
@media(min-width:1200px) and (max-width:1440px){
	.bw_payment_getway_imgs_box img{
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
.inr_input_main .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
	font-size: 12px !important;
}
.inr_input_main .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
	font-size: 12px;
}
.inr_input_main .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
	font-size: 12px;
}
.bw_green_djdinr_list_style li {
	font-size: 14px !important;
}
.bw_pricing_note_table tbody tr td {
    font-size: 12px !important;
}
.bw_pricing_montly_table tbody tr td {
    font-size: 12px !important;
}
}

@media (max-width: 768px) {
	.tooltip_width {
	  max-width: 90%;
	  font-size: 12px;
	}
  }
  
  @media (max-width: 480px) {
	.tooltip_width {
	  max-width: 90%;
	  font-size: 10px;
	}
  }

  @media(max-width:1024px){
	.bw_payment_getway_imgs_box img{
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
	.bw_pricing_note_table {
		width: 100%;
	}
	.table.bw_pricing_note_table td, .table.bw_pricing_note_table th {
		padding: .75rem;
		vertical-align: top;
		border-top: 1px solid #dee2e6;
		border-right: 1px solid #dee2e6;
		border-left: 1px solid #dee2e6;
		border-color: #dee2e6 !important;
	}
	.bw_pricing_note_table thead tr th {
		font-size: 13px !important;
		font-family: 'poppins' !important;
	}
	.bw_pricing_note_table tbody tr td {
		font-size: 13px !important;
	}
  }