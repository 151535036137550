.bw_globe_home_page canvas {
    width: 1000px !important;
    height: 500px !important;
}

/* .Tooltip_glb{
background-color: rgb(89, 116, 158) !important;
} */
.scene-nav-info,
.scene-tooltip {
    display: inline-block;
    /* position: absolute; */
    font-family: sans-serif;
    pointer-events: none;
    user-select: none;
    font-weight: 700;
    color: #fff !important;
    text-align: center;
    text-shadow: 1px 1px #000 !important;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
    font-size: 18px !important;
    display: block;
    /* font-family: "Poppins"; */
}

.bw_globel_section {
    padding: 50px 0 0 0;
}


.free_consalting_page {
    padding: 60px 0 0 0;


    .bw_contact_form_inner_box {
        background: transparent;
        border-radius: 0px;
        padding: 0px;
    }

    .bw_contact_form_outer_box {
        padding: 30px;
        width: 100% !important;
    }

    .left_section {
        padding-left: 60px;

        ul {
            list-style: disc inside;
            padding-left: 0;
            line-height: 1.8;

            li {
                position: relative;
                margin-bottom: 10px;
                padding-left: 20px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 10px;
                    width: 8px;
                    height: 8px;
                    background-color: var(--primary-color);
                    border-radius: 50%;
                }
            }

        }
    }



}


@media only screen and (max-width:991px) {
    .free_consalting_page {
        padding: 40px 0 40px 0;

        .left_section ul li {
            font-size: 14px;
        }
    }

}

@media only screen and (max-width:576px) {
    .free_consalting_page {
        padding: 40px 0 40px 0;

        .left_section {
            padding-left: 15px;
        }
    }

}

@media only screen and (max-width:350px) {
    body canvas {
        touch-action: none;
        width: 524px !important;
        height: 330px !important;
    }
}

@media(min-width:320px) and (max-width:767px) {
    .innerGlobeWrp {
        max-height: 380px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-top: -50px;
        margin-bottom: 50px;
    }



    canvas,
    .scene-container {
        /* width: 300px !important; */
        /* height: 200px !important; */
        display: flex !important;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        /* margin: 20px auto !important; */
        margin: -65px -305px !important;
    }

    canvas {
        touch-action: none;
        width: 610px !important;
        height: 380px !important;
    }

    .bw_globel_section .bw_about_comany_title_text p {
        font-size: 14px;
        width: 100%;
        text-align: center;
        display: block;
    }

    .bw_globel_section .bw_about_comany_title_text .bw_btb_border_black .bw_custom_btn {
        display: block;
        margin: 0 auto;
    }

    section.bw_insta_section {
        padding: 10px 0px 30px 0px !important;
    }

    .BW_mapGloble {
        width: 100%;
        padding-right: 15px;
        padding-left: 28px !important;
    }
}

@media(min-width:768px) and (max-width:1024px) {

    canvas,
    .scene-container {
        /* width: 400px !important; */
        /* height: 270px !important; */
        display: flex !important;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        margin: -65px -285px !important;
        /* margin: -65px -285px !important; */
    }
}

@media(min-width:1200px) and (max-width:1440px) {

    /* .scene-container{
        width: 640px !important;
        height: 420 !important;
        display: flex !important;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        margin: 0 auto !important;
    } */
    /* .bw_globel_section .bw_about_comany_title_text p {
        width: 100%;
    } */
    .BW_mapGloble {
        margin-left: -100px;
    }

    .bw_globel_section {
        padding: 0px;
    }

    section.bw_insta_section {
        padding: 0px 0px 70px 0px;
    }
}