.bw_integration_banner {
  background: url("../../Assets/images/integrations/int-bg-banner.webp");
  background-repeat: no-repeat;
  height: 847px;
  /* height: auto; */
  width: 100%;
}
.bw_integration_section h1 {
  font-weight: 700;
  font-size: 55px;
  line-height: 85px;
  color: #fff;
  text-align: left;
}
.bw_int_banner_heading_box p {
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  color: #fff;
  width: 475px;
}
.bw_int_banner_heading_box {
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-items: center;
}
.bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #2c52b0 !important;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize !important;
  font-family: 'Poppins' !important;
}
.bw_int_tab_section .css-1aquho2-MuiTabs-indicator {
  background-color: #2c52b0;
}
.bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
  border-right: 1px solid #ccc;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize;
  color: #464646;
  padding: 10px 100px;
  font-family: 'Poppins' !important;
}
.bw_int_tab_section button#simple-tab-2 {
  border-right: 0 !important;
}
.bw_int_tab_section .css-1ujnqem-MuiTabs-root {
  box-shadow: 0px 4px 85px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  width: max-content !important;
  margin: 0 auto;
  border-radius: 10px;
}
.bw_int_tab_section .css-1wf8b0h-MuiTabs-flexContainer {
  padding: 20px 0;
  justify-content: center;
}
.bw_int_tab_section
  .css-aym9vq-MuiButtonBase-root-MuiTab-root
  > .MuiTab-iconWrapper {
  margin-bottom: 15px;
}
.bw_int_tab_section .css-19kzrtu {
  padding: 64px 0 0 0;
}
.bw_int_product_box {
  background: #ffffff;
  box-shadow: 0px 4px 85px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  padding:0;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  cursor: pointer;
}
.bw_int_pro_img_box {
  min-height: 250px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}
/* .bw_int_product_box img {
  margin-bottom: 15px;
} */
.bw_int_product_box .bw_new_img_box img {
  margin-bottom: 0 !important;
  /* border-radius: 20px; */
  width: 85%;
}
.bw_int_product_btn {
  background: #3d63c321;
  border-radius: 8px;
  padding: 15px 30px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 80px;
  text-transform: uppercase;
}
.bw_int_product_btn:hover {
  color: #000;
}

/* .bw_tab_position.col {
  position: relative;
  top: -131px;
  left: 0;
  right: 0;
} */
.bw_int_tab_section {
  margin-top: -6%;
}
.bw_int_tab_img {
  width: 50px;
  height: auto;
}

/* .bw_int_tab_social img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.bw_int_tab_marketing img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
} */

.bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root img {
  filter: grayscale(1);
}
.bw_int_tab_section
  .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected
  img {
  filter: grayscale(0);
}
.bw_int_pro_img_box svg {
	font-size: 70px;
	color: #0b0b0ba1;
}
/*-------- Server style start------------ */
.bw_int_tab_section .css-1l4w6pd {
  padding: 20px 0;
  justify-content: center;
}
.bw_int_tab_section .css-1tnnsql.Mui-selected {
  color: #2c52b0 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
.bw_int_tab_section .css-1tnnsql {
  border-right: 1px solid #ccc !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  color: #464646 !important;
  padding: 10px 100px !important;
}
.bw_int_tab_section .css-ttwr4n {
  background-color: #2c52b0 !important;
}
.bw_int_tab_section .css-orq8zk {
  box-shadow: 0px 4px 85px rgb(0 0 0 / 9%) !important;
  background-color: #fff !important;
  width: 860px !important;
  margin: 0 auto !important;
  border-radius: 10px !important;
}
.bw_int_tab_section .css-1tnnsql > .MuiTab-iconWrapper {
  margin-bottom: 15px !important;
}
.bw_int_tab_section .css-1tnnsql.Mui-selected img {
  filter: grayscale(0);
}
.bw_int_tab_section .css-1tnnsql img {
  filter: grayscale(1);
}
.bw_int_tab_social .bw_int_pro_img_box img {
    width: 70px;
}
/*-------- Server style ending------------ */

/* ------------ Responsive Style -------------- */
@media (min-width: 320px) and (max-width: 767px) {
  .bw_int_pro_img_box svg {
	font-size: 60px;
}
  .bw_int_product_box .bw_new_img_box img{
    width: 100%;
  }
  .bw_int_banner_heading_box {
    justify-content: center;
  }
  .bw_int_banner_heading_box p {
    font-size: 16px;
    line-height: 32px;
    width: unset;
    text-align: center;
  }
  .bw_integration_section h1 {
    font-size: 35px;
    line-height: 45px;
    text-align: center;
  }
  .bw_int_tab_img {
    width: 32px;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    font-size: 13px;
    padding: 10px 10px;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 13px !important;
  }
  .bw_int_tab_section .css-1ujnqem-MuiTabs-root {
    width: max-content;
    margin: 0 auto !important;
  }
  .bw_int_tab_section .css-1wf8b0h-MuiTabs-flexContainer {
    padding: 10px 0;
  }
  .bw_int_tab_section
    .css-aym9vq-MuiButtonBase-root-MuiTab-root
    > .MuiTab-iconWrapper {
    margin-bottom: 8px;
  }
  .bw_int_tab_section .css-19kzrtu {
    padding: 30px 0 0 0;
  }

  .bw_int_pro_img_box {
    min-height: 180px;
  }

  /* server style */
  .bw_integration_banner {
    height: 680px;
  }
  .bw_int_tab_section .css-1tnnsql {
    padding: 8px !important;
  }
  .bw_int_tab_section .css-orq8zk {
    width: max-content !important;
  }
  .bw_int_tab_section .css-1tnnsql {
    font-size: 13px !important;
  }
  .bw_int_tab_section .css-1tnnsql,
  .bw_int_tab_section .css-1tnnsql.Mui-selected {
    font-size: 13px !important;
  }
  .bw_int_tab_section .css-1l4w6pd {
    padding: 5px 0;
  }
  .bw_int_tab_section {
    margin-top: -12%;
  }
  .bw_int_product_box {
	margin-bottom: 20px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
.bw_int_pro_img_box img {
	width: 120px;
}
.bw_int_tab_social .bw_int_pro_img_box img {
	width: 60px;
}
.bw_int_pro_img_box {
	min-height: 210px;
}
  .bw_int_banner_heading_box p {
    font-size: 16px;
    line-height: 30px;
    width: unset;
  }
  .bw_integration_section h1 {
    font-size: 35px;
    line-height: 45px;
  }
  .bw_integration_banner {
    height: 530px !important;
    background: url("../../Assets/images/integrations/int-tab-banner.png");
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    padding: 10px 77px;
  }
  .bw_int_tab_section .css-1ujnqem-MuiTabs-root {
    width: max-content;
  }
  .bw_tab_position.col {
    position: relative;
    top: -90px;
    left: 0;
    right: 0;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    font-size: 14px;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 14px;
  }
  .bw_int_product_btn {
    font-size: 15px;
    line-height: 80px;
  }
  .bw_int_tab_section .css-1wf8b0h-MuiTabs-flexContainer {
    padding: 15px !important;
  }
  /* server style */
  .bw_int_tab_section .MuiTabs-root.css-orq8zk {
    width: 700px !important;
    margin: 0 auto !important;
  }
  .bw_int_tab_section .css-1tnnsql {
    padding: 10px 66px !important;
  }
  .bw_integration_banner {
    height: 530px !important;
  }
  .bw_int_tab_section .MuiTabs-root.css-orq8zk {
    width: 500px !important;
    margin: 0 auto !important;
  }
  .bw_int_tab_section .css-1l4w6pd {
    padding: 20px 0 !important;
  }
  .bw_int_tab_section .css-1tnnsql {
    padding: 10px 40px !important;
    font-size: 16px !important;
  }
  .bw_int_tab_section .css-1tnnsql > .MuiTab-iconWrapper {
    margin-bottom: 10px !important;
  }
  .bw_int_tab_section .css-1tnnsql.Mui-selected {
    font-weight: 700 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
  }
  .bw_int_tab_section {
    margin-top: -10% !important;
  }
}
@media(min-width:1024px) and (max-width:1028px){
  .bw_int_pro_img_box {
	min-height: 150px !important;
}
}
@media (min-width: 1024px) and (max-width: 1159px) {
  .bw_integration_banner {
    height: 530px !important;
    padding: 80px 15px !important;
  }
}

@media (min-width: 1160px) and (max-width: 1360px) {
.bw_int_product_box img {
    margin-bottom: 0;
    width: 50%;
}
  .bw_int_tab_img {
    width: 40px;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    font-size: 14px;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 14px;
  }
  .bw_integration_section h1 {
    font-size: 40px;
    line-height: 60px;
  }
  .bw_int_banner_heading_box p {
    font-size: 15px;
    line-height: 30px;
  }
.bw_int_pro_img_box {
    min-height: 170px;
}
  .bw_int_product_btn {
    font-size: 14px;
  }
  /* .bw_int_product_box {
    padding: 20px 10px;
  } */
  .bw_int_tab_section .css-1ujnqem-MuiTabs-root {
    width: max-content;
  }
  .bw_int_tab_section .css-aym9vq-MuiButtonBase-root-MuiTab-root {
    font-size: 14px;
    padding: 0px 94px;
  }
  .bw_tab_position.col {
    top: -79px;
  }
  .bw_integration_banner {
    background-repeat: no-repeat;
    height: 595px;
  }
  .bw_int_product_box img {
    margin-bottom: 30px;
    height: auto;
  }
  .bw_int_image_tab1 .bw_int_product_box img {
    margin-bottom: 0;
  }
.bw_int_tab_social .bw_int_product_box img {
	margin-bottom: 0;
	width: 50px;
}
  .bw_int_tab_social .bw_int_pro_img_box {
    min-height: 150px;
}
.bw_int_product_box img{
      margin-bottom: 0;
}
.bw_int_pro_img_box svg {
	font-size: 60px;
	color: #0b0b0ba1;
}
.bw_int_tab_section .css-19kzrtu {
    padding: 34px 0 0 0;
}
}
@media(min-width:1440px) and (max-width:1600px){
  .bw_int_tab_social .bw_int_product_box img {
	margin-bottom: 0;
	width: 50px;
}
  .bw_int_pro_img_box {
	min-height: 170px;
}
  .bw_int_pro_img_box svg {
	font-size: 80px;
	color: #0b0b0ba1;
}
.bw_int_product_box img {
    margin-bottom: 0;
    width: 50%;
}
.bw_int_tab_section .css-19kzrtu {
    padding: 34px 0 0 0;
}
}