.jck_countdown_hours_main span {
	padding: 2px 5px;
	border-radius: 4px;
	width: 40px;
	background: linear-gradient(290.05deg, rgba(255, 255, 255, 0.17) 2.88%, rgba(255, 255, 255, 0.15) 97.6%);
}
  
  .jck_countdown span {
    font-size: 16px;
  }
  
  .jck_countdown_days span {
    background: linear-gradient(290.05deg, rgba(255, 255, 255, 0.17) 2.88%, rgba(255, 255, 255, 0.15) 97.6%);
    padding: 2px 5px;
    border-radius: 4px;
    display: block;
    width: 40px;
  }
  
  .conutdown_top_bar h3 svg {
    font-size: 20px;
  }
  
  .conutdown_top_bar h3 {
	font-weight: 500;
	font-size: 16px;
	color: white;
	display: flex;
	align-items: center;
	gap: 0px 5px;
	margin-bottom: 0;
    font-family: 'Poppins';
}
  
  .jck_countdown div {
    display: flex;
    align-items: center;
    gap: 0px 5px;
  }
  
  .jck_countdown {
    display: flex;
    justify-content: center;
    gap: 0px 5px;
    align-items: center;
  }
  
  .conutdown_top_bar {
    /* background: #2b9995; */
    background-image: url('../../Assets/images/jck-img/jck-top-nav.webp');
    width: 100%;
    height: 40px;
    background-size: cover;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    padding: 6px 20px;
    text-align: center;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 10px;
  }
  .jck_bbok_slot{
    font-weight: 700;
	font-size: 16px;
	color: white;
    text-decoration: underline !important;
    font-family: 'Poppins';
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .conutdown_top_bar h3 {
        justify-content: center;
    }

    .conutdown_top_bar {
        background-image: url('../../Assets/images/jck-img/jck-top-nav-mobile.webp');
        height: 75px;
        display: block;
    }  
    .conutdown_top_bar h3 {
        font-size: 12px;
    }
    .jck_countdown_hours_main span {
        padding: 0 5px;
        width: 35px;
    }
    .conutdown_top_bar h3, .jck_countdown, .jck_countdown div {
        display: flex;
        align-items: center;
        gap: 0 5px;
        line-height: 20px;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
.conutdown_top_bar h3 {
	font-size: 14px;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .conutdown_top_bar h3 {
        font-size: 15px;
    }
    }