.rcs_custom_select_country select {
	border-color: #fff;
	border-radius: 5px;
	box-shadow: none;
	font-size: 16px;
	margin-bottom: 1rem;
	padding: 11.5px 8px;
	width: 100%;
	color: #6c757d;
	background-color: #fff;
	box-shadow: none;
	border-radius: 6px !important;
	border: 1px solid rgba(0, 0, 0, 0.07);
	height: unset;
}

.banner_img, .bwn_banner_text_and_btn_outer_box {
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
/* .banner_img {
  background-image: url(../images/snow-img/Christmas-Banner-mobile.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 490px;
  width: 100%;
} */
.list_subcategory li::before{
  display: none;
  padding: 0;
}
.list_subcategory li {
	padding-left: 0 !important;
	list-style-type: circle;
	line-height: 18px !important;
	white-space: pre-line !important;
	color: #616161 !important;
	font-family: "Poppins" !important;
	padding: 0 !important;
	padding-bottom: 0 !important;
}
.list_subcategory {
	margin-top: 8px;
	border-bottom: 2px dotted rgb(196, 207, 226);
}
.bw_Card_design_100 {
    background-color: #9092951a;
    padding: 25px 0 10px;
    border-radius: 8px;
    border: 2px dotted #00000008;
}
.banner_img {
   background-image: url('../images/free-plans-smo/free-plans-banner.jpg');
    width: 100%;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    background-position:100% 100%;
    margin-top: 105px;
}
.banner_img.doller_100_plan_banner {
   background-image: url('../images/free-plans-smo/100-plan-banner1.png');
    width: 100%;
    height: 580px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    background-position:100% 100%;
    margin-top: 105px;
}
.bwn_banner_fpsmo_get_started_btn a{
  color: inherit;
}

.kap{
  position: relative;
}
.kap_img {
    width: 146px;
    /* margin: 0 auto; */
    text-align: center;
    position: absolute;
    top: -70px;
    left: -57px;
    right: 0px;
    display: none;
}
.snowfall_main canvas {
    top: 110px!important;
    z-index: 9!important;
}
.snow_img {
  /* background: #e6ebf7 url('../images/snow-img/christmas-bg-img.webp'); */
    width: 100%;
    min-height: 1450px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 3;
    object-fit: cover;
    background-position: center center;
}

.bwn_banner_text_and_btn_outer_box h2 {
  color:#fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 75px;
  margin-bottom: 0;
  text-align: left;
}
.bwn_banner_text_and_btn_outer_box p {
  color:#fff ;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  width: 100%;
}
.bwn_banner_fpsmo_get_started_btn {
  background-color: transparent !important;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff !important;
  font-family: poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px;
  padding: 15px 27px !important;
  text-transform: capitalize !important;
  transition: all .4s ease-in-out;
}

 /* button animation start */
 .bwn_animation_btn.bwn_new_sub_btn{
  background-color: #fff !important;
  color: #3d63c3 !important;
  border-color: #3d63c3 !important;
 }
.bwn_animation_btn {
    color: #fff;
    text-align: center;
    animation: shadow-pulse 1.5s infinite;
}
.bwn_animation_btn:hover{
  animation: none;
}
@keyframes shadow-pulse
{
  0% {
    /* box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.158); */
    box-shadow: 0 0 0 0px rgba(0, 98, 255, 0.218);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 119, 255, 0.192);
    /* box-shadow: 0 0 0 20px rgba(255, 0, 0, 0.171); */
  }
}


/****************************/

.bw_freeplans_smo_bgcolor {
  background-color: #3d63c321;
  padding-bottom: 80px;
}
.bw_free_plan_smo_heading {
  font-weight: 800;
  font-size: 30px;
  line-height: 55px;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.bw_free_plans_box_inner {
	background-color: white;
	padding: 40px 20px 64px;
	border-right: 1px solid rgb(228, 230, 236);
	min-height: 1610px;
	margin-bottom: 25px;
}
.bw_free_plans_box_inner h3 {
  color: rgb(8, 15, 26);
  font-weight: 700;
  white-space: pre-line;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.24px;
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.bw_free_plans_pricing_box {
  width: 100%;
  max-width: 190px;
  background-color: #3d63c321;
  height: 80px;
  border-radius: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 8px auto 0;
  font-weight: 600;
  font-family: "open sans";
}

.bw_free_price {
  font-size: 40px;
  line-height: 1.16;
  letter-spacing: -0.03em;
  font-weight: 800;
  color: #2c52b0;
}
.bw_free_currency {
  font-size: 11px;
  line-height: 1.36;
  position: absolute;
  top: 23px;
  padding-left: 55px;
  color: #616161;
}
.bw_free_currency.bw_zero {
  padding-left: 6px !important;
}
.bw_free_perMonth {
  font-size: 22px;
  line-height: 1.33;
  letter-spacing: -0.24px;
  padding-left: 5px;
  color: #616161;
  margin-top: 12px;
  margin-left: 2px;
}

.bw_start_my_subscription_btn {
  background: #2c52b0;
  border: 1px solid #2c52b0;
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  padding: 15px 60px;
  margin: 0 auto;
}
.bw_start_my_subscription_btn:hover {
  color: #244404;
  background: transparent;
  border: 1px solid #244404;
}
.bw_free_btn_box {
	margin-top: 50px;
	text-align: center;
	margin-bottom: 40px;
}
.bw_free_box_subheading p {
  color: #000;
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  font-family: "Poppins" !important;
}
.bw_free_box_subheading ul li {
  position: relative;
  padding-left: 44px;
  padding-top: 10px;
  padding-bottom: 10px !important;
  position: relative;
  line-height: 1.31;
  white-space: pre-line;
  color: #616161;
}

.bw_free_box_subheading ul li::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2c52b0;
  background-image: url("../../Assets/images/free-plans-smo/free-right-arrow.png") !important;
  background-size: 14px 14px;
  background-position: 51% 63%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
}
.bw_free_box_subheading ul li.bwn_free_plans_li_before_no::before {
  background-image: url("../../Assets/images/free-plans-smo/wrong-white.png") !important;
background-position: 50% 50%;
}
.bw_free_box_subheading ul li span {
  display: inline-block;
  font-family: "Poppins" !important;
}
.bw_free_box_subheading span:last-child {
  border-bottom: 2px dotted rgb(196, 207, 226);
}
.bw_free_font_waight {
  font-weight: 700;
}

@media (max-width: 767px) {
  .bw_free_plans_box_inner {
    min-height: auto;
    margin-bottom: 25px;
  }
}
@media(min-width:320px) and (max-width:359px){
    .banner_img.doller_100_plan_banner {
	height: 230px;
	margin-top: 100px;
}
}
@media(min-width:360px) and (max-width:767px){
  .bw_free_plan_smo_heading {
    font-size: 25px;
    line-height: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
}
    .banner_img.doller_100_plan_banner {
height: 280px;
	margin-top: 100px;
}
}

@media(min-width:320px) and (max-width:767px){
  .banner_img.doller_100_plan_banner {
	background-image: url('../images/free-plans-smo/100-plan-banner-mobile1.png');
}
.doller_100_plan_banner .bwn_banner_fpsmo_get_started_btn_outer_box {
	text-align: center;
	margin: 0 auto;
}
.doller_100_plan_banner .bwn_banner_text_and_btn_outer_box p {
	width: 100%;
	margin: 0 auto;
}
  .banner_img {
	margin-top: 100px;
}
  .banner_img, .bwn_banner_text_and_btn_outer_box {
	justify-content: left;
}
.bw_freeplans_smo_bgcolor {
	padding-bottom: 0px;
}
.snowfall_main canvas {
    top: 79px !important;
    z-index: 9 !important;
}
.bw_start_my_subscription_btn {
	font-size: 14px;
	padding: 15px 20px;
}
.bwn_banner_text_and_btn_outer_box h2 {
	margin-bottom:0;
	line-height: 30px;
	font-size: 19px;
	text-align: left;
}
.bwn_banner_text_and_btn_outer_box p {
	font-size: 13px;
	line-height: 25px;
	text-align: left;
	margin-bottom: 10px;
	width: 80%;
}
.bwn_banner_fpsmo_get_started_btn {
    padding: 10px 15px !important;
    font-size: 14px !important;
}
.kap_img {
	width: 150px;
	top: -58px;
	left: -23px;
}
.bwn_free_plan_smo_heading {
    font-size: 35px;
    line-height: 45px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.banner_img {
  position: relative;
}
.freeTrial_mobile{
  display: block;
}
.freeTrial_desktop{
  display: none;
}

.banner_img {
	   background-image: url('../images/free-plans-smo/free-plans-banner-mobileview.jpg');
	background-position: center;
	height: 330px;
	display: flex;
	justify-content: center;
	vertical-align: bottom;
	align-items: center;
	text-align: left;
  padding-bottom: 20px;
  background-position: 100% 100%;

}
.bw_free_plans_box_inner {
	padding: 30px 20px 25px;
	margin-bottom: 25px;
}
}


@media (min-width: 768px) and (max-width: 809px) {
  .banner_img.doller_100_plan_banner {
	height: 250px;
	margin-top: 95px;
}
}
@media (min-width: 810px) and (max-width: 991px) {
.banner_img.doller_100_plan_banner {
	height: 260px;
	margin-top: 95px;
}
}
@media (min-width: 992px) and (max-width: 1024px) {
.banner_img.doller_100_plan_banner {
	height: 320px;
	margin-top: 85px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {

  .banner_img {
	margin-top: 100px;
}
  .snowfall_main canvas {
    top: 80px !important;
    z-index: 9 !important;
}
.bwn_banner_fpsmo_get_started_btn {
	font-size: 14px !important;
	padding: 12px 20px !important;
}
.banner_img {
	height: 350px;
}
  .bw_container_full_width {
    max-width: 100% !important;
  }
  .bw_free_plans_box_inner h3 {
    font-size: 22px;
  }
  .bw_free_price {
    font-size: 40px;
  }
  .bw_free_perMonth {
    font-size: 20px;
  }
  .bw_free_currency {
    font-size: 10px;
  }
  .bwn_banner_text_and_btn_outer_box h2 {
	font-size: 21px;
	line-height: 35px;
}
.bwn_banner_text_and_btn_outer_box p {
	font-size: 14px;
	line-height: 25px;
}
.bw_start_my_subscription_btn {
	font-size: 10px;
	padding: 12px 10px;
	margin: 10px auto;
	font-weight: 600;
}
.bw_free_plans_pricing_box {
	height: 60px;
}
  .bw_free_currency {
    top: 17px;
  }
  .bw_free_box_subheading ul li {
    padding-left: 35px;
  }
  .bw_free_box_subheading ul li span {
    font-size: 14px;
  }
.bw_free_plans_box_inner {
	min-height: 2005px;
	padding: 40px 12px 64px;
}
  .bw_freeplans_smo_bgcolor {
    padding-bottom: 30px;
  }
  .bw_free_plans_pricing_box {
    margin-bottom: -30px;
  }
.snowfall_main canvas {
    top: 80px!important;
    z-index: 9!important;
}
.bw_free_btn_box {
	margin-top: 60px;
	margin-bottom: 40px;
}
.bw_free_box_subheading ul li::before {
	width: 20px;
	height: 20px;background-size: 13px 13px;
	background-position: 51% 74%;
}
.kap_img {
	left: -35px;
}
}
@media (min-width: 1100px) and (max-width: 1199px) {
.banner_img {
	height: 430px;
}
.bwn_banner_text_and_btn_outer_box h2 {
	color: #fff;
	font-size: 29px;
	line-height: 45px;
}
.bwn_banner_text_and_btn_outer_box p {
	font-size: 17px;
	line-height: 25px;
}
.bwn_banner_fpsmo_get_started_btn {
	font-size: 15px !important;
	padding: 12px 25px !important;
}
.bw_start_my_subscription_btn {
	padding: 15px 15px;
}
}

@media(min-width:1250px) and (max-width:1440px){
.banner_img {
	height: 450px;
	margin-top: 55px;
}
.bwn_banner_text_and_btn_outer_box p {
    font-size: 16px;
}
.bwn_banner_text_and_btn_outer_box h2 {
    font-size: 30px;
    line-height: 45px;
}
.bwn_banner_fpsmo_get_started_btn {
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 18px;
    padding: 9px 15px !important;
}
.bw_start_my_subscription_btn {
    font-size: 12px;
    padding: 15px 30px;
}
}
@media(min-width:1200px) and (max-width:1440px){
  .bw_free_box_subheading ul li span {
	font-size: 13px;
}
.list_subcategory li {
	font-size: 13px;
  line-height: 8px !important;
}
  .bw_contact_us_form .form-control {
	font-size: 14px;
}

  .rcs_custom_select_country select {
	font-size: 14px;
	padding: 5.5px 8px;
}
  .banner_img.doller_100_plan_banner {
	height: 400px;
  margin-top: 75px;
  
}
.bw_free_plans_box_inner {
	min-height: 1570px;
}
.bw_freeplans_smo_bgcolor {
	padding-bottom: 0px;
}
.banner_img {
	margin-top: 75px;
}
}
@media(min-width:1150px) and (max-width:1350px){
.banner_img {
	margin-top: 63px !important;
}
}
@media(min-width:1441px) and (max-width:1600px){
.banner_img {
	margin-top: 75px;
  	height: 500px;
}
.banner_img.doller_100_plan_banner {
	height: 490px;
	margin-top: 75px;
}
}
@media(min-width:1024px) and (max-width:1099px){
.banner_img {
	background-position: 100% 100%;
	margin-top: 85px;
	background-size: cover;
}
}