.bw_ppContent_box {
  padding: 25px 0;
}
.bw_ppContent_box h6 {
  font-weight: 600;
  font-size: 28px;
  line-height: 65px;
  color: #000000;
}
.bw_ppContent_box ul {
  padding-left: 25px;
}
.bw_ppContent_box ul li p {
  font-weight: 500;
}
.bw_ppContent_box ul li {
  list-style-type: circle;
  font-weight: 400;
  font-size: 16.5px;
  line-height: 30px;
  color: #313131;
  font-family: "Poppins" !important;
}
.bw_ppContent_box p {
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #313131;
  text-align: justify;
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_ppContent_box h6 {
    font-size: 25px;
    line-height: 40px;
  }
  .bw_ppContent_box h6 {
    font-size: 23px;
    line-height: 28px;
    text-align: center;
  }
  .bw_ppContent_box p {
    font-size: 14px;
    line-height: 23px;
  }
  .bw_ppContent_box ul li {
    font-size: 14px;
    line-height: 23px;
  }
  .bw_ppContent_box {
    padding: 20px 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_ppContent_box {
    padding: 30px 0;
  }
  .bw_ppContent_box h6 {
    font-size: 22px;
    line-height: 45px;
  }
  .bw_ppContent_box p {
    font-size: 15px;
  }
  .bw_ppContent_box ul li {
    font-size: 15px;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_ppContent_box {
    padding: 30px 0;
  }
  .bw_ppContent_box h6 {
    font-size: 20px;
    line-height: 40px;
  }
  .bw_ppContent_box p {
    font-size: 14px;
  }
  .bw_ppContent_box ul li {
    font-size: 14px;
  }
}
