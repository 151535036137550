                .bw_mobile_view_of_portfolio_logos_btn.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #3D63C3 !important;
     border: 1px solid #3D63C3 !important;
       
}
.bw_mobile_view_of_portfolio_logos_btn {
    color: #4b4a4a ;
    border: 1px solid #4b4a4a ;
    margin: 5px 5px !important;
    font-weight: 700 !important ;
    font-size: 14px ;
    font-weight: 600 ;
      min-height: 40px ;
}
.bw_client_logo_img_ul_new li {
	display: inline-block;
	width: 50%;
	margin: 0 auto;
}
.bw_new_client_logo_design_box img {
	width: 120px;
	height: auto;
	margin: 15px;
}
.bw_client_logo_img_ul_new_design li {
	display: inline-block;
	width: 50%;
}
.bw_client_logo_img_ul_new_design{
	height: 350px;
	overflow: auto; 
}
.bw_new_client_logo_design_box {
	text-align: center;
}

@media(min-width:768px){
    .bw_mobile_view_of_portfolio_logos{
        display: none;
    }
}

@media(min-width:320px) and (max-width:359px){
    .bw_new_client_logo_design_box img {
	width: 100px;
}
}