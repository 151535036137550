.topnav-bg {
	/* padding: 5px 0; */
	/* background: linear-gradient(to right, rgba(9 72 251 / 78%), rgba(239,58,58)); */
	background-image: url('../../Assets/images/jck-img//jck-top-nav.png');
	background-position: 100% 100%;
	width: 100%;
	height: 40px;
	color: #fff;
}
.topnav {
	display: flex; 
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	/* background: #375cb8; */
	color: #fff;
	gap: 10px;
}
.topnav a{
	color: #fff;
}
.count-down_container{
	display: flex;
	gap: 10px;
}
.top-inner-box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.top-inner-box span{ 
    font-weight: 800;
	font-size: 11px;
    text-transform: uppercase;
    margin-top: 2px;
}
.top-inner-box b {
	background: #fff;
	border-radius: 5px;
	padding: 0px 5px;
	width: 30px;
	text-align: center;
	color: #000;
	font-size: 12px;
	font-weight: 600;
}


@media(max-width:767px)
{
	.topnav {
		flex-direction: column;
		text-align: center;
	}
	.count-down_text {
		font-size: 13px;
	}
	.bw_banner_img_main img {
		margin-top: 10px;
	}
}