.bw_contact_banner {
  background: url("../../Assets/images/contactus/contact-banner-bgimg.webp");
  background-repeat: no-repeat;
 height: 657px;
  width: 100%;
  background-size: cover;
  background-position: bottom !important;
}
.bw_all_banner.bw_contact_banner.bw_jck_banner {
	background: url('../../Assets/images//jck-img/jck-banner-24-desktop.webp');
	background-repeat: no-repeat;
	height: 920px;
	width: 100%;
	background-size: cover;
	background-position: 100% !important;
}

.bw_jck_yes
{
  background: url(../../Assets/images/jck-yes.jpg);
background-position: 100% !important;
background-repeat: no-repeat;
background-size: cover;
height: 920px;
width: 100%;
}
.bw_jck_yes h2 {
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 20px; 
}
.bw_jck_yes.bw_jck_no
{
  background: url(../../Assets/images/jck-no.jpg);
background-position: 100% !important;
background-repeat: no-repeat;
background-size: cover;
height: 730px; 
width: 100%;
} 
.jck_yes_thankyou {
	font-weight: 600;
	font-size: 24px !important;
	margin-bottom: 5px;
	width: 100%;
	margin: 0 auto;
	line-height: 1.7; 
}
.boothnbtn
{
  text-align: center; 
}
.boothn {
	background: #fff;
	padding: 10px 50px;
	border-radius: 65px;
	color: #0c276a;
	font-weight: 800; 
	text-transform: uppercase;
	display: inline-block;
	margin: 0 auto;
	text-align: center;
	width: auto;
	font-size: 65px;
	line-height: 1;
}
.jck_yes_btn {
	font-size: 26px;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	gap: 30px;
	margin: 20px 0; 
}
.jck_yes_btn a {
	background: #fff;
	color: #04295e;
	padding: 0px 30px;
}
.excited_yes {
	font-size: 20px;
}
.bw_jck_yes h1 {
	font-size: 54px;
}
.bw_contact_banner h1 {
	font-size: 40px;
	text-align: center;
	margin-bottom: 20px;
	font-weight: 600;
}
select.form-control.sales_representative_select.form-select {
  height: 44px !important;
}
.bw_Contact_link {
  color: #fff;
  line-height: 35px;
  justify-content: center;
  vertical-align: middle;
  font-size: 18px;
  margin-bottom: 30px;
}
.bw_Contact_link svg {
  margin: 0 10px;
  font-size: 17px;
}
.bw_contact_form_outer_box {
  background: #ffffff;
  box-shadow: 0px 4px 85px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 45px;
}
.bw_contact_form_outer_box h2 {
  font-weight: 600;
  font-size: 38px;
  line-height: 51px;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
.bw_contact_form_inner_box {
  background: #3d63c20f;
  border-radius: 10px;
  padding: 40px;
}
.bw_contact_us_form label {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}

.bw_contact_form_inner_box .react-tel-input .form-control {
  width: 100% !important;
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.07);
  line-height: 30px !important;
  height: unset !important;
}
.bw_contact_form_inner_box .react-tel-input .form-control.invalid-number {
  border: 1px solid rgba(0, 0, 0, 0.07) !important;
  background-color: #fff !important;
  border-left-color: rgba(0, 0, 0, 0.07) !important;
}
.bw_contact_form_inner_box .react-tel-input .form-control.invalid-number:focus{
  border-color: #ced4da !important;
}
.react-tel-input:focus .flag-dropdown.open .selected-flag{
   border-color: #ced4da !important;  
}
.bw_contact_form_inner_box .react-tel-input .flag-dropdown.invalid-number {
  border-color: rgba(0, 0, 0, 0.07);
}
.bw_contact_form_inner_box .react-tel-input .invalid-number-message {
  display: none !important;
}
.bw_contact_us_form button, .btnStyle {
	background-color: #2c52b0;
	color: #fff;
	padding: 10px 30px;
	font-size: 16px;
	text-align: center;
	text-transform: capitalize;
	margin: 0 auto;
	font-weight: 600;
	margin-top: 20px;
	border-color: transparent;
	transition: all 0.2s ease-in-out;
	border: 1px solid #2c52b0;
	min-width: 180px;
}
.bw_loader_style .sl-spinner3 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
  max-height: 25px;
}

/* .bw_loader_style{
  background-color: transparent;
  z-index: 1;
  display: block;
  margin:0 !important;
 max-width: 100px;
 max-height: 40px;
 overflow: hidden;
} */
/* .bw_contact_us_form button:hover {
  background-color: transparent;
  color: #2c52b0;
  transition: all 0.2s ease-in-out;
} */
.bw_contact_us_form .form-control {
  /* padding: 23px 18px; */
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.07);
  line-height: 30px !important;
  height: unset !important;
}
.bw_contact_us_form .form-control:focus,
.bw_contact_us_form .form-control:hover,
.bw_contact_us_form .form-control:active {
  border-color: #ced4da;
  outline: 0 !important;
  box-shadow: none !important;
}

.bw_contact_address_box {
  padding: 55px 90px;
  width: 100%;
}
.bw_contact_address_box svg {
    color: #2c52b0;
    font-size: 35px;
    margin-right: 15px;
    cursor: pointer;
}
.bw_contact_address_box h6 {
  color: #2c52b0;
  font-weight: 600;
  font-size: 23px;
  line-height: 35px;
  cursor: pointer;
}
.bw_contact_address_box p {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #8e8e8e;
  cursor: pointer;
  margin-bottom: 0;
}
.bw_contact_address_box a {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #8e8e8e;
}
.bw_contat_icon_inner {
  margin: 25px 0;
}
.bw_new_position_box {
  margin-top: -560px;
}

.bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
  margin-top: -210px;
}

.bwn_contact_map_box {
  display: table !important;
  width: 100% !important;
}
.bw_contact_map_img {
  max-height: 550px !important;
  width: 100%;
}
.bw_new_position_box.bw_contact_position {
	margin-top: -400px;
}
.bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
	margin-top: -450px;
}
.bw_addres {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background: hwb(223deg 24% 24% / 6%);
  width: 100%;
  height: 535px;
  margin: 20px auto;
}
.bw_contact_map_box {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 100%;
  margin: 20px auto;
}
.bw_contact_map_box iframe {
  max-height: 535px;
}
.bw_contact_form_outer_box select::placeholder {
  font-size: 12px;
  font-family: "poppins" !important;
}
/* Caprtcha style */
.bw_captchaa {
  width: 300px;
}
.bw_captchaa .rc-anchor-error-message {
  color: #2c52b0  !important;
  font-family: "poppins" !important;
  font-size: 12px !important;
}
.rc-anchor-normal-footer {
  width: 20%;
}

@media (min-width: 320px) and (max-width: 767px) {
 
  .bw_jck_yes.bw_jck_no .jck_yes_thankyou {
    font-size: 16px !important;
    line-height: 1.5;  
  }
  /* .bw_jck_yes.bw_jck_no .jck_yes_thankyou br {
      display: none; 
  } */

  .bw_new_position_box.bw_contact_position {
	margin-top: -220px;
}
.bw_jck_yesbx .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
	margin-top: -200px !important;
}

.bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
	margin-top: -50px;
}
.bw_all_banner.bw_contact_banner.bw_jck_banner {
	background: url('../../Assets/images/jck-img/jck-banner-24-mobile.webp');
	height: 300px;
	background-position: 100% 100% !important;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}
  .rcs_g-recaptcha {
    transform: scale(0.73);
    -webkit-transform: scale(0.73);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
 
  .bw_Contact_link {
    line-height: 30px;
    font-size: 18px;
  }
  .bw_contact_form_outer_box {
    padding: 10px;
  }
  .bw_contact_form_inner_box {
    padding: 10px;
  }
  .bw_contact_form_outer_box h2 {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 35px;
  }
  .bw_contact_us_form button {
    font-size: 14px !important;
    margin-top: 20px !important;
    /* width: 120px !important; */
    height: 40px !important;
    /* padding: 0 !important; */
  }
  .bw_contact_address_box {
    padding: 30px 20px;
  }
  .bw_contact_address_box h6 {
    font-size: 20px;
    line-height: 30px;
  }
  .bw_contact_address_box svg {
    font-size: 28px;
  }
  .bw_contact_address_box p {
    font-size: 15px;
    line-height: 30px;
  }
  .bw_contact_address_box a {
    font-size: 15px;
    line-height: 30px;
  }
  .bw_contact_us_form label {
    font-size: 15px;
    line-height: 28px;
    font-family: "poppins";
  }
  .bw_contact_us_form .form-control {
    line-height: 25px !important;
  }
  .bw_contact_form_inner_box .react-tel-input .form-control {
    line-height: 24px !important;
  }
.bw_new_position_box {
	margin-top: -200px;
}
  .bw_contact_banner {
    height: 450px;
    background-position: bottom !important;
  }
  .bw_addres {
    height: 445px;
    margin-bottom: 15px;
    margin-top: 0;
  }
  .bw_captchaa .rc-anchor-normal {
    width: 218px !important;
  }

  .bw_contact_banner.bw_package_form_banner h1 {
    font-size: 28px;
    margin-bottom: 5px;
  }
  .bw_jck_yes
  {
    background: url(../../Assets/images/jck-yes-mob.jpg); 
    height: 600px;   
  }
  .bw_jck_yes.bw_jck_no 
  {
    background: url(../../Assets/images/jck-no-mob.jpg); 
    height: 600px !important;     
  }
  .bw_jck_yes h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 15px; 
  } 
  .bw_contact_banner h1 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500; 
  }
  .boothn {
    background: #fff;
    padding: 10px 30px;
    border-radius: 65px;
    color: #0c276a;
    font-weight: 800;
    text-transform: uppercase;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    width: auto;
    font-size: 30px;
    line-height: 1;
  }
  .jck_yes_btn {
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    gap: 20px; 
    margin: 20px 0;
  }
  .jck_yes_btn a {
    background: #fff;
    color: #04295e;
    padding: 3px 30px;
  }
  .jck_yes_thankyou {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 1.4;
  }
  .excited_yes {
    font-size: 16px;
  }
}
@media(min-width:320px) and (max-width:359px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 250px !important;
  }
}
@media(min-width:360px) and (max-width:409px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 310px !important;
  }
}
@media(min-width:410px) and (max-width:600px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 340px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
.bw_new_position_box.bw_contact_position {
	margin-top: -300px;
}
select.form-control.sales_representative_select.form-select {
  height: 38px !important;
}
  .bw_contact_banner h1 {
    font-size: 45px;
  }
  .bw_Contact_link {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .bw_contact_us_form label {
    font-size: 15px;
    line-height: 28px;
  }
  .bw_contact_form_outer_box {
    padding: 20px;
  }
  .bw_contact_form_inner_box {
    padding: 20px;
  }
  .bw_contact_form_outer_box h2 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
  }
  .bw_contact_us_form button {
    margin-top: 20px;
  }
  .bw_contact_address_box h6 {
    font-size: 18px;
    line-height: 20px;
  }
  .bw_contact_address_box {
    padding: 15px 20px;
  }
  .bw_contact_map_box img {
    margin-bottom: 20px;
    max-height: 550px !important;
  }
  .bw_contact_address_box svg {
    font-size: 25px;
  }
  .bw_new_position_box {
    margin-top: -320px;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -160px;
  } 
  .bw_contat_icon_inner {
    margin: 40px 0;
  }
  .bw_contact_address_box p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }
  .bw_contact_address_box a {
    font-size: 16px;
    line-height: 30px;
  }
  .bw_contact_us_form .form-control {
    line-height: 25px !important;
  }
  .bw_contact_form_inner_box .react-tel-input .form-control {
    line-height: 24px !important;
  }
  .bw_contact_us_form button {
    padding: 7px 40px;
  }

}
@media (min-width: 768px) and (max-width: 991px) {
.bw_all_banner.bw_contact_banner.bw_jck_banner {
  height: 380px;
}
}
@media (min-width: 992px) and (max-width: 1024px) {
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 480px;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -200px !important;
  }
  .bw_contact_form_outer_box {
    width: 85%;
    margin: 0 auto;
  }
  }
  


@media (min-width: 1024px) and (max-width: 1159px) {
  .bw_new_position_box {
    margin-top: -295px;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -150px;
  } 
}
@media (min-width: 1160px) and (max-width: 1360px) {
  .bw_contact_banner h1 {
    font-size: 35px;
  }
  .bw_Contact_link {
    line-height: 25px;
    font-size: 15px;
  }
  .bw_contact_form_outer_box {
    padding: 25px;
    margin: 0 auto;
    width: 70%;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -270px;
  }
    .bw_contact_form_outer_box h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .bw_contact_us_form label {
    font-size: 12px;
    line-height: 12px;
  }
  .bw_contact_us_form .form-control {
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);
  }
  .bw_contact_us_form button {
    padding: 5px 15px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bw_contact_form_inner_box {
    padding: 25px;
  }
  .bw_contact_us_form input::placeholder {
    font-size: 12px;
  }
  .bw_contact_us_form textarea::placeholder {
    font-size: 12px;
  }
  .bw_contact_us_form .react-tel-input::placeholder {
    font-size: 12px;
  }
  .bw_contact_address_box h6 {
    font-size: 18px;
    line-height: 30px;
  }
  .bw_contact_address_box svg {
    color: #244404;
    font-size: 25px;
  }
  .bw_contact_address_box p {
    font-size: 14px;
    line-height: 25px;
  }
  /* .bw_contact_address_box {
    height: 535px;
  } */
  .bw_contact_address_box a {
    font-size: 14px;
    line-height: 25px;
  }
  .bw_contact_map_img img {
    height: 420px !important;
    width: 100%;
  }
  .bw_contact_us_form .form-control {
    line-height: 20px !important;
    font-size: 12px;
  }
  .bw_contact_form_inner_box .react-tel-input .form-control {
    line-height: 20px !important;
  }
  .bw_contact_banner {
    height: 490px;
    background-position: bottom;
  }
  .bw_jck_yes {
    height: 650px;
  }

  .bw_new_position_box.bw_contact_position {
    margin-top: -293px;
  } 
  .bw_consulation_from_position select.form-control {
    padding: 7px 10px !important;
  }
  .bw_contact_form_outer_box select {
    color: #495057 !important;
    font-size: 12px !important;
    font-weight: 400;
  }
}

@media (min-width: 1005px) and (max-width: 1148px) {
  .bw_new_position_box {
    margin-top: -257px;
}

}

@media (min-width: 1200px) and (max-width: 1440px) {

/* select.form-control.sales_representative_select.form-select {
  height: 31.5px !important;
} */
.bw_all_banner.bw_contact_banner.bw_jck_banner {
	height: 680px;
}
.bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
	margin-top: -310px;
}

}
@media (min-width: 1150px) and (max-width: 1350px) {

  .bw_contact_form_inner_box .react-tel-input .form-control.invalid-number {
    padding-left: 50px;
  }
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 540px;
  }
  select.form-control.sales_representative_select.form-select {
  height: 31.5px !important;
}
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -230px;
  }
  .bw_jck_yes.bw_jck_no {
    height: 640px !important; 
  }
}

.btnStyle:hover {
  background: #0c276a;
  border-color: #0c276a;
  color: #fff;
}
.bw_contact_us_form .btnStyle {
  display: inline-block;
}

@media(min-width:1441px) and (max-width:1900px){
  /* .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 740px;
  } */
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -320px;
  }
}
@media(min-width:1441px) and (max-width:1650px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 680px;
  }
}
@media(min-width:1651px) and (max-width:1799px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 800px;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -340px !important;
  }
}
@media(min-width:1800px) and (max-width:1900px){
.bw_all_banner.bw_contact_banner.bw_jck_banner {
	height: 900px;
}
.bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
	margin-top: -410px;
}
}

@media(min-width:1025px) and (max-width:1149px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 510px;
  }
  .bw_new_position_box.bw_jck_position.bw_jck_banner_desk {
    margin-top: -210px;
  }
}

@media(min-width:600px) and (max-width:719px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 490px !important;
  }
}
@media(min-width:720px) and (max-width:766px){
  .bw_all_banner.bw_contact_banner.bw_jck_banner {
    height: 600px !important;
  }
}