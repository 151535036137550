.bw_banner_pricing {
  /* background-color: #f7fbf4 !important; */
  background-image: url("../../Assets//images/home/bg-green-top-banner.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}


.bw_banner_pricing.bw_banner_pricing_aed {
  /* background-color: #f7fbf4 !important; */
  background-image: url("../../Assets//images/aed-img/aed-desktop-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
/* .bw_pricing_tabs_box button {
  z-index: 88;
} */
.band_bilder_video video {
	width: 100% !important;
	height: auto !important;
	object-fit: cover !important;
	max-width: 100%;
	margin: 8px auto 15px;
}
.bwn_shopify_plugin_sectionnn .css-1l4w6pd {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  width: max-content;
  margin: 0 auto;
}
.band_bilder_video {
  padding: 0 7px;
}
.bwn_shopify_swp {
	position: absolute;
	right: 0;
	bottom: 0;
}
.bwn_swp {
	position: absolute;
	left: 0;
	bottom: 0;
}

.bw_swdddd_padding_zero .bw_monthly_tab_outer_box .css-19kzrtu {
  padding: 0;
}
span.bwn_price_count_up {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 1.3 !important;
    color: #2c52b0 !important;
    margin-bottom: 0 !important;
}
.bw_table_had_bgColor span.bwn_price_count_up {
    color: #fff !important;
}
.bw_pricing_heading h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 85px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}
.bw_custome_container_pricing {
  width: 1600px;
  max-width: 100%;
}

.bwn_confit {
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* get one month free button style */
.bwn_get_on_month_free_button {
	font-weight: 600;
	font-size: 14px;
	line-height: 12px;
	background: rgba(44, 82, 176, 0.15);
	border: none;
	padding: 12px 20px;
	color: #2c52b0;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto 0px;
	width: max-content;
	top: -15px;
}

.bwn_get_on_month_free_button:before{
  position: absolute;
    content: '';
    top: -10px;
    left: -20px;
  background-image: url('../images/portfolio-img/get-before-img.png');
 background-repeat: no-repeat;
    width: 100%;
    height: 90px;
}

.bwn_get_on_month_free_button:after {
    position: absolute;
    content: '';
   top: 35px;
    left: 222px;
     background-image: url('../images/portfolio-img/get-after-img.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 90px;
}
.bwn_first_month_free_text {
	position: absolute;
	top: 80px;
	bottom: 0;
	left: 0;
	right: 0;
}
.bwn_first_month_free_text img {
    position: absolute;
    top: 38px;
    left: 48%;
    right: 0;
}
/* .bwn_monthly_tab_zindex{
  z-index: 999;
} */
.bwn_b2b_note_container.bw_green_djdinr_list_style {
	padding: 0 30px;
}
.bw_green_djdinr_list_style{
  	padding: 0 30px;
}
/* tabs style */
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  
} */
.bw_pricing_tabs_section {
	padding: 35px 0;
}
.bwn_shopify_plugin_sectionnn .bw_pricing_tabs_section {
	padding: 0;
}
.bwn_shopify_plugin_tab_pading_top {
  margin-top: 50px;
}
.bwn_shopify_plugin_tab_pading_top .css-1wf8b0h-MuiTabs-flexContainer {
	border-bottom: 1px solid #ccc;
	width: max-content;
	margin: 0 auto;
}
.bwn_shopify_plugin_tab_pading_top .bw_pricing_tabs_box .css-1wf8b0h-MuiTabs-flexContainer {
  width: max-content !important;
  margin: 0 auto !important;
  border: 1px solid #ccc  !important;
  border-radius: 6px;
}
.bwn_shopify_plugin_tab_pading_top .bw_ring_builder_tab {
  min-width: 450px;
  padding: 10px!important;
}
.bw_pricing_tabs_box.bw_only_month_tab .css-1wf8b0h-MuiTabs-flexContainer {
  border: 1px solid #ccc  !important;
}
.bw_pricing_tabs_box .css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}
.bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    background-color: #2c52b0 !important;
    font-family: "Poppins" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    border: 2px solid #2c52b0 !important;
    padding: 0px 20px !important;
    margin: 3px !important;
    text-transform: capitalize !important;
    box-shadow: 1px 1px 20px #1830e84d;
}

.bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #2c52b0 !important;
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
  border-radius: 6px;

   min-height: 20px;
}
.bw_ring_builder_tab{
  font-weight: 500 !important;
  font-size: 16px !important;
  color:  #B3B3B3!important;
  font-family: "Poppins" !important;
  text-transform: capitalize !important;
  border-radius: 6px;
  min-height: 20px;
}
.bw_ring_builder_tab.Mui-selected{
  color: #2c52b0 !important;
}

/* monthly tab inner box style  */
.bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 70px 0 0 0 !important;
}

.\/shopify-plugin-pricing .bwn_shopify_plugin_sectionnn .bw_monthly_tab_outer_box .css-19kzrtu  ,.\/shopify-plugin-pricing-gbp .bwn_shopify_plugin_sectionnn .bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 0 0 0 0 !important;
	margin: 60px 0 0 0;
}

.bw_monthly_tab_inner_box {
  border: 1px solid #dee2e6;
  margin-bottom: 30px;
  border-radius: 20px;
}
.bw_mont_inner_heading_box {
    background-color: #3d63c321;
    padding: 15px;
    min-height: 205px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    border-radius: 20px;
}
.bw_mont_inner_heading_box h6 {
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #000;
  margin-bottom: 0;
}
.bw_mont_inner_heading_box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-transform: capitalize;
}
.bw_mont_ul_box {
	padding: 15px 15px 0 30px;
}
.bw_mont_ul_box ul li {
    font-weight: 400;
    font-size: 15px;
    line-height: 38px;
    color: #000;
}
/* price box style */
.bw_btn_price_start:focus, .bw_btn_price_start:active, .bw_btn_price_start:hover{
  outline: 0 !important;
  box-shadow: none;
}
.bw_mont_price_box_outer {
  border: 1px solid #dee2e6;
  margin-bottom: 30px;
  border-radius: 20px;
}
.bw_mont_price_box_inner_box {
	padding: 15px;
	border: 1px solid #dee2e6;
	max-height: 330px;
	border-radius: 20px;
}
.bw_mont_price_box_inner_box h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    margin-bottom: 0;
}
.bw_mont_price_box_inner_box h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3;
    color: #2c52b0;
    margin-bottom: 0;
}
.bw_mont_price_box_inner_box span {
    font-weight: 400;
    font-size: 18px;
    line-height: 50px;
    color: #000000;
}
.bw_btn_price_start {
	background: #2c52b0 !important;
	border-radius: 6px !important;
	padding: 10px 80px !important;
	font-weight: 600 !important;
	border-color: #2c52b0 !important;
	text-transform: uppercase;
	color: #fff !important;
	font-size: 13px;
}

.bw_mont_price_box_inner_box p {
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    color: #898989;
    margin-bottom: 0;
}
.bw_mont_prcing_ul_box ul li {
    font-weight: 500;
    font-size: 15px;
    line-height: 38px;
    color: #000000;
    position: relative;
    left: 40px;
}

.bw_mont_prcing_ul_box ul li::before {
	content: "";
	position: absolute;
	width: 11px;
	height: 11px;
	border-radius: 50%;
   background: #3d63c321 url("../images/home/green-right-arrow.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px;
left: -32px;
	padding: 9px;
	top: 7px;
	bottom: 0;
}
.bw_mont_prcing_ul_box {
	padding: 15px 30px 0 30px;
}
/* .bw_pricng_tornado_bg_img {
  background: url("../images/home/pricing-tor-img.png");
  background-repeat: no-repeat;
  border-radius: 30px;
  width: 100%;
  height: 366px;
  background-size: cover;
  background-position: top;
} */
.bw_pricing_btn_white {
  background-color: #fff !important;
  color: #2c52b0 !important;
}

.bw_pricing_checkicon {
    width: 18px;
    height: 18px;
    background: #2c52b0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
}
.bw_pricing_checkicon svg {
  color: #fff;
  font-size: 14px;
}
.gap_class {
	width: max-content;
	gap: 10px;
	margin: 0 auto;
}
.bw_prcing_package {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000;
}
.bw_pricing_package_heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}
.bw_pricing_package_heading_lower {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    text-transform: capitalize;
}
.bw_pricing_montly_table.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  border-top: 0;
  width: 25%;
  padding-left: 0;
}

tr.bw_pricing_table_two {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #2c52b0;
    background-color: #3d63c321;
}
.bw_bg_light_cancel {
  background-color: #3d63c321 !important;
}
.bw_bg_light_cancel svg {
  color: #2c52b0;
}
.bw_tabletwo_head {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #000;
}
.bw_price_montly {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000;
}

/* cuircle style */
.bw_curcle_info {
  box-shadow: 0 0 20px 2px #ccc;
  display: none;
  font-size: 12px;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
}
.bw_djwd_curcle {
    border: 1px solid #949494;
    border-radius: 50%;
    color: #949494;
    cursor: pointer;
    display: block;
    float: right;
    height: 18px;
    line-height: 20px;
    text-align: center;
    width: 18px;
    position: relative;
    top: 7px;
    font-size: 10px;
}
.bw_djwd_curcle:hover ~ .bw_curcle_info {
  display: block;
  position: absolute;
  color: #414141;
  cursor: pointer;
  background-color: #fff;
}

/*--------Arrow style-------*/
.bw_djd_arrow_imp {
  position: absolute;
  top: -12%;
  left: 40%;
  right: 0;
}
.bw_djd_arrow_imp h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: #000;
  margin-left: -38px;
}

/* -------- Table tabs Mobile view Style -------- */
.bw_table_had_bgColor {
  background-color: #2c52b0;
  color: #fff;
  background: url("../images/home/pricing-tor-img.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover top;
  object-fit: cover;
}
.bw_table_had_bgColor h6 {
  font-size: 22px !important;
  line-height: 35px;
}
.bw_table_had2 {
  background-color: #3d63c321;
}

.bw_table_had_bgColor span {
  font-size: 16px;
  line-height: 30px;
}
.bw_table_column1_head {
  line-height: 30px;
  margin-bottom: 0;
  padding: 10px 0px;
  font-weight: 600;
  font-size: 25px;
}
.bw_table_had_bgColor_light {
  background-color: #3d63c321;
}
.bw_djwd_table_mobileview td {
  font-size: 15px;
  font-family: 'poppins';
  vertical-align: middle;
}
.bw_djwd_table_mobileview tbody tr td:nth-child(1) {
  text-align: left;
}
.bw_djwd_table_mobileview .css-1ujnqem-MuiTabs-root {
  min-height: 30px;
  border: 1px solid #ccc;
  width: max-content;
  margin: 0 auto;
}

.bw_djwd_table_mobileview
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #2c52b0 !important;
  font-weight: 600 !important;
  font-size: 14px;
}
.bw_djwd_table_mobileview .css-1aquho2-MuiTabs-indicator {
  background-color: #2c52b0 !important;
}
.bw_djwd_table_mobileview .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 600 !important;
}

.bw_djwd_table_mobileview .css-19kzrtu {
  padding: 0px;
}
.bw_djwd_table_mobileview tr {
  text-align: center;
}
.bw_pricing_btn_white {
  background-color: #fff !important;
  color: #2c52b0 !important;
  padding: 8px 20px !important;
}
.table thead th {
  vertical-align: middle;
  border-bottom: 2px solid #dee2e6;
}

/* Server Style start */
.bw_pricing_tabs_box .css-1q2h7u5.Mui-selected {
    color: #fff!important;
    background-color: #2c52b0!important;
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    border: 2px solid #2c52b0!important;
    padding: 0 20px!important;
    margin: 3px!important;
    text-transform: capitalize!important;
    border-radius: 4px!important;
}
.bw_pricing_tabs_box .css-ttwr4n {
  background-color: transparent !important;
}
.bw_pricing_tabs_box .css-1q2h7u5 {
    font-weight: 600!important;
    font-size: 16px!important;
    color: #2c52b0!important;
    font-family: 'Poppins'!important;
    text-transform: capitalize!important;
    padding: 0px 20px!important;
}
.bw_pricing_tabs_box.css-1l4w6pd {
  justify-content: center;
  width: 301px !important;
  margin: 0 auto !important;
  border: 2px solid rgba(36, 68, 4, 0.10196078431372549) !important;
  border-radius: 6px !important;
}
/* new */
.bw_djwd_table_mobileview .css-1q2h7u5 {
  font-weight: 600;
}

.bw_prcing_btn {
  background-color: #2c52b0 !important;
  border: 1px solid transparent !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 30px;
  min-width: 165px;
  padding: 10px 20px !important;
  text-decoration: none;
  text-transform: capitalize !important;
  transition: all 0.5s ease-in-out;
  display: block;
  text-align: center;
}
.bw_prcing_btn:hover {
  color: #2c52b0 !important;
  background-color: #fff !important;
  border-color: #2c52b0 !important;
  transition: all 0.5s ease-in-out;
}
.bw_prcing_btn_box {
  display: flex;
  justify-content: start;
}
.bw_pricing_tabs_box .MuiTabs-flexContainer.MuiTabs-centered{
	border: 1px solid #cccccc82;
  width: max-content;
  border-radius: 4px;

	margin: 0 auto !important;
}

/* Server Style end */

/* server Style Tab and Mobile View */
.bw_djwd_table_mobileview .css-orq8zk {
  border: 1px solid #ccc !important;
  width: 45% !important;
  margin: 0 auto !important;
}
.bw_djwd_table_mobileview .css-1q2h7u5.Mui-selected {
  color: #2c52b0 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.bw_djwd_table_mobileview .css-ttwr4n {
  background-color: #2c52b0 !important;
}
/* server Style Tab and Mobile View ending */
.bw_table_had_bgColor {
  background-image: url("../images/home/plans-pricing-image-nodaius.png") !important;
  background-size: cover;
}

.bwn_listing_permotion_section .bw_pricing_tabs_section{
  padding: 0;
}
.\/listing-and-promotion-of-nventory\/ .bw_monthly_tab_outer_box .css-19kzrtu {
    padding: 10px 0 0;
}

/* ---------- Responsive Style --------- */
@media (min-width: 320px) and (max-width: 359px) {
.bwn_first_month_free_text {
	top: 70px;
}
.bwn_first_month_free_text img {
    position: absolute;
    top: 35px;
    right: 0;
}
.bwn_get_on_month_free_button::after {
	top: 40px;
	left: 188px;
}
.bwn_first_month_free_text.text-center img {
	left: 84px;
}
}
@media (min-width: 360px) and (max-width: 767px) {
  .\/shopify-plugin-pricing .bwn_shopify_plugin_sectionnn .bw_monthly_tab_outer_box .css-19kzrtu, .\/shopify-plugin-pricing-gbp .bwn_shopify_plugin_sectionnn .bw_monthly_tab_outer_box .css-19kzrtu {
    padding: 30px 0 0 0 !important;
    margin: 0px 0 0 0;
  }
.bwn_first_month_free_text img {
    position: absolute;
    top: 35px;
    right: 0;
}
.bwn_get_on_month_free_button::after {
	top: 40px;
	left: 198px;
}
.bwn_first_month_free_text img {
	left: 100px;
}
}
@media (min-width: 320px) and (max-width: 767px) {
  .bwn_shopify_plugin_tab_pading_top .bw_ring_builder_tab {
    padding: 10px 10px !important;
    font-size: 14px !important;
    width: 180px;
    min-width: auto;
  }
  .bwn_shopify_swp img, .bwn_swp img{
    height: 65px;
  }
  .bw_swdddd_padding_zero .bw_pricing_tabs_section {
    padding: 20px 0 30px 0;
}
  .bw_banner_pricing.bw_banner_pricing_aed {
	background-image: url('../images//aed-img/aed-mobile-banner.png');
}
.bwn_listing_permotion_section .bw_pricing_tabs_section{
  padding: 0;
}
.bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 30px 0 0 0;
}

.\/b2c-pricing .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/b2b-pricing .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/shopify-website-pricing .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/shopify-plugin-pricing .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/plans-pricing-seo .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/plans-pricing-smo .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}
.\/email-marketing .bwn_first_month_free_text {
	margin: 25px 0px 0 0px;
}


.bwn_first_month_free_text {
  top: 0;
  position: inherit;
  margin: 25px 0px 35px 0px;
}
.bwn_listing_permotion_section .bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 0px 0 0 0;
}
.bwn_get_on_month_free_button::after {
	top: 40px;
	left: 198px;
}
.bwn_get_on_month_free_button {
	font-size: 13px;
	line-height: 15px;
	border: none;
	padding: 12px 15px;
	min-width: 200px;
}
.bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 30px 0 0 inherit;
}
.bw_table_had_bgColor span.bwn_price_count_up {
	color: #fff !important;
	font-size: 15px !important;
}
.bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	min-height: 35px;
}
  .bw_pricing_tabs_box
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 14px !important;
    padding: 0px 20px !important;
  }
  .bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 13px !important;
    padding: 0 10px !important;
  }
  .bw_mont_inner_heading_box {
    padding: 15px 20px;
    max-height: 280px;
  }
  .bw_mont_ul_box {
    padding: 20px 20px 15px;
  }
  .bw_mont_ul_box ul li {
    padding-bottom: 10px;
  }
  .bw_mont_inner_heading_box h6 {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 10px;
  }
.bw_btn_price_start {
	padding: 8px 30px !important;
	font-size: 11px;
}
  .bw_mont_price_box_inner_box h6 {
    font-size: 20px;
  }
  .bw_mont_price_box_inner_box h4 {
    font-size: 18px;
    line-height: 55px;
  }
  .bw_mont_price_box_inner_box span {
    font-size: 20px;
    line-height: 45px;
  }
  .bw_mont_price_box_inner_box p {
    font-size: 15px;
    line-height: 21px;
    margin-top: 15px;
  }
  .bw_mont_price_box_inner_box {
    padding: 20px 15px;
    max-height: 280px;
  }
  .bw_mont_prcing_ul_box ul li {
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 10px;
    left: 20px;
  }
  .bw_mont_prcing_ul_box ul li::before {
    width: 15px;
    height: 15px;
    left: -38px;
    padding: 12px;
    top: 4px;
  }
  .bw_mont_prcing_ul_box {
    padding: 20px 30px;
  }
  .bw_djd_arrow_imp h6 {
    font-size: 14px;
    line-height: 6px;
    margin-left: -21px;
  }
  .bw_djd_arrow_imp {
    top: -7%;
  }
  .bw_djd_arrow_imp img {
    height: 45px;
  }
  .bw_pricing_package_heading {
    font-size: 12px;
    line-height: 20px;
  }
  .bw_pricing_package_heading_lower {
    font-size: 11px;
    line-height: 20px;
  }
  .bw_prcing_package {
    font-size: 11px;
    line-height: 15px;
  }
  .bw_pricing_checkicon svg {
    font-size: 15px;
  }
  .bw_pricing_checkicon {
    width: 18px;
    height: 18px;
  }
  .bw_pricing_montly_table .table td,
  .table th {
    padding: 10px 8px;
  }
  .bw_pricing_table_two tr {
    font-size: 13px;
    line-height: 20px;
  }
  .bw_pricing_table_second.table td,
  .table th {
    padding: 10px 8px;
  }
  .bw_tabletwo_head {
    font-size: 11px;
    line-height: 20px;
  }
  .bw_price_montly {
    font-size: 11px;
    line-height: 20px;
  }
  .bw_pricing_montly_table {
    margin-top: 30px;
  }
  tr.bw_pricing_table_two {
    font-size: 12px;
  }
  .bw_djwd_table_mobileview .css-1ujnqem-MuiTabs-root {
    width: 100%;
  }
  .bw_table_had_bgColor h6 {
    font-size: 16px !important;
    line-height: 20px;
    text-transform: uppercase;
  }
  .bw_table_had_bgColor h4 {
    font-size: 15px !important;
  }

.bw_table_column1_head {
	line-height: 1.3;
	font-weight: 600;
	font-size: 15px;
}
  .bw_djwd_table_mobileview .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 12px 10px;
    font-size: 12px;
     font-family: 'poppins';
  }
  .bw_djwd_table_mobileview .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
	font-size: 12px;
 
}
  .bw_prcing_btn_box {
    display: block !important;
    justify-content: center !important;
    max-width: 300px;
  }
  .bw_prcing_btn {
    padding: 8px 8px !important;
    font-size: 15px !important;
  }
  .bw_table_had_bgColor span {
    font-size: 12px;
    line-height: 25px;
  }

  /* ------ server style ---- */

  .bw_pricing_tabs_box .css-1l4w6pd {
    width: 204px !important;
    margin: 0 auto;
    border: 1px solid #ccc !important;
  }
  .bw_pricing_tabs_box
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 14px !important;
    padding: 0px 20px !important;
  }
  .bw_pricing_tabs_box .css-1q2h7u5.Mui-selected {
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px;
    border-radius: 4px !important;
  }
  .bw_pricing_tabs_box .css-1q2h7u5 {
    justify-content: center !important; 
    font-size: 14px !important;
    padding: 0 10px !important;
        border-radius: 4px !important;
  }
  .bw_pricing_tabs_box .css-1q2h7u5.Mui-selected {
    font-size: 14px !important;
    padding: 0px 20px !important;
  }
  .bw_pricing_tabs_box .bw_djwd_table_mobileview .css-orq8zk {
    width: 100% !important;
  }
  .bw_djwd_table_mobileview .css-orq8zk {
    width: 100% !important;
  }
  .bw_djwd_table_mobileview .css-1q2h7u5 {
    font-weight: 600 !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_swdddd_padding_zero .bw_pricing_tabs_section {
    padding:0px 0 30px 0;
}
  .bwn_get_on_month_free_button {
    top: -12px !important;
}
/* .bwn_get_on_month_free_button {
	font-size: 14px;
	padding: 12px 30px;
} */
.bw_djwd_table_mobileview{
    margin-top: 20px;
}
  .bw_pricing_btn_white {
	padding: 10px 70px !important;
}
  .bw_pricing_tabs_box .MuiTabs-flexContainer.MuiTabs-centered {
	margin: 0 auto 30px !important;
}
  .bw_pricing_package_heading {
    font-size: 20px;
  }
  .bw_pricing_package_heading_lower {
    font-size: 20px;
    line-height: 30px;
  }
  .bw_prcing_package {
    font-size: 16px;
    line-height: 30px;
  }
  .bw_pricing_table_two tr {
    font-size: 18px;
  }
  .bw_tabletwo_head {
    font-size: 16px;
  }
  .bw_price_montly {
    font-size: 16px;
  }
  .bw_mont_inner_heading_box h6 {
    font-size: 35px;
  }
  .bw_mont_inner_heading_box p {
    font-size: 17px;
  }
  .bw_mont_price_box_inner_box h6 {
    font-size: 18px;
  }
  .bw_mont_price_box_inner_box h4 {
    font-size: 30px;
  }
  .bw_mont_price_box_inner_box p {
    font-size: 17px;
  }
  .bw_mont_ul_box ul li {
    font-size: 17px;
    line-height: 30px;
  }
  .bw_pricing_checkicon {
    width: 25px;
    height: 25px;
  }
  .bw_pricing_checkicon svg {
    font-size: 18px;
  }
  .bw_mont_prcing_ul_box ul li::before {
    width: 25px !important;
    height: 25px !important;
    background-size: 16px;
  }
  .bw_pricing_tabs_box
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 16px !important;
    padding: 9px 25px !important;
  }

  .bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 0 20px !important;
    font-size: 16px !important;
  }
.bw_monthly_tab_outer_box .css-19kzrtu {
	padding: 10px 0 0 0;
}
.bw_pricing_tabs_section {
	padding: 30px 0 30px;
}
  .bw_djd_arrow_imp h6 {
    line-height: 5px;
  }
  .bw_djd_arrow_imp {
    top: -9%;
  }
  .bw_mont_price_box_outer {
    margin-bottom: 100px;
  }
  .bw_monthly_tab_inner_box {
    min-height: 87%;
  }
  .bw_mont_price_box_outer {
    min-height: 83%;
  }
  tr.bw_pricing_table_two {
    font-size: 16px;
  }
  /* server Style */
  .bw_pricing_tabs_box .bw_djwd_table_mobileview .css-1q2h7u5.Mui-selected {
    color: #2c52b0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .bw_pricing_tabs_box .bw_djwd_table_mobileview .css-1q2h7u5 {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .bw_djwd_table_mobileview .css-orq8zk {
    width: 50% !important;
  }
  .bw_mont_inner_heading_box {
    padding: 35px 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
}

@media (min-width: 1150px) and (max-width: 1360px) {
  .bw_pricing_montly_table tbody tr td {
	font-size: 12px !important;
	line-height: 15px;
}
  .bw_mont_price_box_inner_box h4 {
	font-size: 16px;
}
  .bw_pricing_tabs_box
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 13px !important;
    padding: 5px 20px !important;
  }
  span.bwn_price_count_up {
    font-size: 16px !important;
}

  .bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 13px !important;
    padding: 0 20px !important;
  }
.bw_mont_inner_heading_box h6 {
    font-size: 20px;
    line-height: 1.3;
}
  .bw_mont_inner_heading_box p {
    font-size: 13px;
    line-height: 23px;
  }
 .bw_mont_inner_heading_box {
    min-height: 160px;
}

.bw_mont_price_box_inner_box h6 {
    font-size: 14px;
    line-height: 1.3;
}
.bw_mont_price_box_inner_box h4 {
    font-size: 16px;
    line-height: 1.3;
}
.bw_mont_price_box_inner_box span {
    font-size: 12px;
    line-height: 35px;
}

.bw_btn_price_start {
	padding: 3px 20px !important;
	font-size: 11px;
}
  .bw_djd_arrow_imp h6 {
    font-size: 18px;
  }
  .bw_djd_arrow_imp {
    top: -16%;
  }
  /* .bw_pricing_tabs_section {
    padding: 35px 0;
  } */
  .bw_all_banner {
    padding: 110px 0 35px;
  }
  .bw_mont_ul_box ul li {
    font-size: 12px;
    line-height: 30px;
  }
.bw_mont_prcing_ul_box ul li {
    font-size: 12px;
    line-height: 30px;
    /* padding-bottom: 14px; */
}
.bw_mont_prcing_ul_box ul li::before {
	width: 9px;
	height: 9px;
	background-size: 9px;
	left: -35px;
	padding: 8px;
	top: 4px;
	bottom: 0;
}

.bw_djwd_curcle {
    height: 14px;
    line-height: 17px;
    width: 14px;
    font-size: 12px;
}
  .bw_pricing_package_heading {
    font-size: 14px;
    line-height: 20px;
  }
  .bw_pricing_package_heading_lower {
    font-size: 14px;
    line-height: 20px;
  }
  .bw_prcing_package {
    font-size: 12px;
    line-height: 20px;
  }
  .bw_pricing_checkicon svg {
    font-size: 12px;
  }
.bw_pricing_checkicon {
	width: 16px;
	height: 16px;
}
  .bw_pricing_table_two tr {
    font-size: 16px;
  }
  .bw_tabletwo_head {
    font-size: 12px;
    line-height: 20px;
  }
  .bw_price_montly {
    font-size: 12px;
    line-height: 20px;
  }


  tr.bw_pricing_table_two {
    font-size: 12px;
    line-height: 20px;
  }
  .bw_prcing_btn {
    font-size: 12px !important;
    line-height: 30px;
    padding: 2px 15px !important;
  }
  .bw_pricing_montly_table tbody tr td {
    text-align: center;
    font-size: 12px;
  }
  .bw_table_had_bgColor h6 {
    font-size: 18px !important;
    line-height: 25px;
  }
  .bw_mont_price_box_inner_box p {
    font-size: 11px;
    line-height: 30px;
    margin-bottom: 0;
}
.bw_prcing_btn {
	font-size: 14px !important;
	padding: 4px 20px !important;
    line-height: 25px;
}
}
@media(min-width:1440px) and (max-width:1650px){
    .bw_mont_price_box_inner_box h4 {
	font-size: 16px;
}
  .bw_prcing_btn {
	font-size: 14px !important;
	padding: 4px 20px !important;
  line-height: 25px;
}
.bw_mont_price_box_inner_box h6 {
	font-size: 14px;
	line-height: 1.3;
}
span.bwn_price_count_up {
	font-size: 16px !important;
}
.bw_mont_inner_heading_box h6 {
	font-size: 18px;
	line-height: 1.3;
}
.bw_mont_price_box_inner_box p {
	font-size: 11px;
	line-height: 30px;
	margin-bottom: 0;
}
.bw_btn_price_start {
	padding: 8px 70px !important;
	font-size: 11px;
}
.bw_mont_price_box_inner_box span {
	font-size: 12px;
	line-height: 35px;
}
.bw_mont_prcing_ul_box ul li {
	font-size: 12px;
	line-height: 30px;
}
.bw_mont_prcing_ul_box ul li::before {
	width: 9px;
	height: 9px;
	background-size: 9px;
	left: -35px;
	padding: 8px;
	top: 5px;
	bottom: 0;
}
.bw_pricing_checkicon {
	width: 16px;
	height: 16px;
}
.bw_mont_ul_box ul li {
	font-size: 12px;
	line-height: 30px;
}
.bw_djwd_curcle {
	height: 14px;
	line-height: 14px;
	width: 14px;
	font-size: 9px;
}
.bw_mont_inner_heading_box {
	min-height: 165px;
}
.bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
	font-size: 13px !important;
	padding: 5px 20px !important;
}
.bw_pricing_tabs_box .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	font-size: 13px !important;
	padding: 0 20px !important;
}
.bw_pricing_package_heading {
	font-size: 14px;
	line-height: 20px;
}
.bw_pricing_package_heading_lower {
	font-size: 14px;
	line-height: 20px;
}
.bw_prcing_package {
	font-size: 12px;
	line-height: 20px;
}
.bw_pricing_checkicon svg {
	font-size: 12px;
}
.bw_pricing_montly_table tbody tr td {
	text-align: center;
	font-size: 12px;
}
.bw_pricing_montly_table tbody tr td {
	text-align: center;
	font-size: 12px;
}
}
@media(min-width:1200px) and (max-width:1440px){
.bwn_first_month_free_text img {
	left: 46%;
}
.bwn_first_month_free_text {
    top: 77px;
}
}

@media (max-width: 1024px) {
  .bw_pricing_tab_desktopview {
    display: none;
  }
}
@media (min-width: 1025px) {
  .bw_djwd_table_mobileview {
    display: none;
  }
}


