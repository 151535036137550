.bw_blogs_container {
  padding: 50px 0;
}
.bw_recent_blog_heading h2 {
  padding: 0 0 20px 0;
  font-size: 35px;
  color: #000;
  line-height: 30px;
}
.bw_recent_blog1 img {
  margin-bottom: 25px;
  width: 100%;
  /* height: 246px; */
}
.bw_recent_blog1 img {
  margin-bottom: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
h6.bw_blog_post_date {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
}
.bw_recent_blog1 p {
  margin-top: 0;
  color: #00000080;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: auto;
  /* -webkit-line-clamp: 2; */
}
.bw_post_date {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #000;
    position: relative;
    left: 30px;
}
.bw_post_date::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  top: 9px;
  left: -20px;
  right: 0;
}
.bw_recent_blog1 h4 {
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    color: #000;
}
.bw_blog1_content_box h1 {
	color: #000;
	font-size: 28px;
	font-weight: 600;
	line-height: 38px;
	text-transform: uppercase;
	letter-spacing: .5px;
}
.bw_blog_btn {
  border-radius: 28.5px;
  background-color: #d9d9d900;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 8px 40px;
  color: #000;
  border: 2px solid #d9d9d933 !important;
  margin-right: 20px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.bw_blog_btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d9d9d933;
  z-index: -2;
}
.bw_blog_btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #ccc;
  transition: all 0.3s;
  color: #fff;
  z-index: -1;
}
.bw_blog_btn:hover {
  color: #000;
  background-color: #ccc;
  border: 2px solid #ccc;
}
.bw_blog_btn:hover:before {
  width: 100%;
}
.bw_blog_btn.btn-primary:focus {
  color: #000;
  background-color: #ccc !important;
}
.bw_blog_btn.btn-primary:hover,
.bw_blog_btn.btn-primary:focus,
.bw_blog_btn.btn-primary:active {
  box-shadow: none !important;
  outline: 0 !important;
}

.bw_blog_post_inner_box img {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}
.bw_bpost_box_inner h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    color: #000;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
}
.bw_bpost_box_inner p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #00000080;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: auto;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.bw_blog_btn_fonts {
  font-size: 18px;
}
.bw_all_blog_post_img img {
  margin-bottom: 20px;
  width: 100%;
  /* height: 360px; */
  object-fit: cover;
}
.bw_all_blog_post_img {
  margin-bottom: 40px;
}
.bw_all_blogpost h2 {
  font-weight: 600;
  font-size: 35px;
  line-height: 38px;
  color: #000;
  padding: 20px 0 20px 0;
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_blog_btn {
    font-size: 14px;
    line-height: 16px;
    padding: 8px 15px;
  }
  .bw_recent_blog_heading h2 {
    font-size: 22px;
  }
  .bw_all_blogpost h2 {
    font-size: 30px;
  }
  h6.bw_blog_post_date {
    font-size: 16px;
  }
  .bw_post_date {
    font-size: 16px;
  }
  .bw_post_date::before {
    width: 8px;
    height: 8px;
    top: 7px;
  }
  .bw_bpost_box_inner h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .bw_bpost_box_inner p {
    font-size: 15px;
  }
  .bw_recent_blog1 h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .bw_recent_blog1 p {
    font-size: 16px;
    line-height: 30px;
  }
  .bw_all_blog_post_img img {
    height: unset;
  }
  .bw_all_banner.bw_blog1_banner_padding {
    width: 100%;
  }
  .bw_blog1_content_box h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .bw_blog1_content_box p {
    font-size: 14px;
    line-height: 25px;
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_post_date {
    font-size: 16px;
  }
  h6.bw_blog_post_date {
    font-size: 16px;
  }
  .bw_recent_blog1 h4 {
    font-size: 20px;
  }
  .bw_recent_blog1 p {
    font-size: 16px;
    line-height: 30px;
  }
  .bw_blog_btn {
    font-size: 16px;
    padding: 5px 30px;
  }
  .bw_bpost_box_inner h4 {
    font-size: 20px;
  }
  .bw_bpost_box_inner p {
    font-size: 16px;
    line-height: 26px;
  }
  /* .bw_blog_post_inner_box img {
    height: 240px;
  } */
  .bw_all_blogpost h2 {
    padding: 30px 0 20px 15px;
    font-size: 28px;
  }
  .bw_all_blog_post_img img {
    height: auto;
  }
  .bw_recent_blog_heading h2 {
    padding: 50px 0 20px 15px;
    font-size: 28px;
  }
  .bw_blog1_content_box h1 {
    font-size: 23px;
    line-height: 30px;
    letter-spacing: .5px;
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .bw_bpost_box_inner h4 {
    font-size: 16px !important;
  }
  .bw_bpost_box_inner p {
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .bw_blog_btn {
    font-size: 16px !important;
    padding: 7px 15px !important;
  }
  h6.bw_blog_post_date {
    font-size: 14px !important;
  }
  .bw_post_date {
    font-size: 14px !important;
  }
  .bw_post_date::before {
    width: 8px !important;
    height: 8px !important;
    top: 6px !important;
  }
}

@media (min-width: 1160px) and (max-width: 1360px) {
  .bw_banner_sub_heading {
    font-size: 14px;
    line-height: 25px;
    width: 700px;
  }
  .bw_banner_heading {
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 15px;
  }
  /* .bw_all_blog_post_img img {
    height: 260px;
  } */
  .bw_recent_blog1 img {
    height: auto;
    object-fit: cover;
  }
  .bw_bpost_box_inner h4 {
    font-size: 16px;
    line-height: 30px;
  }
  h6.bw_blog_post_date {
    font-size: 14px;
    line-height: 30px;
  }
  .bw_post_date {
    font-size: 14px;
    line-height: 30px;
  }
  .bw_bpost_box_inner p {
    font-size: 12px;
    line-height: 23px;
  }
  .bw_recent_blog1 h4 {
    font-size: 18px;
    line-height: 25px;
  }
  .bw_recent_blog1 p {
    padding-bottom: 10px;
    font-size: 15px;
    line-height: 30px;
  }
  .bw_blog_btn {
    font-size: 13px;
    line-height: 15px;
    padding: 7px 20px;
    margin-right: 18px;
  }
  .bw_all_blogpost h2 {
    font-size: 25px;
    line-height: 30px;
    padding: 10px 0 10px 0;
  }
  .bw_recent_blog_heading h2 {
    padding: 20px 0 10px 0;
    font-size: 25px;
  }
  .bw_car_search_box .form-control {
    padding: 25px 20px 25px 50px !important;
  }
  .bw_post_date::before {
    top: 5px;
  }
  .bw_post_date::before {
    width: 8px;
    height: 8px;
  }
  .bw_all_blog_post_img img {
    object-fit: cover;
    min-height: 284px;
  }
}

.datalimit {
  height: 60px;
}