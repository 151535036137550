html {
  scroll-behavior: smooth;
}

.bw-branding-sec.bw_branding_text_mobile {
  display: none;
}

.bw_cr_gems_section .bw_all_banner {
  padding: 100px 0 20px;
}

/* gems style */
.video_gems {
  width: 100% !important;
  height: 750px !important;
  object-fit: cover !important;
  margin-top: 20px !important;
}

.video_gems video {
  width: 100%;
  height: 750px;
  object-fit: cover;
}

body .apiSecUnder.cr_gems_sectionn {
  margin-top: 30px;
}

/* -------------home banner iframe style start ---------*/
.bw_home_banner_iframe_box {
  position: absolute;
  top: 15px;
  left: 11.5%;
  width: 77.3%;
  border-radius: 7px;
  overflow: hidden;
}

.bw_home_banner_iframe_box iframe {
  overflow-x: hidden;
  width: 100%;
  height: 447px;
  overflow-y: scroll;
}

.bw_home_banner_iframe_box iframe::-webkit-scrollbar {
  display: none !important;
}

/* -------------home banner iframe style end ---------*/

/***************** home banner section css start *********************/

.bw_custome_container_banner {
  /* max-width: 1520px !important; */
  max-width: 1620px !important;
  width: 100%;
}

.bw_banner_img_main img {
  width: 100%;
  height: auto;
}

.bw_banner_section {
  /* background-image: url("../images/home/banner_bg1.png"); */
  background-image: url("../images/home/blue-home-img.webp");
  background-size: cover;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 99;
  height: 100vh;
  align-items: center;
  display: flex;
}

/* .bw_banner_content {
    padding: 14rem 0;
} */

.bw_banner_content h1 {
  color: #fff;
}

.bw_scroll_sec {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.bw_scroll_sec p {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 6px;
}

.bw_scroll-down {
  border: 2px solid #fff;
  border-radius: 20px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  width: 30px;
  cursor: pointer;
}

.bw_scroll-down::before {
  animation: scrollDownAnimation 2s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
}

@-moz-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 0;
  }
}

@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 0;
  }
}

.bwn_view_all_mobile_btn {
  margin-top: 90px !important;
}

.bw_banner_content_inner h1 {
  font-size: 55px;
  font-weight: 300;
  padding-right: 50px;
  margin-bottom: 20px;
}

.bw_banner_content_inner p {
  color: #fff;
  width: 80%;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 18px;
}

.bw_banner_content_inner h1 strong {
  font-weight: 600;
}

.bw_banner_content_inner h1 strong span {
  position: relative;
  z-index: 9;
}

.bw_banner_content_inner h1 strong span::before {
  content: "";
  position: absolute;
  bottom: 16px;
  background-color: #cf1c6533;
  width: 100%;
  height: 10px;
  z-index: -1;
  display: none;
}

/***************** home banner section css end *********************/
/***************** home about company section css start ************/
.bw_custome_container.container {
  max-width: 1420px;
}

.bw_brand_slider_item img {
  width: 100%;
  padding: 0px 15px;
  outline: none;
}

.bw_brand_slider_item:active,
.bw_brand_slider_item:focus,
.bw_brand_slider_item:hover {
  outline: 0 !important;
  box-shadow: none !important;
}

.bw_brand_slider_item {
  outline: none !important;
}

.bw_about_comany_title_text p {
  font-weight: 400;
  font-size: 18px;
  color: rgb(0 0 0 / 55%);
  margin-top: 20px;
  margin-bottom: 25px;
  text-align: left;
  width: 85%;
}

section.bw_about_comany_section {
  padding: 70px 0px;
  background: #3d63c30d;
}

.bw_about_comany_title_text h6 {
  font-weight: 300;
  font-size: 25px;
}

.bw_about_comany_title_text h2 {
  font-weight: 700;
  font-size: 55px;
  line-height: 1;
  color: #2C52B0;
}

.bw_about_comany_logo_img {
  margin-top: 30px;
}

/* .bw_about_comany_logo_img img {
    margin-right: 25px;
} */
.bw_about_comany_logo_img ul {
  list-style: none;
  display: flex;
  padding: 0;
}

.bw_about_comany_logo_img ul li {
  margin-right: 22px;
}

.bw_about_box_main {
  padding: 25px 30px;
  background: #ffffff;
  box-shadow: 0px 0px 54px rgb(0 0 0 / 3%);
  border-radius: 14px;
  min-height: 380px;
}

.bw_about_box_main p {
  font-weight: 400;
  font-size: 15px;
  color: #899097;
  text-align: justify;
}

.bw_about_box_main h2 {
  font-weight: 600;
  font-size: 49px;
}

.bw_about_box_main h6 {
  font-weight: 500;
  font-size: 20px;
}

.bw_branding_text_agency_icn_div a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.bw_branding_text_agency_icn_div a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.slick-slide {
  outline: 0 !important;
  box-shadow: none !important;
}

/***************** home page about company section css end *********************/

/***************** home page New branding section css start *********************/
.bw_new_branding_mobile {
  display: none;
}

section.bw_new_branding_section {
  background: #F5F7FC;
  padding: 70px 0px 50px;
}

.bw_new_branding_main_title h2 {
  font-weight: 700;
  font-size: 55px;
  color: #3D63C3;
  margin-top: 5px;
  position: relative;
  text-align: center;
  padding-bottom: 40px;
}

.bw_new_branding_main_title p {
  font-weight: 400;
  font-size: 16px;
  /* color: #3D63C3; */
  margin-top: 15px;
}

.bw_new_branding_main_title_dots p {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
  color: #000;
  text-transform: capitalize;
}

.bw_new_branding_main_title_dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bw_new_branding_main_title_dots span {
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

.bw_new_branding_box h2 {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  margin: 25px 0px 12px 0px;
}

.bw_new_branding_box h3 {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  margin: 25px 0px 12px 0px;
}

.bw_new_branding_box {
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.bw_new_branding_box p {
  color: #000;
  opacity: 0.5;
  font-weight: 300;
  font-size: 16px;
  padding: 0px 30px;
  line-height: 23px;
}

.bw_new_branding_main_title {
  margin-bottom: 50px;
  padding-left: 30px;
}

/* .bw_new_branding_main_title h2:before {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 3px;
    background: white;
    border-radius: 5px;
} */
.bw_new_branding_box_icon img {
  width: 70px;
}

/***************** home page  branding section css end *********************/

/************************branding text mobile section css *****************/
.bw-branding-sec {
  background: #f9fafd;
  padding: 250px 0 240px;
  text-align: center;
}

.branding-box h1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 120px;
  line-height: 81px;
  display: block;
  margin-bottom: 70px;
}

.branding-box h1 span {
  display: inline-block;
  line-height: 1.2;
}

.were-txt,
.and {
  color: #54555b;
}

.branding-box h1 .were-img img {
  height: 112px;
  position: relative;
  top: -10px;
}

.branding-box h1 .were-img {
  padding: 0px 25px;
}

.branding-box h1 .agency {
  padding-right: 70px;
  position: relative;
}

.agency-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #2ac1b7;
  border-radius: 100%;
  right: 0px;
  top: -20px;
  box-shadow: 0px 4px 24px rgba(25, 167, 86, 0.25);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.agency-circle:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.agency-circle svg {
  font-size: 40px;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 30%;
  color: #fff;
}

.branding-box .and-img img {
  height: 112px;
  position: relative;
  top: -12px;
}

.branding-box .and-img {
  padding-left: 25px;
}

.branding-box .studio-img img {
  position: relative;
  top: -10px;
  height: 112px;
}

.branding-box .studio-img {
  padding: 0 25px;
}

.branding-box .studio {
  position: relative;
  padding-right: 50px;
}

.branding-box span.were-img img {
  border-radius: 50%;
  overflow: hidden;
}

/************************branding text mobile section css end *****************/

/*********** home page branding agency text section css start ***********/
.bw_branding_text_div1 {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

section.bw_branding_text_section {
  background: #f9fafd;
  height: 950px;
  display: flex;
  align-items: center;
}

.bw_branding_text_row1_img1 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0px 30px;
}

.bw_branding_text_font_style h2 {
  font-weight: 600;
  font-size: 110px;
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
}

.bw_branding_text_row1_img1 img {
  width: 100%;
}

Portfolio .bw_branding_text_we_are_text {
  color: #54555b;
}

.bw_branding_text_branding_text h2 {
  position: relative;
}

/* .bw_branding_text_branding_text h2:before {
    content: "";
    position: absolute;
    bottom: 14px;
    left: 5px;
    width: 99%;
    height: 5px;
    background: #020202;
} */

.bw_branding_text_div2 {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  padding-right: 60px;
  position: relative;
  top: -6px;
}

.bw_branding_text_agency_img_circle {
  width: 85px;
  height: 85px;
  overflow: hidden;
  margin: 0px 15px;
  background: #2ac1b7;
  box-shadow: 0px 4px 24px rgb(25 167 86 / 25%);
  border-radius: 50%;
  position: absolute;
  top: -9px;
  right: -90px;
  transform: translate3d(0.004%, -0.004%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
  transition: all 0.5s;
}

.bw_branding_text_agency_img_circle:hover {
  transform: translate3d(0.00744%, -1.7072%, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
}

.bw_branding_text_agency_text {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 90px;
}

.bw_branding_text_agency_img_circle img {
  width: fit-content;
  margin: auto;
}

.bw_branding_text_agency_img2 {
  width: 190px;
  height: 120px;
  margin-left: 20px;
}

.bw_branding_text_agency_img2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bw_branding_text_agency_and h2 {
  color: #54555b;
}

.bw_branding_text_agency_and {
  display: flex;
  align-items: center;
  margin-left: 15px;
  top: -10px;
}

.bw_branding_text_agency_icn_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.bw_branding_text_div3 {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  padding-right: 20px;
}

.bw_branding_text_design h2 {
  color: #000000;
}

.bw_branding_text_design_img1 {
  width: 110px;
  height: 110px;
  margin: 0px 30px;
}

.bw_branding_text_design {
  display: flex;
  align-items: center;
}

.bw_branding_text_design_img1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bw_branding_text_studio_img_circle img {
  width: fit-content;
  margin: auto;
}

.bw_branding_text_studio_text {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 90px;
}

.bw_branding_text_studio_img_circle {
  width: 85px;
  height: 85px;
  overflow: hidden;
  margin: 0px 15px;
  background: #2ac1b7;
  box-shadow: 0px 4px 24px rgb(25 167 86 / 25%);
  border-radius: 50%;
  position: absolute;
  right: -75px;
  top: -5px;
  transform: translate3d(0.004%, -0.004%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
  transition: all 0.5s;
}

.bw_branding_text_studio_img_circle:hover {
  transform: translate3d(0.00744%, -1.7072%, 0px) scale3d(1.2, 1.2, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
}

.bw_branding_text_btn_main {
  text-align: center;
  margin-top: 50px;
}

.bw_branding_text_btn_img {
  margin-left: 35px;
  position: relative;
  top: -3px;
}

.bw_animate_style_d123 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fec401;
}

.bw_animate_style_d123 img {
  border-radius: 50%;
}

.bw_branding_text_agency_img22 {
  overflow: hidden;
}

/*********** home page branding agency text section css end ***********/

/*********** home page brand slider section css start***********/
section.bw_brand_slider_section {
  /* height: 850px; */
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 70px 0;
}

.bw_brand_slider_left_text_main h2 {
  font-weight: 700;
  font-size: 55px;
  color: #3D63C3;
  text-align: center;
}

.bw_brand_slider_left_text_main p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: 25px;
  text-align: center;
  width: 80%;
  margin: 25px auto 0;
}

.bw_brand_slider_right_slide_main {
  margin-top: 20px;
  position: relative;
}

/* .btn_view_more {
  position: absolute;
  bottom: -69%;
  left: 0;
  right: 0;
} */
/* .bw_brand_slider_right_slide_main:hover .btn_view_more {
  display: block;
} */
.bw_brand_slider_item {
  margin-bottom: 0px;
  padding: 0px 15px;
}

.bw_brand_slider_right_slide_main button.slick-arrow.slick-prev {
  bottom: -20px;
  top: inherit;
  left: -60px;
  right: 0;
  margin: auto;
}

.bw_brand_slider_right_slide_main button.slick-arrow.slick-next {
  top: inherit;
  bottom: -20px;
  left: 0;
  right: -20px;
  margin: auto;
}

.bw_brand_slider_right_slide_main .slick-prev:before,
.slick-next:before {
  color: black !important;
}

.bw_brand_slider_right_slide_main button {
  bottom: -40px !important;
}

.bw_brand_slider_right_slide_main .slick-prev,
.slick-next {
  width: 60px !important;
  height: 60px !important;
}

.bw_brand_slider_right_slide_main .slick-prev {
  top: inherit;
  bottom: -140px;
  left: -80px;
  right: 0;
  margin: auto;
}

.bw_brand_slider_right_slide_main .slick-next {
  top: inherit;
  bottom: -140px;
  left: 0;
  right: -70px;
  margin: auto;
}

.bw_brand_slider_right_slide_main img.slick-arrow.slick-prev.slick-disabled {
  opacity: 0.4;
}

.bw_brand_slider_right_slide_main img.slick-arrow.slick-next.slick-disabled {
  opacity: 0.4;
}

/*********** home page brand slider section css end ***********/

/*********** home page video section css start ***********/
.bw_innovation_video_section {
  background-image: url("../images/home/video-bg-img.webp");
  height: 950px !important;
  display: flex;
  align-items: center;
}

.bw_innovation_video_text p {
  font-weight: 400;
  font-size: 22px;
  color: #ffff;
  margin: 25px 0px 25px 0px;
}

.bw_innovation_video_text h2 {
  font-weight: 700;
  font-size: 74px;
  line-height: 81px;
  color: #ffff;
}

.bw_innovation_video_btn {
  text-align: center;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.bw_innovation_video_btn button img {
  display: block;
  position: relative;
}

.bw_innovation_video_btn button {
  position: relative !important;
  border: none !important;
}

.bw_innovation_video_btn button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 55%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}

.bw_innovation_video_btn button:after {
  content: "";
  position: absolute;
  z-index: -4;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 200ms;
}

/*********** home page video section css end ***********/
/*********** home clent logo css start ***********/
/* .bw_client_logo_img_ul {
  width: 600px;
  height: 50px;
  padding-left: 0;
  margin: 0 auto;
} */
/* .bw_client_logo_imgs ul li {
  display: inline-block;
  width: 20%;
  text-align: center;
}
.bw_client_logo_imgs ul li img {
  
  height: auto;
  margin: 15px;
} */
/*********** home clent logo css end ***********/

/*********** home page project slider section css start ***********/

.bw_project_slider_section {
  overflow: hidden;
  background-image: url("../images/home/recent-project.webp");
  /* height: 850px; */
  display: flex;
  align-items: center;
  padding: 70px 0;
}

.bw_project_slider_item img {
  width: 100%;
  height: auto;
}

.bw_project_slider_text h2 {
  font-weight: 700;
  font-size: 55px;
  color: #3D63C3;
}

.bw_project_slider_text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #1f1f1f;
  padding-bottom: 20px;
  text-align: left;
}

.bw_project_slider_main.bw_brand_slider_right_slide_main .slick-prev {
  top: inherit;
  bottom: 5px;
  left: -470px;
  right: 0;
  margin: initial;
  width: 60px !important;
  height: 60px !important;
}

.bw_project_slider_main.bw_brand_slider_right_slide_main .slick-next {
  top: inherit;
  bottom: 5px;
  right: inherit;
  margin: inherit;
  left: -395px;
  width: 60px !important;
  height: 60px !important;
}

.bw_project_slider_item_text_paragraph p {
  font-weight: 400;
  font-size: 18px;
  color: #0000008f;
  line-height: 30px;
}

.bw_project_slider_item_text_paragraph {
  width: 100%;
}

.bw_project_slider_item_text_title {
  /* width: 26%; */
  margin-right: 10px;
}

.bw_project_slider_item_text {
  /* display: flex; */
  margin-top: 20px;
  padding: 0px 15px;
  width: 100%;
}

.bw_project_slider_item_text_title h4 {
  font-weight: 700;
  font-size: 23px;
  position: relative;
  width: fit-content;
  z-index: 9;
  color: #000000db;
}

.bw_project_slider_item_text_title h4:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 10px;
  background: #000000;
  opacity: 0.07;
  z-index: -1;
}

.bw_product_img_outer_box {
  background: #2f30310f;
  padding: 40px;
  border-radius: 11px;
}

.bw_brand_slider_right_slide_main .slick-slide.slick-active.slick-current .bw_product_img_outer_box {
  background-color: #3d63c321;
}

/* .bw_brand_slider_right_slide_main.bw_project_slider_main .slick-prev,.slick-next {
    width: 60px !important;
    height: 60px !important;
} */
/*********** home page project slider section css end ***********/

/*********** home page google review slider section css start ***********/
.bw_brand_slider_right_slide_main.google_review_slider_main .slick-prev {
  top: inherit;
  bottom: -120px;
  left: -80px;
  right: 0;
  margin: auto;
}

.bw_brand_slider_right_slide_main.google_review_slider_main .slick-next {
  bottom: -120px;
}

.bw_google_review_title h2 {
  font-weight: 700;
  font-size: 55px;
  text-align: center;
  color: #3D63C3;
  margin-bottom: 40px;
}

section.bw_google_review_section {
  background: #F5F7FC;
  display: flex;
  align-items: center;
  padding: 70px 0 160px 0;
}

.bw_google_review_title {
  margin-bottom: 20px;
}

.google_review_slider_box p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
}

.google_review_slider_box h4 {
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 50px;
}

.bwn_review_text {
  font-size: 16px;
}

.google_review_slider_box_rating span {
  font-size: 40px;
}

.google_review_slider_box {
  text-align: center;
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
  color: #000;
  min-height: 360px;
}

.google_review_slider_box_rating {
  margin-bottom: 5px;
}

.google_review_slider_box p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}

.bwn_review_nevigate_link {
  text-align: center;
  background: #fff;
  border-radius: 15px;
  color: #000;
  font-size: 17px;
  font-weight: 500 !important;
}

.bwn_review_nevigate_link:hover {
  color: #3D63C3;
}

.google_review_slider_box img {
  margin: auto;
  width: 90px;
  cursor: pointer;
}

.google_review_slider_box .bwn_review_user_image {
  width: 60px;
  margin-bottom: 15px;
  cursor: pointer;
}

.bw_brand_slider_right_slide_main.google_review_slider_main .slick-prev {
  width: 65px !important;
  height: 65px !important;
}

.bw_brand_slider_right_slide_main.google_review_slider_main .slick-next {
  width: 65px !important;
  height: 65px !important;
  right: -71px;
}

/*********** home page google review slider section css end ***********/

/*********** home page news section css start ***********/
section.bw_news_section {
  padding: 140px 0px;
}

.bw_news_mobile_view {
  display: none !important;
}

.bw_news_featured_box_main {
  background: black;
  padding: 80px 0px;
}

.bw_news_featured_box_text h3 {
  color: #ffffff;
  opacity: 0.67;
  font-weight: 500;
  font-size: 21px;
}

.bw_news_featured_box_text h2 {
  font-weight: 600;
  font-size: 41px;
  color: #ffff;
  margin: 25px 0px 25px 0px;
}

.bw_news_featured_box_text p {
  color: #ffff;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 25px;
}

.bw_news_featured_box_text a,
.bw_news_featured_box_text a:hover,
.bw_news_featured_box_text a:active,
.bw_news_featured_box_text a:focus {
  color: #ffff;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  position: relative;
  width: fit-content;
  z-index: 99;
}

.bw_news_featured_box_text {
  padding: 0px 40px;
}

.bw_news_featured_box_text a:before {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 67%;
  height: 8px;
  background: #3d3636;
  z-index: -9;
}

.bw_news_Content_main ul {
  padding: 0;
  list-style: none;
}

.bw_news_Content_main ul li {
  padding: 50px 0px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  align-items: center;
}

.bw_news_box_Content_title_img {
  display: flex;
  align-items: center;
  width: 11%;
}

.bw_news_box_Content_img {
  width: 17%;
}

.bw_news_box_Content_title {
  padding: 0px 30px;
  width: 45%;
}

.bw_news_box_Content_link {
  width: 44%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.bw_news_box_Content_title h4 {
  color: grey;
  font-weight: 500;
  font-size: 20px;
}

.bw_news_box_Content_title h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 37px;
}

.bw_news_box_Content_link a,
.bw_news_box_Content_link a:hover,
.bw_news_box_Content_link a:active,
.bw_news_box_Content_link a:focus {
  font-weight: 500;
  font-size: 20px;
  line-height: 49px;
  color: black;
  text-decoration: none;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 99;
}

.bw_news_box_Content_link a:before {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 20%;
  height: 10px;
  background: #e4e4e4;
  z-index: -9;
  transition: ease-out 0.3s;
}

.bw_news_box_Content_link a:hover:before {
  width: 100%;
  transition: ease-in 0.3s;
}

.bw_news_Content_main {
  margin-top: 30px;
}

/* .bw_news_Content_main ul li:nth-last-child(1) {
    border: none;
} */

.bw_news_Content_main ul li:before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 0px;
  height: 2px;
  background: #244404;
  transition: ease-out 0.5s;
}

.bw_news_Content_main ul li:hover:before {
  width: 100%;
  transition: ease-in 0.5s;
}

.bw_animate_style_d {
  overflow: hidden;
}

/*********** home page news section css end ***********/

/*********** home page insta section css start ***********/
section.bw_insta_section {
  padding: 70px 0px 70px 0px;
}

section.bw_insta_section.pb-0 {
  position: relative;
}

.bw_insta_content_left {
  position: relative;
}

.bw_insta_content_left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bw_insta_content_left ul li h2 {
  color: #fff;
  text-shadow: -2px 0 1px #3b64c6, 0 2px 1px #3b64c6, 2px 0 1px #3b64c6,
    0 -2px 1px #3b64c6;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  position: absolute;
  transform: rotateZ(-90deg);
  top: 190px;
  left: -200px;
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  line-height: 76px;
  opacity: 0.2;
}

.bw_insta_content_left ul li.bw_insta_box {
  background-color: #fff;
  box-shadow: 0px 0px 34px rgb(0 0 0 / 10%);
  border-radius: 20px;
  padding: 20px;
  width: 400px;
  height: 500px;
  margin-left: 105px;
}

.bwn_home_insta {
  margin-top: 30px !important;
}

.bw_insta_content_left::before {
  content: "";
  background-color: rgba(68, 113, 231, 0.02);
  width: 210px;
  height: 510px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: -20px;
  top: -20px;
  z-index: -1;
}

.bw_insta_inner_content h3 {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(0 0 0 / 50%);
  font-weight: 600;
  margin: 10px 0 10px;
}

.bw_insta_inner_content button,
.bw_insta_inner_content button:hover,
.bw_insta_inner_content button:focus,
.bw_insta_inner_content button:active {
  background-color: #000 !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  margin: 0 auto;
  display: block;
  padding: 8px 20px;
  border-radius: 0;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.bw_insta_inner_content h4 {
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
}

.bw_insta_inner_content span {
  color: rgb(0 0 0 / 52%);
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  display: block;
}

.bw_insta_inner_content p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.bwn_home_insta .bwn_footer_iocn_circle.bwn_footer_bg_insta {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  transition: all .4s ease;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.bwn_home_insta .bwn_footer_iocn_circle.bwn_footer_bg_insta:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  transition: all .4s ease-in-out;
}

.bwn_home_insta .bwn_footer_iocn_circle.bwn_footer_bg_insta svg {
  color: #fff;
}

.bw_insta_inner_content {
  text-align: center;
}

/*********** home page insta section css start ***********/

/********************home page our client style start ************************/
.bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
  border-bottom: 1px solid #E2E2E2;
  width: max-content;
  margin: 0 auto;
}

/* .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color:#3D63C3 !important;
} */
.bwn_our_clint_tab .css-1aquho2-MuiTabs-indicator {
  background-color: #3D63C3;
}

/* .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
	font-family: 'Poppins' !important;
	font-style: normal;
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 20px !important;
	text-transform: capitalize !important;
	padding: 15px 74px !important;
} */
.bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  padding: 15px 25px !important;
  /* border-right: 1px solid #ccc; */
  color: #4b4a4a !important;
}

.bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.css-1q2h7u5 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  padding: 15px 25px !important;
  /* border-right: 1px solid #ccc; */
  color: #4b4a4a !important;
}

.bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.css-1q2h7u5 {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-transform: capitalize !important;
  padding: 15px 25px !important;
  color: #4b4a4a !important;
}

.bwn_our_clint_tab_button {
  transition: all .4s ease;
}

ul.bw_client_logo_img_ul li {
  display: inline-block !important;
  justify-content: space-between !important;
  text-align: center;
  vertical-align: middle;
  width: 20%;
  margin: 20px auto;
  /* cursor: pointer; */
}

.bwn_client_tab_logo ul li img {
  width: 150px;
}

/* .bwn_client_tab_logo{
  min-height: 250px;
} */
/********************home page our client style end ************************/
.our_process_container {
  justify-content: center;
  row-gap: 15px;

  .card {
    box-shadow: 1px 1px 10px #c7c7c7;
    transition: all .3s linear;
    border-radius: 12px;

    &:hover {
      transform: translateY(-5px);
    }
  }
}

.digital_marketing_card {
  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 1px 1px 10px #fff;
  }

  h2 {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
  }
}

.dentist_card_design {
  justify-content: center;
  row-gap: 15px;

  .card {
    border-radius: 10px;
    border: 0;
    box-shadow: 1px 1px 10px #fff;
    position: relative;
    z-index: 1;
    background-color: #fff;
    border: 2px solid #3b64c6;
    scale: .98;
    padding: 10px;
    transition: all .3s linear;

    &::before {
      position: absolute;
      content: "";
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      background-color: #3b64c6;
      height: 50%;
      width: calc(100% + 20px);
      z-index: -1;
      border-radius: 15px;
    }

    .card-body {
      background-color: #fff;
      padding: 10px 15px;
    }

    &:hover {
      scale: 1;
      transform: translateY(-5px);
    }
  }



  h3 {
    &:hover {
      color: #3d63c3;
    }
  }
}

/* ------------Responsive style start------------- */

@media (min-width: 320px) and (max-width: 767px) {
  .bw_new_branding_main_title p {
    font-size: 14px;
  }

  .digital_marketing_card {
    h2 {
      font-size: 16px;
    }
  }

  .bw_cr_gems_section .bw_banner_heading {
    font-size: 28px !important;
    line-height: 35px;
    padding-bottom: 5px;
  }

  .video_gems {
    width: 100% !important;
    height: 180px !important;
    object-fit: cover !important;
    margin-top: 20px !important;
  }

  .wistia_responsive_wrapper::after {
    content: '';
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }

  body .apiSecUnder.cr_gems_sectionn {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    margin-top: 20px;
  }

  body .innerMapWrp {
    height: 50vh;
  }

  .bw_home_banner_iframe_box {
    display: none;
  }

  .bwn_about_us_video {
    margin-top: 50px;
  }

  .bw_client_logo_imgs ul li img {
    width: 85%;
    height: auto;
    margin: 0 5px 20px 5px;
  }

  .bw_client_logo_img_ul {
    width: 100%;
    display: contents;
  }

  .google_review_slider_box {
    min-height: 330px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .video_gems {
    height: 450px !important;
  }

  .bw_client_logo_img_ul {
    width: 100%;
    height: 170px;
    margin: 0 auto;
    text-align: center;
    display: contents !important;
  }

  .bwn_clint_logo_tabs_design_bbox {
    padding: 50px 0 10px 0;
  }

  .google_review_slider_box {
    min-height: 410px;
  }

  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 18px !important;
    padding: 15px 30px !important;
  }

  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.css-1q2h7u5 {
    font-size: 18px !important;
    padding: 15px 30px !important;
  }

  ul.bw_client_logo_img_ul li {
    width: 20%;
  }

  .bwn_client_tab_logo ul li img {
    width: 95px !important;
  }

  ul.bw_client_logo_img_ul li {
    margin: 10px auto;
    padding: 10px 0;
  }

  /* .bwn_our_clint_tab_section_box {
	min-height: 300px;
} */
  /* .bwn_client_tab_logo {
	min-height: 280px;
} */
}

@media (min-width: 768px) and (max-width: 809px) {
  .bw_home_banner_iframe_box iframe {
    height: 285px;
  }
}

@media (min-width: 810px) and (max-width: 833px) {
  .bw_home_banner_iframe_box iframe {
    height: 305px;
  }
}

@media (min-width: 834px) and (max-width: 1023px) {
  .bw_home_banner_iframe_box iframe {
    height: 315px;
  }
}

@media (min-width: 1024px) and (max-width: 1050px) {
  .bw_home_banner_iframe_box iframe {
    height: 268px;
  }
}

@media(min-width:768px) and (max-width:1024px) {
  .bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
    width: max-content;
  }
}

@media (min-width: 1100px) and (max-width: 1149px) {
  .bw_banner_content_inner h1 {
    font-size: 35px;
  }

  .bw_banner_content_inner p {
    font-size: 16px;
  }

  .bw_new_branding_main_title h2 {
    font-size: 45px;
  }

  .bw_new_branding_box h2 {
    font-size: 17px !important;
  }

  .bw_new_branding_box h3 {
    font-size: 17px !important;
  }

  .bwn_sopify_heading h4 {
    line-height: 55px;
  }

  .bw_brand_slider_left_text_main h2 {
    font-size: 45px;
  }

  .bwn_about_us_counts_list_box ul li {
    width: 31%;
  }

  .bw_project_slider_text h2 {
    font-size: 45px;
  }

  .bw_google_review_title h2 {
    font-size: 45px;
    margin-bottom: 30px;
  }

  .bwn_sopify_text_box_inner h4 {
    font-size: 28px;
    line-height: 35px;
  }

  .bwn_sopify_text_box_inner p {
    font-size: 16px;
  }

  .bw_brand_slider_left_text_main p {
    font-size: 16px;
  }

  .bw_about_comany_title_text p {
    font-size: 16px;
  }

  .bwn_home_about_us_group_image_box img {
    margin-top: 30px;
  }

  .bwn_about_countup {
    font-size: 40px;
    line-height: 30px;
  }

  .bwn_about_us_counts_list_box_inner_box p {
    font-size: 18px;
  }

  .bw_project_slider_text p {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 10px;
  }

  .bw_project_slider_item_text_title h4 {
    font-size: 20px;
  }

  .bw_project_slider_item_text_paragraph p {
    font-size: 16px;
    line-height: 25px;
  }

  .bwn_client_tab_logo ul li img {
    width: 130px;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_home_banner_iframe_box iframe {
    height: 310px;
  }

  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 15px 52px !important;
  }

  .bwn_client_tab_logo ul li img {
    width: 100px;
    height: 40px;
  }

}

@media (min-width: 1351px) and (max-width: 1450px) {
  .bw_home_banner_iframe_box iframe {
    height: 312px;
  }

  .bwn_client_tab_logo ul li img {
    width: 100px !important;
  }

  .bwn_client_tab_logo ul li img {
    width: 100px;
    height: 40px;
  }
}

@media (min-width: 1536px) and (max-width: 1538px) {
  .bw_home_banner_iframe_box iframe {
    height: 428px;
  }

  .bwn_client_tab_logo ul li img {
    width: 100px !important;
  }
}

@media (min-width: 768px) {
  .bw_banner_mobile_view {
    display: none;
  }

  .bwn_client_logo_mobile_view {
    display: none !important;
  }
}

@media (max-width: 359px) {
  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 70px !important;
  }

  .bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }

}

@media (max-width: 767px) {
  .bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 0 !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    text-transform: capitalize !important;
    max-width: 70px !important;
    min-width: 70px !important;
  }

  .bw_banner_image_desktopview {
    display: none;
  }

  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    padding: 15px 0 !important;
  }

  .bwn_our_clint_tab_button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    min-width: 70px !important;
  }

  .bw_port_client_img_outerrr_box {
    /* border: 1px solid #cccccc69; */
    min-height: 100px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
  }

  ul.bw_client_logo_img_ul li {
    display: inline-block !important;
    justify-content: space-between !important;
    text-align: center;
    vertical-align: middle;
    width: 50%;
    margin: 8px auto;
    /* cursor: pointer; */
  }

  .bwn_our_clint_tab_section_box .css-19kzrtu {
    padding: 10px 0;
  }

  /* .bwn_client_logo_Desktop_View.only_portfolio{
    display: none;
  } */
  /* .bwn_client_logo_Desktop_View.only_home{
     display: none !important;
  } */
}

.popupbutton {
  cursor: pointer;
}

.modal-header_close {
  display: flex;
  align-items: flex-start;
  justify-content: end;
  padding: 0.3rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

button.close.close_model {
  display: flex;
  background-color: red;
  padding: 0px 4px;
}

.jck-close-button {
  position: relative;
  top: -3;
}

.thankyou-wrapper {
  min-height: calc(100vh - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.thankyou-wrapper img {
  width: 80px;
}

.thankyou-wrapper h1 {
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 15px 0 20px;
  background: -webkit-linear-gradient(180deg, #082262 0%, #3d63c3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thankyou-wrapper p {
  margin: 0 0 10px;
  font-size: 18px;
  text-transform: capitalize;
}

@media only screen and (max-width: 992px) {
  .thankyou-wrapper h1 {
    font-size: 80px;
  }

  .thankyou-wrapper p {
    font-size: 16px;
  }

  .thankyou-wrapper img {
    width: 80px;
    margin-bottom: 0px;
  }

  .thankyou-wrapper {
    min-height: calc(100vh - 250px);
  }
}

@media only screen and (max-width: 767px) {
  .thankyou-wrapper h1 {
    font-size: 40px;
  }

  .thankyou-wrapper p {
    font-size: 14px;
    padding: 0 2rem;
  }

  .thankyou-wrapper img {
    width: 50px;
  }

  .thankyou-wrapper {
    min-height: calc(100vh - 20px);
  }
}

.mapWrapper {
  background: #e9efff;
  border-top: 1px solid #dee0e4;
  text-align: center;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;
}

.innerMapWrp {
  width: 85vw !important;
  height: 70vh;
  margin-left: auto;
  margin-right: auto;

}

.mapWrapper .bw_google_review_title {
  margin-bottom: 60px;
}

@media(min-width: 1901px) {
  .insta-iframe {
    height: 500px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {
  .insta-iframe {
    height: 460px;
  }

  .bwn_client_tab_logo ul li img {
    width: 100px !important;
    height: 40px !important;
  }
}

@media(min-width: 1280px) and (max-width: 1439px) {
  .insta-iframe {
    height: 325px;
  }

  .bw_insta_content_left ul li.bw_insta_box {
    height: 370px !important;
  }
}

@media(min-width: 992px) and (max-width:1279px) {
  .insta-iframe {
    height: 287px;
  }
}

@media(min-width: 651px) and (max-width:991px) {
  .insta-iframe {
    height: 297px;
  }
}

@media(max-width: 650px) {
  .insta-iframe {
    height: 380px;
  }
}

.iframe-box {
  display: flex;
  align-items: center;
  height: 100%;
}

@media(min-width:1200px) and (max-width:1440px) {
  .video_gems {
    width: 100% !important;
    height: 550px !important;
    object-fit: cover !important;
    margin-top: 20px !important;
  }
}