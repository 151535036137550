/* @media (min-width: 1025px) {
  .bw_djwd_table_mobileview.bw_prcing_seo {
    display: unset;
  }
} */
.bw_pricing_montly_table tbody tr td {
  text-align: center;
  vertical-align: middle;
  font-size: 14px !important;
  font-family: 'poppins' !important;
}
.bw_pricing_montly_table tbody tr td:nth-child(1) {
  text-align: left;
}

.bw_pricing_upperbox_border {
  border: 1px solid #2444041a;
  border-radius: 20px;
}
.bw_new_mont_hading_box {
	padding: 25px 25px;
	min-height: 143px;
}


@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_new_mont_hading_box h6 {
    font-size: 16px;
    color: #000;
    line-height: 25px;
  }
.bw_new_mont_hading_box {
    padding: 15px;
    min-height: 116px;
}
  .bw_mont_inner_heading_box.bw_pfeture_text_margin p {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .bw_mont_inner_heading_box.bw_hair_salone_headingbox {
    padding: 55px 35px;
    max-height: 230px;
  }
}
@media (min-width: 1440px) and (max-width: 1650px) {
  .bw_new_mont_hading_box h6 {
    font-size: 16px;
    color: #000;
    line-height: 25px;
  }
  .bw_new_mont_hading_box {
	padding: 15px;
	min-height: 113px;
}
}