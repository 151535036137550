body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-primary{
  height: 45px;
  background-color: #3d63c3 !important;
  border-radius: 0 !important;
}

:root{
  --white: #fff;
  --black: #000;
  --primary-color: #3D63C3; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mx-w-100{
  max-width: 100% !important;
}

.mx-w-900 {
  max-width: 900px;
}

.fw-900 {
  font-weight: 900;
}
.fw-700 {
  font-weight: 700;
}

.fs-20 {
  font-size: 20px;
}

.w-80 {
  width: 80px;
}

.bg-black {
  background: #000;
}

.bg-grey {
  background-color: #F5F7FC;
}

.bg-blue {
  background-color: #3d63c3;
}

.bg-blue-1 {
  background-color: #8AACFF;
}

.bg-grey-1 {
  background-color: #262626;
}

.text-blue {
  color: #3D63C3;
}

.text-grey {
  color: #6e6f72;

}

.text-grey-1 {
  color: #8c8c8c;

}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.border-right-1 {
  border-right: 1px solid rgb(255, 255, 255, .3);
}

.flex-none {
  flex: none;
}

.head-1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 85px;
}

.para-1 {
  font-size: 25px;
  line-height: 38px;
  font-weight: 400;
}

.para-2 {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
}

.head-2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.head-3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.head-4 {
  font-size: 36px;
  font-weight: 600;
  line-height: 45px;
}


.head-5 {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
}

.para-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.para-3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

@media(max-width:776px) {
  .head-2 {
    font-size: 34px;
    line-height: 45px;
  }
}

@media(max-width:576px) {
  .py-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .head-2 {
    font-size: 30px;
    line-height: 40px;
  }

  .para-2 {
    font-size: 14px;
    line-height: 30px;
  }

  .head-5 {
    font-size: 24px;
    line-height: 30px;
  }
  .head-4 {
    font-size: 24px;
    line-height: 30px;
  }

  .sub-heading{
    font-size: 12px;
  }
}

.btn{
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, 0) !important;
}