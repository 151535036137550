.bw_blog1_image_section ul li {
	list-style: disc;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 10px;
  color: rgb(0 0 0 / 81%);;
  font-family: 'Poppins';
  letter-spacing: .5px;
}
.bw_blog1_image_section h3 {
	font-size: 22px;
	line-height: 28px;
	margin: 20px 0;
	color: #000;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: .5px;
}
.bw_blog1_image_section p a{
  color: rgb(0 0 0 / 81%);;
}
.bw_blog1_image_section ul li strong{
  color: rgb(0 0 0 / 81%);;
}
.bw_blog1_image_section ul {
	padding-left: 30px;
}

.bw_all_banner.bw_blog1_banner_padding {
  padding: 81px 0 60px;
  background-color: #fff !important;
  background-image: none !important;
  height: 300px;
  width: 100%;
}
.bw_blog1_banner_height {
  height: 135px;
}
.bw_blog1_image_section {
	margin-top: -155px;
	margin-bottom: 30px;
}
.bw_blog1_btn_box {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}
.bw_blog1_content_box h2 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #000;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
}
.bw_blog1_content_box p {
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: rgb(0 0 0 / 81%);
	text-align: left;
	padding-bottom: 0;
}
.bw_blog1_content_box h4 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: #000;
    margin: 20px 0 10px 0;
}
.bw_blogs_list_style li {
  list-style-type: disc;
}
@media (min-width: 320px) and (max-width: 767px) {
  .bw_blog1_image_section h3 {
    font-size: 20px;
    line-height: 25px;
    margin: 15px 0;
  }
  .bw_blog1_content_box h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .bw_blog1_image_section ul li {
    font-size: 14px;
    line-height: 22px;
  }
  .bw_blog1_btn_box {
    display: block !important;
  }
  .bw_blog1_content_box p {
    font-size: 14px;
    line-height: 25px;
    padding-bottom: 0;
  }
  .bw_blog1_content_box h4 {
    font-size: 18px;
    line-height: 40px;
    margin: 10px 0;
  }
  .bw_blog1_banner_height {
    height: unset;
  }
 .bw_blog1_image_section {
	margin-top: -16px;
}
  .bw_all_banner.bw_blog1_banner_padding {
    height: 140px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_blog1_image_section {
	margin-top: -175px;
}
.bw_blog1_content_box p {
	font-size: 14px;
}
.bw_blog1_content_box h2 {
	font-size: 22px;
	line-height: 30px;
	margin: 10px auto;
}
  .bw_blog1_content_box h4 {
    font-size: 18px;
    line-height: 45px;
  }
  .bw_blog1_image_section ul li {
    font-size: 14px;
    line-height: 22px;
  }
  .bw_blog1_image_section h3 {
    font-size: 20px;
    line-height: 25px;
    margin: 15px 0;
  }
}

@media (min-width: 1150px) and (max-width: 1440px) {
  .bw_blog1_image_section {
    margin-top: -219px !important;
    margin-bottom: 30px;
  }
  .bw_blog1_content_box h2 {
    font-size: 24px;
  }
  .bw_blog1_content_box p {
    font-size: 12px;
    padding-bottom: 0;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .bw_blog1_content_box h4 {
    font-size: 16px;
    line-height: 25px;
  }
  .bw_blog1_content_box h2 {
    font-size: 22px;
    line-height: 23px;
    margin-top: 10px;
}
.bw_blog1_image_section ul li {
	font-size: 12px;
	line-height: 20px;
	margin-bottom: 8px;
	letter-spacing: .5px;
}
.bw_blog1_image_section h3 {
	font-size: 20px;
	line-height: 20px;
	margin: 18px 0;
}
}

.datalimit {
  height: 60px;
}