.bw_bredcrum .MuiBreadcrumbs-li p, .bw_bredcrum .MuiBreadcrumbs-li a{
	font-size: 14px;
	font-family: 'poppins';
}
.bw_sercices_image_section {
  padding: 50px 0;
}
.bg_light_color {
    background-color: #f5f7fc6b;
    padding: 50px 15px;
}
.bg_light_color h2{
	font-weight: 700;
    font-size: 35px;
    line-height: 38px;
    color: #212529;
    width: 100%;
	margin-bottom: 15px;
}
.bg_light_color h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    color: #3D63C3;
    width: 95%;
	margin-bottom: 15px;
}
.bw_sercices_image_section h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #000;
    width: 95%;
}
.bw_sercices_image_section p {
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	color: #737373;
	text-align: justify;
	width: 90%;
	margin-bottom: 10px;
}
 .bg_light_color p {
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	color: #737373;
	text-align: center;
	width: 90%;
	margin-bottom: 10px;
}
.bw_sercices_image_section.bw_shopify_development_section p {
	font-weight: 400;
	font-size: 16px;
	line-height: 28px;
	color: #737373;
	text-align: left;
	width: 100%;
	margin-bottom: 10px;
}
.bw_sevices_left_text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.bw_services_form_section {
  padding: 50px 0;
}
.bw_servics_textyyy p{
	width: 100%;
}
@media (min-width: 320px) and (max-width: 767px) {
   .bw_services_form_section {
          padding: 0px 0 50px 0;
        }
		.bw_sercices_image_section h2 {
			font-size: 20px;
			line-height: 25px;
			text-align: center;
		}
  .hm_branding_box_main.bw_service_branding_main .bw_new_branding_box {
    padding: 10px 10px;
}

.hm_branding_box_main.bw_service_branding_main .bw_new_branding_box p {
    display: block;
}

.hm_branding_box_main.bw_service_branding_main .bw_new_branding_box h2{
  font-size: 16px;
}
  .bw_sercices_image_section {
padding: 30px 0 0px 0;
  }
.bw_sercices_image_section p {
	font-size: 14px;
	text-align: center;
	line-height: 23px;
	width: 100%;
}
  .bw_sevices_left_text_box {
    order: 2;
  }
  .bw_sevices_right_text_box {
    order: 1;
  }
  .bw_sevices_right_text_box img {
    margin-bottom: 20px;
  }
  .bw_sercices_image_section.bw_shopify_development_section p {
	font-size: 15px;
	line-height: 25px;
	margin-bottom: 10px;
  text-align: center;
}
.bg_light_color h2 {
	font-size: 28px;
	line-height: 35px;
}
.bg_light_color p {
	font-size: 14px;
	line-height: 22px;
}
.bg_light_color {
	padding: 40px 15px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
	.bg_light_color h2 {
		font-size: 28px;
		line-height: 35px;
	}
	.bg_light_color p {
		font-size: 14px;
		line-height: 22px;
	}
	.bg_light_color {
		padding: 40px 15px;
	}
.bw_sercices_image_section h2 {
	font-size: 25px;
	line-height: 35px;
}
.bw_sercices_image_section.bw_shopify_development_section p {
	font-size: 14px;
	line-height: 25px;
}
.bw_sercices_image_section p {
	font-size: 14px;
	line-height: 23px;
	width: 100%;
	margin-bottom: 5px;
}
  .bw_sercices_image_section {
	padding: 50px 0;
}
.bw_new_branding_main_title h2 {
	padding-bottom: 20px;
}
.bw_services_form_section {
	padding: 5px 0 40px 0;
}
}

@media(min-width:1150px) and (max-width:1439px){
	.bg_light_color h2 {
		font-size: 28px;
		line-height: 35px;
	}
	.bg_light_color p {
		font-size: 14px;
		line-height: 22px;
	}
	.bg_light_color {
		padding: 40px 15px;
	}
}
@media (min-width: 1150px) and (max-width: 1350px) {
.bw_sercices_image_section h2 {
	font-size: 35px;
	line-height: 40px;
	width: 90%;
}
.bw_sercices_image_section.bw_shopify_development_section p {
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 5px;
}
.bw_sercices_image_section p {
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 5px;
}
  .bw_new_branding_main_title h2 {
    font-size: 40px;
  }
  .bw_sercices_image_section {
	padding: 80px 0 50px;
}
/* .bw_sercices_image_section {
	padding: 40px 0 50px;
} */
.bw_servics_textyyy p{
	width: 95%;
}

}

@media (min-width: 1351px) and (max-width: 1450px) {
.bw_sercices_image_section h2 {
	font-size: 35px;
	line-height: 45px;
	width: 90%;
}
.bw_sercices_image_section.bw_shopify_development_section p {
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 10px;
}
.bw_sercices_image_section p {
	font-size: 14px;
	line-height: 23px;
}
  .bw_servics_textyyy p{
	width: 95%;
}
}
@media(min-width:1440px) and (max-width:1650px){
	.bg_light_color h2 {
		font-size: 28px;
		line-height: 35px;
	}
	.bg_light_color p {
		font-size: 14px;
		line-height: 22px;
	}
	.bg_light_color {
		padding: 40px 15px;
	}
}