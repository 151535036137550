/******** header footer hide in hair salon theme style start *******/
.\/hair-salon-designs\/prague .bw_header_section, .\/hair-salon-designs\/barcelona .bw_header_section, .\/hair-salon-designs\/singapore .bw_header_section,  .\/hair-salon-designs\/melbourne .bw_header_section, .\/hair-salon-designs\/sydney .bw_header_section{
	display: none;
}
.\/hair-salon-designs\/prague .bwn_footer_section, .\/hair-salon-designs\/barcelona .bwn_footer_section, .\/hair-salon-designs\/singapore .bwn_footer_section,  .\/hair-salon-designs\/melbourne .bwn_footer_section, .\/hair-salon-designs\/sydney .bwn_footer_section{
	display: none;
}

/* @import url('../../Assets/css/Santral-Light.otf'); */
/* logo with hover text style */
/* .bw_log_with_hover_text{
  position: relative;
} */
.bw_logo::before {
	bottom: 0;
	color: #fff;
	content: "Belgium WebNet";
	font-size: 23px;
	font-weight: 300;
	height: auto;
	left: 10px;
	opacity: 0;
	position: absolute;
	top: 16px;
	transition: left .5s ease,opacity .3s ease;
	width: max-content;
	z-index: -1;
	cursor: pointer;

  
}
.bw_logo:hover:before {
  left: 4.1rem;
  opacity: 1;
  color: #fff;
  -webkit-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

/******** header footer hide in hair salon theme style end  *******/
.bw_custome_container_header {
  max-width: 1620px !important;
  width: 100%;
}
/* .bw_header_section.bw_header_other_page {
	top: 40px;
} */
section.bw_header_section.is-sticky {
  position: fixed;
  background: linear-gradient(180deg, #082262 0%, #3d63c3 100%);
  width: 100%;
  top: 0;
  /* padding: 15px 0; */
  box-shadow: 2px 1px 20px 0 hsl(0deg 0% 80% / 7%);
  /* transition: 0.5s ease-in; */
  transition: ease-in;
  animation: slideDown .35s ease-out;

}
.bw_phone_Svg_margin_right {
	margin-right: 8px;
	color: #fff;
	/* font-size: 15px; */
}
.bw_btb_border_white:hover .bw_phone_Svg_margin_right {
	color: #000;
}
.bw_logo {
  position: relative;
  z-index: 999;
}
.bwn_mobile_logo img {
	max-width: 90px;
}
.bwn_mobile_logo{
  text-align: left;
}
section.bw_header_section {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;
  /* transition: ease-out 0.5s; */
  transition: ease-out;
  padding: 20px 0px;
}


.bw_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul.bw_menu_list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

ul.bw_menu_list li {
    padding: 5px 20px;
}

ul.bw_menu_list li:last-child {
  padding: 0;
}

/* .bw_logo img {
  width: 250px;
} */
.bw_logo img {
	width: 70px;
}

ul.bw_menu_list li a {
  color: #fff;
  text-decoration: none;
  font-family: "Poppins";
}

/* ***************Header mobile view css start**************** */
.bwn_header_two_btn {
	display: flex;
	justify-content: start;
	margin: 20px 0;
	flex-direction: column;
}
.bw_menu_btn_one{
	border-radius: 0 !important;
	font-size: 15px !important;
	padding: 10px 15px !important;
 background: #2c52b0 !important;
 color: #fff;
 border:1px solid #2c52b0;
font-weight: 600 !important;
width: 100%;
margin-bottom: 15px;
}
.bw_menu_btn_two {
	border-radius: 0 !important;
	font-size: 15px !important;
padding: 10px 15px !important;
 background-color: #fff !important;
 color: #2c52b0 !important;
 border-color: #2c52b0 !important;
  font-weight: 600 !important;
}
.bw_menu_btn_one,.bw_menu_btn_two:hover,.bw_menu_btn_one,.bw_menu_btn_two:focus,.bw_menu_btn_one,.bw_menu_btn_two:active{
outline: 0 !important;
box-shadow: none !important;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle {
	background-color: #2c52b0;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_iocn_circle svg{
  color: #fff;
}
.bwn_mobile_header_social_icon_outer_box .bwn_footer_social_icon_inner_box {
	margin: 0;
}

.bw_mobile_header_price_link_heading {
	font-weight: 600 !important;
	font-size: 20px !important;
	line-height: 25px !important;
	color: #2c52b0 !important;
 font-family: 'Poppins' !important;
}
.bw_mobile_header_price_link ul li a{
  font-weight: 400 !important;
font-size: 20px !important;
line-height: 30px !important;
color: #2c52b0 !important;
 font-family: 'Poppins' !important;
}
.bwn_accordin_main{
  box-shadow: none !important;
  background-color: transparent !important;
}
.bwn_acco_mb_acc_sum{
  padding: 0 !important;
}
.bwn_accordin_main .MuiAccordionDetails-root{
  padding: 0 !important;  
}
.bwn_accordin_main svg{
  color: #2c52b0 !important;
  font-size: 22px;
}
.bwn_accordin_main.MuiAccordion-root.Mui-expanded{
  margin: 0;
}

/* .bwn_accordin_main .MuiAccordion-root::before{
  display: none !important;
} */
/* ***************Header mobile view css end **************** */


/*Menu full screen section css*/
/* body,
  html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  } */
/* .bw_menu_sec {
    height: 0;
    position: absolute;
    overflow: hidden;
    z-index: -9;
    width: 100%;
    top: -20px;
  }  */

/* .bw_menu_sec aside {
    visibility: hidden;
}
.bw_menu_sec_open.bw_menu_sec aside{
  visibility: visible;
} */

.bw_header {
  width: 100%;
  z-index: 999999;
}

.bw_menu_sec.bw_menu_sec_open .aside-section.aside-left {
  transform: translatey(0%);
  transition: transform 0.4s ease-in-out;
}

.bw_menu_sec.bw_menu_sec_open .aside-section.aside-right {
  transform: translatey(0%);
  transition: transform 0.4s ease-in-out;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 40px;
}

.bw_menu_sec .aside-section.aside-left {
  transform: translatey(-100%);
  transition: transform 0.4s ease-in-out;
}

.bw_menu_sec .aside-section.aside-right {
  transform: translatey(-100%);
  transition: transform 0.4s ease-in-out;
}

.bw_menu_sec {
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 99999;
  /* width: 9.5%; */
  top: -20px;
  right: 0px;

  transform: translatey(-100%);
  transition: transform 0.5s ease-in-out;
}

.bw_menu_sec_open {
  z-index: 999999;
  transform: translatey(0%);
  transition: transform 0.5s ease-in-out;
}

.bw_menu_sec .aside-section {
  top: 0;
  bottom: 0;
  position: absolute;
}

.bw_menu_sec .aside-left {
  display: none;
  width: 60%;
  left: 0;
  background: linear-gradient(130.1deg, #2c52b0 0.93%, #3d63c3 98.97%);
  -webkit-transform: translatey(-100%);
  -moz-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  -o-transform: translatey(-100%);
  transform: translatey(-100%);
  transition: transform 0.4s ease-in-out;
}

.bw_menu_sec .aside-right {
  width: 100%;
  right: 0;
  background: #fff;
  -webkit-transform: translatey(-100%);
  -moz-transform: translatey(-100%);
  -ms-transform: translatey(-100%);
  -o-transform: translatey(-100%);
  transform: translatey(-100%);
  transition: transform 0.4s ease-in-out;
}

.bw_menu_sec input[type="checkbox"] {
  display: none;
}

.bw_menu_sec input[type="checkbox"]:checked ~ aside .aside-left {
  transform: translateY(0%);
}

.bw_menu_sec input[type="checkbox"]:checked ~ aside .aside-right {
  transform: translatey(0%);
}

.bw_menu_sec input[type="checkbox"]:checked ~ label .bar {
  background-color: #fff;
}

.bw_menu_sec input[type="checkbox"]:checked ~ label .top {
  -webkit-transform: translateY(0px) rotateZ(45deg);
  -moz-transform: translateY(0px) rotateZ(45deg);
  -ms-transform: translateY(0px) rotateZ(45deg);
  -o-transform: translateY(0px) rotateZ(45deg);
  transform: translateY(0px) rotateZ(45deg);
}

.bw_menu_sec input[type="checkbox"]:checked ~ label .bottom {
  -webkit-transform: translateY(-15px) rotateZ(-45deg);
  -moz-transform: translateY(-15px) rotateZ(-45deg);
  -ms-transform: translateY(-15px) rotateZ(-45deg);
  -o-transform: translateY(-15px) rotateZ(-45deg);
  transform: translateY(-15px) rotateZ(-45deg);
}

.bw_menu_sec input[type="checkbox"]:checked ~ label .middle {
  width: 0;
}

.bw_menu_sec .middle {
  margin: 0 auto;
}

.bw_menu_sec label {
  top: 14px;
  display: inline-block;
  padding: 7px 10px;
  background-color: transparent;
  cursor: pointer;
  margin: 10px;
  z-index: 9999;
  position: absolute;
  right: 110px;
}

.bw_menu_sec .bar {
  display: block;
  background-color: #fff;
  width: 30px;
  height: 3px;
  border-radius: 5px;
  margin: 5px auto;
  transition: background-color 0.4s ease-in, transform 0.4s ease-in,
    width 0.4s ease-in;
}

.aside-section.aside-left {
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.aside-section.aside-right {
  display: flex;
  justify-content: start;
  align-items: start;
  padding-left: 90px;
  height: 100vh;
  padding-top: 18%;
}

ul.bw_first_menu,
ul.bw_second_menu,
ul.bw_third_menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.bw_first_menu li a {
  font-size: 32px;
  color: #2c52b0;
  font-weight: 600;
  text-decoration: none;
}

ul.bw_second_menu li a,
ul.bw_third_menu li a {
  font-size: 18px;
  color: #2c52b0;
  font-weight: 400;
  margin: 9px 0;
  text-decoration: none;
  /* display: block; */
}
ul.bw_second_menu li h4 ul.bw_third_menu li h4 {
  color: #2c52b0;
}

.bw_menu_right_sec p {
  color: #fff;
  font-weight: 200;
}

.bw_menu_right_sec h2 {
  color: #fff;
  line-height: 50px;
}

.bw_menu_right_sec a.bw_menu_btn,
.bw_menu_right_sec a.bw_menu_btn:hover,
.bw_menu_right_sec a.bw_menu_btn:focus,
.bw_menu_right_sec a.bw_menu_btn:active {
  background: #2c52b0 !important;
  border: 2px solid rgba(217, 255, 181, 0.25) !important;
  display: inline-block !important;
  margin: 20px 0px !important;
  border-radius: 0;
  padding: 10px 30px !important;
  box-shadow: none !important;
  outline: 0 !important;
  font-weight: 600 !important;
}

/* .bw_menu_right_sec img {
  position: absolute;
  bottom: 90px;
} */
/* Header other page style start */
.section.bw_header_section.new_bg_header_color{
background: linear-gradient(180deg, #082262 0%, #3d63c3 100%) !important;
}
.bw_header_section.bw_header_other_page {
	background: linear-gradient(180deg, #082262 0%, #3d63c3 100%) !important;
}
/* .bw_header_section .bw_logo_other {
  display: none !important;
}
.bw_header_section.bw_header_other_page .bw_logo_other {
  display: block !important;
}
.bw_header_section.bw_header_other_page .bw_logo_home {
  display: none;
}
.bw_header_section.bw_header_other_page .bw_menu_list li a {
  color: #000;
}
.bw_header_section.bw_header_other_page .bw_menu_btn svg {
  color: #000;
}
.bw_header_section.bw_header_other_page .bw_btb_border_white .bw_custom_btn {
  border: 2px solid #000 !important;
  color: #000 !important;
}
.bw_header_section.bw_header_other_page
  .bw_btb_border_white
  .bw_custom_btn:hover {
  border: 2px solid #2c52b0  !important;
  color: #000 !important;
  background-color: #2c52b0  !important;
  color: #fff !important;
}
.bw_header_section.bw_header_other_page
  .bw_btb_border_white
  .bw_custom_btn:hover:after {
  background-color: #2c52b0  !important;
  color: #fff !important;
  border-color: #2c52b0 ;
}
.bw_header_section.bw_header_other_page
  .bw_btb_border_white
  .bw_custom_btn:hover:before {
  background-color: #2c52b0  !important;
  color: #fff !important;
  border-color: #2c52b0 ;
}
section.bw_header_section.bw_header_other_page.is-sticky {
  background: #fff;
} */
/* Header other page style end */
/*-------- Header Menu Address Box start------  */
.bw_header_menu_logo img {
  margin-top: 30px;
  width: 80px;
  height: auto;
}
.bw_header_menu_adderss_box {
  margin-top: 50px !important;
}
.bw_header_menu_adderss_box svg {
  color: #fff;
  font-size: 35px;
  margin-right: 10px;
}
.bw_header_menu_adderss_box h6 {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  cursor: pointer;
}
.bw_header_menu_adderss_box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0;
}
.bw_second_menu h4,
.bw_third_menu h4 {
  color: #2c52b0 !important;
  font-size: 20px;
}
.bw_book_an_Appointment_headermenu_link h5 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #2c52b0 !important;
  line-height: 47px;
  margin-bottom: 0;
  display: block;
}

/* .bw_book_an_Appointment_headermenu_link h5:after {
  display: block;
  content: "";
  border-bottom: solid 1px #3c6910;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  padding-bottom: -1px;
}
.bw_book_an_Appointment_headermenu_link h5:hover:after {
  transform: scaleX(1);
} */
/*-------- Header Menu Address Box end------  */

/* -------- Header Dropdawn Style start-------- */
.bw_header_dropdown {
  position: relative !important;
  display: inline-block;
}

.bw_header_dropdown-content {
	visibility: visible;
	position: absolute;
	background-color: #0000;
	width: max-content !important;
	/* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
	z-index: 1;
	padding: 5px 0 0 0;
	transform: translateY(0);
	border-radius: 4px;
 top: 23px
}

.bw_header_dropdown-content a {
  color: #000 !important;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}
.bw_header_dropdown-content::before {
  position: absolute;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  background-color: #fff;
  left: 16%;
  margin: 0 auto;
  content: "";
  top: 8px;
}
.bw_extra_dropdown_div {
	background-color: #fff;
	margin: 10px 0 0 0;
	border-radius: 4px;
	padding: 15px 0;
	box-shadow: 0px 3px 20px #cccccc7a;
	z-index: 999 !important;
}
.bw_header_dropdown-content a {
  line-height: 18px !important;
}
.bw_header_other_page .bw_header_dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  line-height: 20px !important;
}
.bw_header_dropdown-content a:hover {
  color: #2c52b0 !important;
}
.bwn_header_book_an_appointment_button.bw_btb_border_black .bw_custom_btn {
	border-color: #2c52b0 !important;
	color: #2c52b0 !important;
	text-transform: uppercase !important;
	font-size: 16px !important;
	line-height: 20px !important;
	font-weight: 600 !important;
	margin-top: 0;
}
.bwn_header_book_an_appointment_button .bw_custom_btn{
  	color: #2c52b0 !important;
}
.bwn_header_book_an_appointment_button.bw_btb_border_black {
	text-align: left;
	margin-top: 3px;
	padding-bottom: 20px;
  margin-bottom: 10px;
}
/* -------- Header Dropdawn Style end-------- */

/*------- Header Link hover bodrder style start----- */

.header-main-d .left-content li a {
  display: inline-block;
}
.header-main-d .left-content a:after {
  display: block;
  content: "";
  border-bottom: solid 1px #2c52b0;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  padding-bottom: 3px;
}
.header-main-d .left-content a:hover:after {
  transform: scaleX(1);
}
/*------- Header Link hover bodrder style end----- */
/* simple new header style start */

/* simple new header style end */

/*********************** Responsive style*************************/
@media(max-width:1024px){
  ul.bw_menu_list li{
  display: none;
}
}
@media(min-width:1025px){
  .bw_menu_btn {
	display: none;
}
}
@media (min-width: 768px) {
  .bw_aside_right_mobile_view {
    display: none;
  }
}
@media (min-width: 992px) {
  .bw_menu_sec .aside-left {
    display: block;
  }

  .bw_menu_sec .aside-right {
    width: 40%;
  }
}
@media(min-width:1025px) and (max-width:1186px){
  ul.bw_menu_list li a {
	font-size: 12px;
}
ul.bw_menu_list li {
	padding: 0 10px;
}
}
@media(min-width:320px) and (max-width:359px){
  .bw_mobile_menu_bottom h2 {
	font-size: 22px !important;
}
.bw_mobile_header_price_link ul li a {
	font-size: 14px !important;
	line-height: 28px !important;
}
.bw_mobile_header_price_link_heading {
	font-size: 17px !important;
	line-height: 20px !important;
	text-transform: uppercase;
}
}

@media(min-width:360px) and (max-width:767px){
  ul.bw_first_menu li a {
	font-size: 25px !important;
	line-height: 40px !important;
}
.bw_mobile_header_price_link ul li a {
	font-size: 16px !important;
}
}
@media (min-width: 320px) and (max-width: 767px) {
  section.bw_header_section {
    top: 0;
    padding: 5px 0px;
  }
  /* .bw_header_section.bw_header_other_page {
    top: 75px;
  } */
  .bwn_header_book_an_appointment_button.bw_btb_border_black {
    /* margin-bottom: 70px; */
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 100px;
  }
.bw_first_menu li {
	border-top: 1px solid #1d0de517;
}
  .bw_menu_close_btn {
	right: 20px !important;
}
  .bw_menu_close_btn svg {
    font-size: 25px !important;
  }
  /* New Mobile Menu Section style */
  .bw_menu_right_sec.bw_menu_desktopview {
    display: none;
  }
  .header-main-d .left-content {
    width: 0;
  }
  .header-main-d .right-content {
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .header-main-d .right-content {
    background: #fff !important;
  }
  .bw_mobile_menu_bottom {
    background-color: #f3ffe7;
  }
  .bw_mobile_menu_bottom h2 {
    font-size: 24px;
    font-weight: 700;
    color: #2c52b0;
  }
.bw_header_menu_logo img {
	margin: 0 0 20px 0;
	width: 55px;
	height: auto;
}
.bw_mobile_menu_bottom .bw_footer_link h3 {
	font-size: 20px !important;
	color: #2c52b0 !important;
	font-weight: 600 !important;
	line-height: 30px !important;
}
  .bw_mobile_menu_bottom p {
    font-size: 14px;
    font-weight: 400;
    color: #2c52b0;
  }
  .bw_mobile_menu_bottom {
    background-color: #f9fafd;
    padding: 40px 20px;
   
  }
  .bw_mobile_menu_bottom .bw_footer_link ul li a {
    color: #2c52b0;
    text-decoration: none;
    display: flex;
  }
  .bw_second_menu h4,
  .bw_third_menu h4 {
    font-size: 17px !important;
    font-weight: 800;
    margin-top: 20px;
  }
  .bw_mobile_menu_bottom a.bw_menu_btn,
  .bw_mobile_menu_bottom a.bw_menu_btn:hover,
  .bw_mobile_menu_bottom a.bw_menu_btn:focus,
  .bw_mobile_menu_bottom a.bw_menu_btn:active {
    background: #2c52b0 !important;
    border: 2px solid rgba(217, 255, 181, 0.25) !important;
    display: inline-block !important;
    margin: 20px 0px !important;
    border-radius: 0;
    padding: 8px 20px !important;
    box-shadow: none !important;
    outline: 0 !important;
    font-weight: 600 !important;
    font-size: 15px;
  }
  .bw_mobile_menu_bottom .bw_footer_social_icon ul {
    display: flex !important;
    justify-content: center;
  }
  .bw_mobile_menu_bottom .bw_footer_social_icon ul li {
    margin-right: 5px !important;
    width: 35px;
    height: 35px;
    border: 1px solid #2c52b0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    vertical-align: middle !important;
    align-items: center !important;
  }
  .bw_second_menu h4,
  .bw_third_menu h4 {
    font-size: 17px !important;
    font-weight: 800;
    margin-top: 30px;
  }
  .bw_mobile_menu_top {
    margin-top: 0px;
    padding: 0 20px;
  }

.bwn_accordin_main .MuiAccordionSummary-root {
	min-height: 42px !important;
}
  .bwn_accordin_main.MuiAccordion-rounded {
	border-radius: 0 !important;
	/* border-color: transparent!important; */
  border-top: 1px solid #1d0de517 !important;
}
.bwn_acco_mb_acc_sum .MuiIconButton-root {
	padding: 0 12px !important;
}
.bwn_acco_mb_acc_sum .MuiAccordionSummary-content{
  margin: 0 0 !important;
}
.bw_mobile_menu_top .bw_footer_social_icon h3 {
	font-size: 16px !important;
	line-height: 20px !important;
	color: #2c52b0 !important;
	font-weight: 600 !important;
}
.bwn_footer_social_icon_box_new {
	padding: 15px 0 !important;
}
.bw_mobile_header_price_link_heading {
	font-size: 16px !important;
	line-height: 45px !important;
	text-transform: uppercase;
}
.bwn_accordin_main.MuiAccordion-root.Mui-expanded {
	border-color: #1d0de517 !important;
}
.bwn_accordin_main.MuiAccordion-root::before {
	height: 0px !important;
	background-color: #1d0de517;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .aside-section.aside-left {
    flex-direction: column;
  }
  .bw_menu_right_sec h2 {
    line-height: 36px;
    font-size: 24px;
  }
  .aside-section.aside-right {
    padding-left: 40px;
    padding-top: 41%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_logo:hover::before {
    left: 4.1rem;
    opacity: 1;
    color: #fff;
    transition: left 0.5s ease;
  }
  .bw_logo::before {
    left: 10px;
    bottom: 0;
    top: 16px;
    font-size: 20px;
  }
  .aside-section.aside-left {
    align-items: start;
    padding-left: 8%;
  }
  .bw_second_menu h4,
  .bw_third_menu h4 {
    font-size: 19px;
    font-weight: 600;
  }
  ul.bw_second_menu li a,
  ul.bw_third_menu li a {
    font-weight: 400;
    margin: 0;
    line-height: 30px;
    font-size: 16px;
  }
  ul.bw_first_menu li a {
    color: #2c52b0 !important;
  }
  .bw_header_menu_adderss_box h6 {
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
  }
  .bw_menu_right_sec .bw_footer_link h3 {
    color: #2c52b0;
    font-size: 19px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
  }
  .bw_header_menu_adderss_box p {
    font-size: 14px;
  }
  .bw_menu_close_btn svg {
    font-size: 30px;
  }
  .bw_menu_close_btn svg {
    font-size: 32px !important;
  }
}
/*Menu full screen section css*/

/********************************************/
.header-main-d {
  width: 100%;
  display: flex;
  position: fixed;
  z-index: 99999;
  /* overflow: hidden; */
  transition: transform 0.4s ease-in-out;
}

.header-main-d .left-content {
  width: 60%;
  background: #fff;
  position: absolute;
  left: 0;
  height: 100vh;
  opacity: 1;
  transition: transform 0.4s ease-in-out !important;
  transform: translatex(-100%) !important;
}

.header-main-d .right-content {
  width: 40%;
  background: #f9fafd;
  position: absolute;
  right: 0px;
  height: 100vh;
  opacity: 1;
  transition: transform 0.4s ease-in-out !important;
  transform: translatex(100%) !important;
}
.bw_menu_right_sec h2 {
  color: #2c52b0;
}
.bw_menu_right_sec p {
  color: #2c52b0;
}
.bw_header_menu_adderss_box h6 {
  color: #2c52b0;
}
.bw_header_menu_adderss_box p {
  color: #2c52b0;
}
.bw_menu_right_sec .bw_footer_link h3 {
  color: #2c52b0;
  font-size: 20px;
}


.header-main-d .r-open {
  transform: translatex(0%) !important;
  transition: transform 0.4s ease-in-out !important;
  opacity: 1 !important;
}

.header-main-d .l-open {
  transform: translatex(0%) !important;
  opacity: 1 !important;
}

.bw_menu_close_btn {
	position: absolute;
	right: 30px;
	top: 30px;
}

.bw_menu_btn svg {
  color: white;
  font-size: 38px;
  cursor: pointer;
  margin-left: 10px;
}

.bw_menu_close_btn svg {
  color: #2c52b0;
  font-size: 30px;
  cursor: pointer;
  margin-left: 10px;
}

/* Header Social icon style */
.bw_menu_right_sec .bw_footer_link li a {
  width: 50px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #2c52b082;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.bw_menu_right_sec .bw_footer_link li a svg {
  color: #2c52b0 !important;
}


@media(min-width:320px) and (max-width:1024px){
  section.bw_header_section {
    padding: 5px 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .bw_logo::before {
    left: 10px;
    top: 11px;
    font-size: 17px;
    font-weight: 300;
  }
  .bw_logo:hover::before {
    left: 3.1rem;
  }
  .bw_logo img {
	width: 50px !important;
}
  /* .bw_logo img {
	width: 200px;
} */
.bw_btb_border_white .bw_custom_btn {
	padding: 10px 20px !important;
}
.bw_phone_Svg_margin_right {
	margin-right: 5px;
}
  .bw_custome_container_header {
    max-width: 1160px !important;
  }

  ul.bw_menu_list li a {
	font-size: 11px;
	line-height: 12px;
}

ul.bw_menu_list li {
	padding: 9px 12px;
}
.bw_btb_border_white .bw_custom_btn {
	padding: 10px 15px !important ;
}
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_extra_dropdown_div a {
	line-height: 10px !important;
}
  section.bw_header_section.is-sticky {
    padding: 5px 0px;
  }
  section.bw_header_section {
    padding: 5px 0px;
  }
  .bw_menu_right_sec .bw_footer_link h3 {
    line-height: 35px;
    font-size: 14px;
  }
  .bw_menu_right_sec a.bw_menu_btn,
  .bw_menu_right_sec a.bw_menu_btn:hover,
  .bw_menu_right_sec a.bw_menu_btn:focus,
  .bw_menu_right_sec a.bw_menu_btn:active {
    padding: 5px 20px !important;
    font-size: 12px;
  }
  .bw_logo img {
    width: 50px !important;
  }
  .bw_logo:hover::before {
    left: 3.3rem;
  }
  .bw_logo::before {
    font-size: 17px;
    top: 10px;
  }

  /* -------- header menu start ------ */
  .aside-section.aside-left {
    padding-left: 35px;
  }
  ul.bw_first_menu li a {
    font-size: 20px;
  }
  ul.bw_second_menu li a,
  ul.bw_third_menu li a {
    font-size: 14px;
    margin: 4px 0;
  }
  .bw_second_menu h4,
  .bw_third_menu h4 {
    font-size: 14px;
    font-weight: 600;
  }
  .bw_menu_right_sec h2 {
    line-height: 30px;
    font-size: 21px;
  }
  .bw_header_menu_adderss_box h6 {
    font-size: 14px;
    line-height: 25px;
  }
  .bw_header_menu_adderss_box p {
    font-size: 11px;
    line-height: 25px;
    margin-bottom: 0;
  }
  .bw_header_menu_logo img {
    margin-top: 0;
    width: 50px;
  }
  .bw_contat_icon_inner {
    margin: 5px 0;
  }
  .bw_header_menu_adderss_box {
    margin-top: 25px !important;
  }
  .bw_footer_link h3 {
    line-height: 50px;
    font-weight: 600;
    font-size: 18px;
  }
  .bw_footer_social_icon ul li {
    width: 30px;
  }
  .bw_menu_right_sec .bw_footer_link li a {
    width: 40px;
    height: 30px;
  }
  .bw_menu_right_sec .bw_footer_link li a svg {
    font-size: 15px;
  }
  .bw_header_dropdown-content::before {
    width: 12px;
    height: 12px;
    top: 10px;
  }
.bw_header_dropdown-content .bw_extra_dropdown_div a {
	line-height: 8px !important;
	font-size: 12px !important;
}
}

@media (min-width: 1024px) and (max-width: 1149px) {
  .aside-section.aside-left {
    flex-direction: column;
  }
}
@media (min-width: 1025px) and (max-width: 1149px) {
  .bw_header_other_page .bw_header_dropdown-content a {
	line-height: 10px !important;
}
}
@media(min-width:1140px) and (max-width:1650px){
 .bw_header_dropdown-content .bw_extra_dropdown_div a{
    line-height: 12px !important;
    font-size: 14px;
  }
}


@media(min-width:1600px) and (max-width:1900px){
  ul.bw_menu_list li {
	padding: 5px 15px;
}
}
@media(min-width:1100px) and (max-width:1149px){
  ul.bw_menu_list li {
	padding: 0 6px !important;
}
.bw_btb_border_white .bw_custom_btn {
	padding: 10px 15px !important;
}
.bw_phone_Svg_margin_right {
	margin-right: 5px !important;
}
}

@media(min-width:1441px) and (max-width:1599px){
  ul.bw_menu_list li {
    padding: 5px 10px;
}
.bw_btb_border_white .bw_custom_btn {
    padding: 10px 20px;  
}
}