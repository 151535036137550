
  /* swal text css*/
  .swal-text {
    background-color: #FEFAE3;
    padding: 17px;
    border: 1px solid #F0E1A1;
    display: block;
    margin: 15px !important;
    text-align: center;
    color: #61534e;
  }
  /* swal text css ending*/
  /* swal button css*/
  .swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #3d63c3;
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  } 

  /* swal button css ending*/
  /* swal icon css*/
  .swal-icon {
    width: 54px !important;
    height: 54px !important;
  }
  /* swal icon css ending*/
  /* swal icon warning css*/
  .swal-icon--warning__body {
    height: 24px !important;
  }  
  /* swal icon warning css ending*/
    /* swal icon success css*/
  .swal-icon--successs{
    width:60px !important;
    height: 60px !important;
  }
  .swal-icon--success__line--long {
    width: 35px !important;
    right: 5px !important;
    top: 30px !important;
    -webkit-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    -webkit-animation: animateSuccessLong .75s !important;
    animation: animateSuccessLong .75s !important;
}
.swal-icon--success__line--tip {
  width: 17px !important;
  left: 6px !important;
  top: 34px !important;
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  -webkit-animation: animateSuccessTip .75s !important;
  animation: animateSuccessTip .75s !important;
}

.swal-icon--success__ring {
    width: 54px !important;
    height: 54px !important;
    border: 4px solid #a5dc86 !important;
    border-radius: 50% !important;
    box-sizing: content-box !important;
    position: absolute !important;
    left: -6px !important;
    top: -2px !important;
    z-index: 2 !important;
}
/* swal icon success css ending*/
/* swal icon error css*/
.swal-icon--error__line {
  position: absolute !important;
  height: 5px !important;
  width: 35px !important;
  background-color: #f27474 !important;
  display: block !important;
  top: 24px !important;
  border-radius: 2px !important;
}
.swal-icon--error__line--right {
  -webkit-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
  right: 10px !important;
}

.swal-icon--error__line--left {
  -webkit-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
  left: 9px !important;
}

/* swal icon error css ending*/
button.swal-button.swal-button--confirm:hover {
  background-color: #3d63c3 !important;
}