.bw_MeetOurCto_section .bw_custome_container_ash_about {
	max-width: 1400px !important;
	padding: 10px 0;
	margin: auto;
}
.bw_marquee_section {
	margin-top: 104px;
}
.bw_MeetOurCto_section .bw_img_assh_profile {
	text-align: left;
}
.bw_MeetOurCto_section .bw_custome_container_ash_about {
	max-width: 1400px !important;
	padding: 10px 0 120px;
	margin: auto;
}
.bw_special_sevices_marque_tag {
	background-color: rgba(234, 238, 247, 1);
}
.bw_special_sevices_marque_tag marquee {
	font-size: 16px;
	line-height: 25px;
	color: rgba(61, 99, 195, 1);
	font-weight: 500;
    font-family: 'Poppins';
    padding: 6px 0;
}
.bw_MeetOurCto_section .bw_custome_container_ash_about .bw_img_assh_profile img {
	width: 100%;
	border: none;
	padding: 0;
}
.bw_meet_ashi_text h6{
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Poppins';
    font-weight: 400;

}
.bw_meet_ashi_text h1{
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Poppins';
    font-weight: 500;

}
.bw_MeetOurCto_section .bw_new_branding_main_title h2 {
    display: none !important;
}
.bw_MeetOurCto_section .bw_new_branding_main_title_offer h2 {
	font-weight: 500;
	font-size: 50px;
	color: #3D63C3;
	margin-top: 20px;
	position: relative;
	text-align: center;
	padding-bottom: 0px;
}
.bw_MeetOurCto_section .bw_new_branding_section {
	background: #fff;
	padding: 20px 0px 20px !important;
}
.bw_meet_enjoy_20_off {
	background: linear-gradient(180deg, #082262 0%, #3D63C3 100%) !important;
	border-radius: 50px;
	position: relative;
	height: 372px;
	width: 80%;
	margin: 50px auto 0;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.bw_meet_enjoy_20_off::before{
    position: absolute;
    top: -15px;
    left: 15%;
    right: 0;
    content: '';
    background-image: url('../../Assets/images//meet-cto-img/advantage.png');
    z-index: 1;
	height: 120px;
	background-repeat: no-repeat;
	margin: 0 auto;
}

.bw_meet_enjoy_20_off h2 {
	width: 79%;
	text-align: center;
	color: #fff;
	padding: 20px 0;
	justify-content: center;
	margin: 0 auto;
	font-size: 50px;
	font-weight: 600;
	line-height: 50px;
	font-family: 'Poppins';
	margin-bottom: 15px;
	margin-top: 35px;
}
.bw_MeetOurCto_section .bw_new_ash_About_row.row {
	margin-top: -50px;
	width: 85%;
	margin: auto;
}

/* Resposive media */
@media(min-width:320px) and (max-width:767px){
    .bw_meet_enjoy_20_off::before {
        top: -4px;
        background-image: url('../../Assets/images//meet-cto-img/advantage-mobile.png');
        left: 45px;
    }
    .bw_meet_enjoy_20_off {
        height: 250px;
    }
    .bw_MeetOurCto_section .bw_img_assh_profile {
        text-align: center;
    }
    .bw_MeetOurCto_section .bw_custome_container_ash_about .bw_img_assh_profile img {
        width: 80%;
        margin-bottom: 20px !important;
    }
    .bw_meet_ashi_text h6 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 10px;
        text-align: center;
    }
    .bw_meet_ashi_text h1 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 10px;
        text-align: center;
    }
    .bw_MeetOurCto_section .bw_new_branding_main_title_offer h2 {
        font-size: 40px;
        margin-top: 10px;
    }
    .bw_meet_enjoy_20_off h2 {
        padding: 10px 0;
        font-size: 22px;
        line-height: 25px;
        margin-bottom: 15px;
        margin-top: 0;
        width: 100%;
    }
    .bw_meet_enjoy_20_off {
        width: 320px;
        margin: 20px auto 0;
        border-radius: 20px;
    }
    .bw_MeetOurCto_section .bw_custome_container_ash_about {
        padding: 10px 0 80px;
    }
    .bw_special_sevices_marque_tag marquee {
        font-size: 13px;
        line-height: 20px;
        padding: 3px 0;
    }
}
@media(min-width:768px) and (max-width:991px){
    .bw_meet_ashi_text h6 {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 10px;
    }
    .bw_meet_ashi_text h1 {
        font-size: 45px;
        line-height: 45px;
        margin-bottom: 5px;
    }
    .bw_meet_enjoy_20_off {
        width: 700px;
        border-radius: 30px;
        height: 280px;
        margin-top: 10px;
    }
    .bw_meet_enjoy_20_off::before {
        background-image: url('../../Assets/images//meet-cto-img/advantage-tab.png');
        top: -9px;
    left: 120px;
    }
    .bw_meet_enjoy_20_off h2 {
        width: 100%;
        padding: 20px 0;
        font-size: 38px;
        line-height: 40px;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .bw_meet_enjoy_20_off::before {
        top: -16px;
        left: 3%;
        right: 0;
        height: 104px;
        width: 840px;
    }
    .bw_meet_enjoy_20_off {
        height: 372px;
        width: 90%;
        margin: 10px auto 0;
        padding: 15px;
    }
    .bw_MeetOurCto_section .bw_new_ash_About_row.row {
        margin-top: -50px;
        width: 75%;
    }
    .bw_marquee_section {
        margin-top: 72px;
    }
    .bw_meet_ashi_text h1 {
        font-size: 50px;
        line-height: 50px;
    }
    .bw_meet_ashi_text h6 {
        font-size: 30px;
        line-height: 30px;
    }
    .bw_meet_enjoy_20_off h2 {
        width: 75%;
        padding: 15px 0;
        font-size: 40px;
        line-height: 42px;
        margin-bottom: 15px;
        margin-top: 35px;
    }
}
@media(min-width:1200px) and (max-width:1440px){
    .bw_marquee_section {
        margin-top: 55px;
    }
    .bw_MeetOurCto_section .bw_new_ash_About_row.row {
        margin-top: -50px;
        width: 70%;
    }
    .bw_meet_ashi_text h6 {
        font-size: 30px;
        line-height: 30px;
    }
    .bw_meet_ashi_text h1 {
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .bw_MeetOurCto_section .bw_new_branding_main_title_offer h2 {
        font-size: 40px;
        margin-top: 10px;
    }
    .bw_meet_enjoy_20_off {
        height: 350px;
        width: 1100px;
        margin: 20px auto 0;
        padding: 15px;
    }
    .bw_meet_enjoy_20_off h2 {
        width: 70%;
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 15px;
        margin-top: 30px;
    }
    .bw_meet_enjoy_20_off::before {
        left: 13%;
    }
    .bw_MeetOurCto_section .client-country h3 {
        font-size: 40px;
        line-height: 45px;
    }
    .bw_special_sevices_marque_tag marquee {
        font-size: 14px;
        line-height: 23px;
        padding: 3px 0;
    }
}
@media(min-width:1200px) and (max-width:1366px){
    .bw_meet_enjoy_20_off::before {
        left: 12.5% ;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }
    .bw_special_sevices_marque_tag marquee {
        font-size: 14px;
        line-height: 23px;
        padding: 3px 0;
    }
}



@media(min-width:320px) and (max-width:991px){
   
}