/* Banner Heading and Subheading Style*/
.bw_all_banner {
  /* background-color: #d9d9d933; */
  background-image: url("../../Assets/images/home/bg-green-top-banner.webp");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding: 145px 0 55px;
  background-size: cover;
}

.bw_banner_heading {
  margin: 0 auto;
  font-weight: 700;
  font-size: 45px;
  line-height: 65px;
  color: #fff;
  text-align: center;
  text-transform: capitalize !important;
}

.bw_banner_sub_heading {
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-align: center;
  padding: 0 15px;
}

/* excited_to_work style */
.bw_excited_to_work {
  padding: 80px 0 30px 0;
}

.bw_excited_to_work_inner h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  text-align: center;
}

.bw_excited_to_work_inner p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  padding: 0 15px 60px;
}

.bw_car_search_box {
  margin: 0 auto;
}

.bw_car_search_box .form-control {
  background: #ffffff;
  box-shadow: 0px 4px 34px rgb(61 104 221 / 8%) !important;
  border-radius: 10px;
  width: 74%;
  border: none;
  padding: 30px 20px 30px 50px !important;
  margin: 0 auto !important;
}

.bw_car_search_box .form-control::placeholder {
  font-size: 20px;
  color: #000;
}

.bw_car_search_box svg {
  position: absolute;
  left: 14%;
  color: #000;
  font-size: 25px;
  bottom: 27%;
}

.bw_car_tech_padding {
  padding: 84px 0 10px 0;
}

.bw_car_tech_box {
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 30px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  position: relative;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  box-shadow: 0 0 2px #ccc;
  transition: all 0.4s ease-in-out;
  margin-bottom: 30px;
  min-height: 218px;
}

.bw_car_tech_box h4 {
  font-weight: 700;
  font-size: 23px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
}

.bw_car_tech_box p {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #00000080;
  margin-bottom: 5px;
}

.bw_car_tech_box .bw_map_svg {
  color: #000000f7;
  font-size: 30px;
  position: relative;
  left: -8px;
}

.bw_car_tech_next_box {
  width: 40px;
  height: 40px;
  background: #3d63c321;
  border: 1.5px solid #2c52b0;
  border-radius: 4px;
  display: none;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  right: 6%;
}

.bw_car_tech_next_box svg {
  font-size: 24px;
  color: #2c52b0;
}

.bw_car_tech_box a {
  display: none;
  transition: all 0.5s ease-in-out;
}

.bw_car_tech_box:hover a {
  display: block;
  transition: all 0.5s ease-in-out;
}

.bw_car_tech_box:hover {
  background: #ffffff;
  border-bottom: 3px solid #2c52b0 !important;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px 6px 20px 0px #ccccccb5;
}

.bw_btn_load_more {
  border-color: #0000001a !important;
  font-weight: 600 !important;
}

.bw_car_tech_box:hover .bw_car_tech_next_box {
  display: flex;
  /* justify-content: center;
  vertical-align: middle;
  align-items: center; */
}

@media (min-width: 320px) and (max-width: 374px) {
  .bw_careers_section .bw_all_banner {
    padding: 90px 0 40px;
  }

  .bw_careers_section .bw_banner_heading {
    font-size: 28px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_careers_section.bw_all_banner {
    padding: 90px 0 60px;
  }

  .bw_all_banner.bw_banner_pricing_aed {
    padding: 120px 0 30px;
  }

  .bw_banner_heading {
    font-size: 25px;
    line-height: 30px;
    padding-bottom: 5px;
  }

  .bw_banner_sub_heading {
    font-size: 14px;
    line-height: 22px;
  }

  .bw_excited_to_work_inner h2 {
    font-size: 25px;
    line-height: 35px;
  }

  .bw_excited_to_work_inner p {
    font-size: 16px;
    line-height: 35px;
    padding: 0 15px 40px;
  }

  .bw_car_search_box .form-control {
    padding: 20px 15px 20px 40px !important;
    width: 90% !important;
  }

  .bw_car_tech_padding {
    padding: 64px 0 30px 0;
  }

  .bw_excited_to_work {
    padding: 10px 0 0 0;
  }

  .bw_car_search_box .form-control::placeholder {
    font-size: 16px;
    color: #000;
    left: 10% !important;
    position: relative;
  }

  .bw_car_search_box svg {
    position: absolute;
    left: 3%;
    color: #000;
    font-size: 20px;
    bottom: 18%;
  }

  .bw_car_search_box .form-control::placeholder {
    font-size: 16px;
    color: #000;
  }

  .bw_car_search_box svg {
    left: 9%;
    font-size: 21px;
  }

  .bw_car_tech_box {
    padding: 30px 30px;
    margin-bottom: 20px;
  }

  .bw_car_tech_box h4 {
    font-size: 20px;
  }

  .bw_car_tech_box .bw_map_svg {
    font-size: 25px;
  }

  .bw_car_tech_box h4 {
    width: 245px;
  }

  .bw_car_tech_box p {
    font-size: 16px;
  }

  .bw_car_tech_next_box {
    top: 70%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bw_car_search_box .form-control {
    width: 100%;
  }

  .bw_banner_heading {
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .bw_banner_sub_heading {
    font-size: 16px;
    line-height: 22px;
  }

  .bw_excited_to_work_inner h2 {
    font-size: 25px;
    line-height: 60px;
  }

  .bw_excited_to_work_inner p {
    font-size: 16px;
    line-height: 30px;
    padding: 0 15px 30px;
  }

  .bw_car_tech_box h4 {
    font-size: 20px;
    line-height: 35px;
  }

  .bw_car_tech_box p {
    font-size: 16px;
    line-height: 23px;
  }

  .bw_car_tech_box .bw_map_svg {
    font-size: 25px;
  }

  .bw_all_banner {
    padding: 120px 0 70px;
  }

  .bw_excited_to_work {
    padding: 30px 0 10px 0;
  }

  .bw_car_tech_box {
    padding: 35px 25px;
  }

  .bw_car_search_box svg {
    left: 2%;
    font-size: 22px;
  }

  .bw_car_tech_padding {
    padding: 40px 0 30px 0;
  }

  .bw_car_search_box .form-control::placeholder {
    font-size: 16px;
    color: #000;
  }

  .bw_car_tech_box h4 {
    width: 270px;
  }
}

@media (min-width: 1160px) and (max-width: 1360px) {
  .bw_excited_to_work_inner h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .bw_excited_to_work_inner p {
    font-size: 15px;
    line-height: 30px;
    width: 700px;
    padding: 0 15px 40px;
    margin: 0 auto;
  }

  .bw_car_tech_box {
    padding: 20px 20px;
  }

  .bw_car_tech_box h4 {
    font-size: 18px;
    line-height: 35px;
  }

  .bw_car_tech_box p {
    font-size: 14px;
    line-height: 25px;
  }

  .bw_car_tech_box .bw_map_svg {
    font-size: 25px;
  }

  .bw_car_tech_next_box svg {
    font-size: 20px;
  }

  .bw_car_tech_next_box {
    width: 30px;
    height: 30px;
  }

  .bw_car_tech_padding {
    padding: 44px 0;
  }

  .bw_btb_border_black .bw_custom_btn {
    padding: 7px 20px !important;
  }

  .bw_btb_border_black .bw_custom_btn {
    padding: 7px 20px !important;
    font-size: 14px;
  }

  .bw_car_tech_box {
    padding: 30px 20px;
    min-height: 195px;
  }
}


@media(min-width:1100px) and (max-width:1199px) {
  .bw_banner_heading {
    font-size: 35px;
    line-height: 45px;
  }

  .bw_banner_sub_heading {
    font-size: 16px;
    line-height: 23px;
  }
}