.bw_mont_price_box_inner_box.bw_pricing_upperbox_border{
	width: 350px !important;
	margin: 0 auto;
}

.bwn_shopify_page_section {
  /* background-image: url("../images//shopify-img/bg-sp.webp"); */
  width: 100%;
  /* height: 500px; */
  background-color: #3D63C3;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  padding: 80px 0;
  background-position: 100% 100%;
}
.bwn_sopify_text_box_outer {
  display: flex;
  justify-content: left;
  vertical-align: middle;
  align-items: center;
}
.bwn_sopify_text_box_outer_right {
  display: flex;
  vertical-align: middle;
  align-items: start;
  justify-content: center;
}
.bwn_sopify_text_box_outer_right1 {
  display: flex;
  vertical-align: middle;
  align-items: start;
  justify-content: center;
}
.bwn_sopify_text_box_inner h6 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
}
.bwn_sopify_text_box_inner h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 55px;
  color: #fff;
  text-align: center;
}
.bwn_sopify_text_box_inner p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
  width: 85%;
  text-align: center;
  margin: 0 auto 20px;
}
.bwn_sopify_heading {
  padding-bottom: 50px;
}
.bwn_sopify_heading h4 {
  font-size: 46px;
  line-height: 55px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}
.bwn_shopify_outer_box img {
  width: 80%;
  margin-bottom: 30px;
}
.bwn_shopify_logo_in_mobileView {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}
.bwn_wp_logo_in_mobileView {
	position: absolute;
	bottom: 0;
	left: 0;
	border: 0;    
}
/* @media (min-width: 768px) {
  .bwn_shopify_logo_in_mobileView {   
    display: none;
  } 
  .bwn_wp_logo_in_mobileView
  {
	display: none;
  }
} */

@media (min-width: 1140px) {
  .bwn_shopify_logo_in_mobileView img { 
    width: 100px; 
  }
  .bwn_wp_logo_in_mobileView img {
    width: 100px;
  } 
}
@media (min-width: 1800px) {
  .bwn_shopify_logo_in_mobileView img { 
    width: auto; 
  } 
  .bwn_wp_logo_in_mobileView img {
    width: auto; 
  }  
}
@media (min-width: 992px) and (max-width: 1440px) {
  .bwn_shopify_logo_in_mobileView img { 
    width: 85px; 
  }
  .bwn_wp_logo_in_mobileView img {
    width: 85px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .bwn_shopify_logo_in_mobileView img {
    width: 85px; 
  }
  .bwn_wp_logo_in_mobileView img {
    width: 85px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {

  .bwn_plugin_shopify_mobile_view_tab.d_grid .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
    display: grid;
    /* overflow: scroll; */
  }

  .bwn_shopify_plugin_sectionnn
    .bwn_plugin_shopify_mobile_view_tab
    .css-1wf8b0h-MuiTabs-flexContainer {
    /* display: table-caption !important; */
  }
  .bwn_plugin_shopify_mobile_view_tab .css-  {
    display: table-caption !important;
  }
  .bwn_shopify_logo_in_mobileView img {
    width: 85px; 
  }
  .bwn_wp_logo_in_mobileView img {
    width: 85px;
  }
  .bwn_shopify_logo_in_mobileView {
    top: 0;
    right: 0;
  }
  .bwn_shopify_logo_in_mobileView img .btn_none_mobile {
    display: none;
  }
  .bwn_sopify_text_box_inner.bwn_sopify_heading h4 {
    font-size: 28px;
    line-height: 35px;
    padding-top: 20px;
  }
  .bwn_sopify_text_box_inner h6 {
    font-size: 17px;
    line-height: 1.3;
  }
  .bwn_sopify_text_box_inner h4 {
    font-size: 20px;
    line-height: 25px;
  }
  .bwn_sopify_text_box_inner {
    text-align: center;
    margin-top: 30px;
  }
  .bwn_sopify_text_box_inner p {
    font-size: 14px;
    line-height: 25px;
    width: 100%;
  }
  .bwn_sopify_text_box_inner .bw_btb_border_black .bw_custom_btn {
    padding: 8px 20px !important;
    font-size: 14px;
  }

  .bwn_shopify_outer_box img {
    width: 100%;
    margin-bottom: 0px;
  }
  .bwn_shopify_page_section {
    padding: 40px 0 70px;
    background: #3d63c3;
  }
  .bwn_order1 {
    order: 1;
    margin-top: 30px;
  }
  .bwn_order2 {
    order: 2;
  }
  .bwn_sopify_text_box_inner.bwn_sopify_heading {
    padding-bottom: 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bwn_shopify_page_section {
    background-position: 95% 100%;
  }
  .bwn_shopify_outer_box img {
    width: 70%;
  }
  .bw_new_branding_box h2 {
    font-size: 20px;
  }
  .bwn_sopify_text_box_inner h6 {
    font-size: 17px;
    line-height: 1.3;
  }
  .bwn_sopify_text_box_inner.bwn_sopify_heading h4 {
    font-size: 31px;
  }
  .bwn_sopify_heading {
    padding-bottom: 20px;
  }
  .bwn_sopify_text_box_inner h4 {
    font-size: 20px;
    line-height: 35px;
  }
  .bwn_sopify_text_box_inner p {
    font-size: 16px;
    line-height: 25px;
    width: 100%;
  }
  .bwn_sopify_text_box_inner .bw_btb_border_black .bw_custom_btn {
    padding: 8px 20px !important;
    font-size: 14px;
  }
  .bwn_shopify_page_section {
    padding: 50px 0;
  }
}
@media (min-width: 1150px) and (max-width: 1350px) {
  .bwn_shopify_page_section {
    padding: 80px 0;
    background-position: 92% 6%;
  }
  .bwn_shopify_outer_box img {
    width: 60%;
  }
  .bwn_sopify_text_box_inner .bw_btb_border_black .bw_custom_btn {
    padding: 7px 20px !important;
    font-size: 12px !important;
  }
  .bwn_sopify_text_box_inner h6 {
    font-size: 16px;
    line-height: 1.3;
  }
  .bwn_sopify_text_box_inner h4 {
    font-size: 25px;
    line-height: 35px;
  }
  .bwn_sopify_text_box_inner.bwn_sopify_heading h4 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .bwn_sopify_text_box_inner p {
    font-size: 14px;
    line-height: 25px;
    width: 67%;
  }
  .bwn_shopify_page_section {
    padding: 60px 0;
  }
  .bwn_sopify_heading {
    padding-bottom: 20px;
  }
  .bw_setup_Section {
    font-size: 11px;
  }
  /* .bwn_sopify_text_box_outer_right img, .bwn_sopify_text_box_outer_right1 img {
        width: 80%;
} */
}
@media (min-width: 1440px) and (max-width: 1650px) {
  .bw_setup_Section {
    font-size: 11px;
  }
  .bwn_shopify_outer_box img {
    width: 75%;
  }
  .bwn_sopify_text_box_inner.bwn_sopify_heading h4 {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .bwn_sopify_text_box_inner h4 {
    font-size: 25px;
    line-height: 35px;
  }
  .bwn_sopify_text_box_inner h6 {
    font-size: 18px;
    line-height: 1.3;
  }
  .bwn_sopify_text_box_inner p {
    font-size: 14px;
    line-height: 25px;
  }
  .bwn_shopify_page_section {
    padding: 60px 0;
  }
  .bw_btb_border_black .bw_custom_btn {
    padding: 8px 20px !important;
  }
  .bwn_sopify_heading {
    padding-bottom: 20px !important;
  }
}
@media (min-width: 1651px) and (max-width: 1750px) {
  .bwn_sopify_text_box_inner h4 {
    font-size: 40px;
    line-height: 45px;
  }
  .bwn_sopify_text_box_inner h6 {
    font-size: 18px;
    line-height: 1.3;
  }
  .bwn_sopify_text_box_inner p {
    font-size: 16px;
    line-height: 1.3;
    width: 70%;
  }
  .bwn_shopify_page_section {
    padding: 60px 0;
  }
  .bwn_sopify_heading {
    padding-bottom: 20px !important;
  }
}
