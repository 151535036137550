.tab_bar ul li {
    flex: auto !important;
}

.tab_bar ul li button {
    width: 100%;
    border-radius: 0 !important;
    background-color: transparent;
    color: #fff;
    font-weight: 600;

}

.tab_bar .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #000;
}

.socialCard {
    background: #F5F7FC;

}
.btn-outline-white {
    background-color: transparent;
    border-radius: 0;
    color: #fff;
    border: 2px solid #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    width: 238px;
    height: 45px;
    position: relative;
    overflow: hidden;  
    transition: color 0.3s ease-in-out; 
    z-index: 1;
    &:hover{
        color: #000;  
    }

    &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: #fff; 
        transition: width 0.5s ease-in-out; 
        z-index: -1;
    }

    &:hover::before {
        width: 100%;
    }

    

    span {
        position: relative;
        z-index: 10; 
    }
}


/* ______ plan Caard ______ */

.planCard {
    height: 336px;
    position: relative;
    overflow: hidden;
}


.planCard .imgBox {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    &::before {

        right: 100%;
        top: 100%;
        transform-origin: right;
        transform: rotate(132deg);
        content: "";
        position: absolute;
        z-index: 10;
        background-color: #8AACFF;
        width: 469px;
        height: 6px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 304px;
        height: 100%;
        background-color: #3d63c3;
        clip-path: polygon(0 0, 100% 0%, 50% 50%, 0% 100%);


    }
}