.bw_free_plans_box_inner.kap.new_100_doller_white_box {
	background-color: #2c52b0;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box h3 {
	color: #fff;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bw_free_plans_pricing_box {
	background-color: #fff;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box p {
	color: #fff;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bw_free_box_subheading ul li {
	color: #ffffffc4 !important;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bwn_animation_btn.bwn_new_sub_btn {
	background-color: transparent !important;
	color: #fff  !important;
	border-color: #fff  !important;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bwn_animation_btn.bwn_new_sub_btn:hover {
    color: #fff;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bw_free_box_subheading ul li::before {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  background-image: url("../../Assets/images/free-plans-smo/blue-check.png") !important;
  background-size: 14px 14px;
background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bw_free_price {
	font-size: 55px;
}
.bw_free_plans_box_inner.kap.new_100_doller_white_box .bwn_animation_btn {
    color: #fff;
    text-align: center;
    animation: shadow-pulsee 1.5s infinite;
}
@keyframes shadow-pulsee
{
  0% {
    box-shadow: 0 0 0 0px rgba(246, 243, 243, 0.368);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(245, 242, 242, 0.531);
  }
}