@import url("https://fonts.googleapis.com/css2?family=Baskervville:ital@0;1&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Poppins", sans-serif;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(190, 190, 190);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a1a1c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1a1a1c;
}

/*button css and animation*/
.bw_btb_border_white .bw_custom_btn {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #fff !important;
  position: relative;
  z-index: 9;
  padding: 10px 30px;
  color: #fff !important;
}
.bw_btb_border_white .bw_custom_btn:hover,
.bw_btb_border_white .bw_custom_btn:focus,
.bw_btb_border_white .bw_custom_btn:active {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #fff !important;
  box-shadow: none !important;
  outline: none !important;
  color: #000;
}
.bw_btb_border_white .bw_custom_btn:hover {
  color: #000 !important;
}
.bw_btb_border_white .bw_custom_btn:hover:before {
  width: 100% !important;
  transition: all 0.5s !important;
}
.bw_btb_border_white .bw_custom_btn::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  background: #fff !important;
  width: 0% !important;
  height: 100% !important;
  transition: all 0.5s !important;
  top: 0;
  color: #000 !important;
  z-index: -9 !important;
}

.bw_btb_border_black .bw_custom_btn {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #000 !important;
  position: relative;
  z-index: 9;
  padding: 10px 30px !important;
  color: #000 !important;
}
.bw_btb_border_black .bw_custom_btn:hover,
.bw_btb_border_black .bw_custom_btn:focus,
.bw_btb_border_black .bw_custom_btn:active {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #2c52b0 !important;
  box-shadow: none !important;
  outline: none !important;
  color: #fff !important;
}
.bw_btb_border_black .bw_custom_btn:focus {
  color: #000 !important;
}
.bw_btb_border_black .bw_custom_btn:hover {
  color: #fff !important;
}
.bw_btb_border_black .bw_custom_btn:hover:before {
  width: 100% !important;
  transition: all 0.5s !important;
}
.bw_btb_border_black .bw_custom_btn::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  background: #3d63c3 !important;
  border-color: #3d63c3;
  width: 0% !important;
  height: 100% !important;
  transition: all 0.5s !important;
  top: 0;
  color: #fff !important;
  z-index: -9 !important;
}

.colorBlack{
  color: #000 ;
}
.card-info {
  background: #fff;
  padding:13.3px 20px;
}
.bw_contact_us_form button:hover {
  background: #0c276a;
  border-color: #0c276a;
}
.bgDarkBlue{
  background: #2D3C5C;
}
.colorWhite{
  color: #fff;
}
.fs35{
  font-size: 35px;
} 
.fw600{
  font-weight: 600;
}
.formWrapper {
  background: #414F6B;
  padding: 7% 15%;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.radius10{
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.radius20{
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.input-control input{
  border: none;
  height: 54px;
}
.input-control textarea{
  border: none;
  min-height: 145px;
}
.submitSection{
  padding: 45px;
  background: #ffffff;
box-shadow: 0px 4px 85px rgba(0, 0, 0, 0.09);
}
.transition{
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
body button.btnBlue{
  background: #385fbf;
  color: #fff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  font-weight: 700;
  font-size: 15px;
  border: none;
  padding: 10px 30px !important;
  width: auto !important;
  height: auto !important;
  
}
.btnBlue:hover{
  background: #414f6b;
}
/*checkbox*/
.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #a5a5a5;
}

.containerCheck:hover input ~ .checkmark {
  background-color: #2D3C5C;
}
.containerCheck input:checked ~ .checkmark {
  background-color: #2D3C5C;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkText {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #000;
  top: 1px;
}
.checkText a {
  color: #000;
  text-decoration: underline;
}
/*checkbox*/

.apiSecUnder {
  margin-top: 80px;
  background: #fff;
  border-radius: 80px 80px 0px 0px;
  padding: 60px 45px;
  box-shadow: 0px 4px 85px rgba(0, 0, 0, 0.09);
}
.colorBlue{
  color: #385fbf;
}
.belgium{
  color: #385fbf;
}
.webnet{
  color: #D72765;
}

.pinkBtn {
  background: #FF679A !important;
}


.bgDarkBlue1{
  background: #f5f7fc;
}
.mob_niv {
	position: absolute;
	bottom: -6px;
	width: 20%;
	z-index: 9;
}
.fs15{
  font-size: 15px;
}