.bwn_client_mobile_accor{
    box-shadow: none !important;
}
.bwn_client_mobile_accor_heading{
   text-align: center !important;
width: 100% !important;
margin: 0 auto !important;
}
.bwn_client_mobile_accor_detail{
    text-align: center !important;
width: 100% !important;
margin: 0 auto !important;
}
.bwn_client_mobile_accor_summery p{
text-align: center !important;
width: 100% !important;
margin: 0 auto !important;
font-family: 'Poppins' !important;
font-style: normal;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 20px !important;
line-height: 65px !important;
}
.bwn_client_mobile_accor .css-o4b71y-MuiAccordionSummary-content{
font-family: 'Poppins';
font-style: normal;
font-weight: 500 !important;
font-size: 20px !important;
line-height: 20px !important;
text-align: center;
color: #001100;
}
.bwn_client_mobile_accor .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
	margin: 20px 0;
	color: #3D63C3 !important;
	border-bottom: 2px solid #3D63C3;
}
.bwn_client_slider_inner_box_mobile_view{
    background-color: #fff;
    
}
.bwn_client_slider_inner_box_mobile_view {
	background: #FFFFFF;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 30px 15px;
	margin: 0 auto;
}
.bwn_client_slider_inner_box_mobile_view img {
	width: 130px;
	height: auto;
	margin: 0 auto;
}
.bwn_accordian_detaial_slider_mobile_view_outer_boxxx .bw_box_padding {
	padding: 10px 20px;
}
.bwn_client_mobile_accor_detail .slick-arrow.slick-next {
	display: none !important;
}
.bwn_client_mobile_accor_summery .css-15v22id-MuiAccordionDetails-root{
padding: 0 !important;
}
.bwn_client_mobile_accor .MuiAccordionDetails-root{
    padding: 0 !important;
}
.bwn_client_tab_logo ul li img {
    width: 150px;
    height: 55px;
    object-fit: contain;
    margin: 0 auto;
    filter: grayscale(1) !important;
}
.bwn_client_tab_logo ul li img:hover {
    filter: grayscale(0) !important;
}


@media(max-width:767px){
	.bwn_clint_logo_tabs_design_bbox_prtfolio {
	display: none;
}
}

@media(min-width:320px) and (max-width:767px){
.bwn_client_slider_inner_box_mobile_view {
	background: #FFFFFF;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 30px 15px;
	margin: 0 auto;
	width: 100%;
	height: 180px;
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}
}

@media (min-width:1150px) and (max-width:1440px) {

	.bwn_client_tab_logo ul li img {
	  width: 100px !important;
	  height: 40px !important;
  }
  }
  
@media (min-width:1440px) and (max-width:1900px) {
	.insta-iframe {
	  height: 460px;
	}
	.bwn_client_tab_logo ul li img {
	  width: 100px !important;
	  height: 40px !important;
  }
  }