.bw_carrersDetails_contain_box {
  padding: 50px 0 30px 0;
}
.bw_carrersDetails_contain_box h6 {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  margin: 20px 0;
}
.bw_carrersDetails_contain_box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  text-align: justify;
}
.bw_carrersDetails_contain_box ul li {
  list-style: disc;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  text-align: justify;
  font-family: "Poppins" !important;
}

/*----- Form stlye------ */
.bw_careerDetails_form_section .react-tel-input .form-control {
  width: 100%;
}
.bw_careerDetails_form_section .form-control small {
  font-size: 12px;
}

.bw_careerDetails_form_section input[type="file"]::file-selector-button {
  border: 1px solid transparent;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background: #f3f3f3;
  transition: 1s;
  cursor: pointer;
}
.bw_careerDetails_form_section input#formFile {
  line-height: 22px !important;
}

/*---------- Responsive Style -------- */

@media (min-width: 320px) and (max-width: 767px) {
  .bw_carrersDetails_contain_box p {
    font-size: 16px;
    line-height: 32px;
  }
  .bw_banner_careerdetails_heading {
    font-size: 30px;
  }
  .bw_carrersDetails_contain_box h6 {
    font-size: 20px;
    line-height: 30px;
    margin: 15px 0;
  }
  .bw_carrersDetails_contain_box ul li {
    font-size: 15.5px;
    line-height: 30px;
  }
  .bw_carrersDetails_contain_box {
    padding: 20px 0 10px 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_banner_careerdetails_heading {
    font-size: 30px !important;
    line-height: 45px;
  }
  .bw_carrersDetails_contain_box p {
    font-size: 15px;
    line-height: 30px;
  }
  .bw_carrersDetails_contain_box h6 {
    font-size: 20px;
    line-height: 30px;
    margin: 15px 0;
  }
  .bw_carrersDetails_contain_box ul li {
    font-size: 15.5px;
    line-height: 30px;
  }
  .bw_carrersDetails_contain_box {
    padding: 30px 0 20px 0;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_carrersDetails_contain_box p {
    font-size: 14px;
    line-height: 30px;
  }
  .bw_carrersDetails_contain_box h6 {
    font-size: 20px;
    line-height: 30px;
    margin: 15px 0;
  }
  .bw_carrersDetails_contain_box ul li {
    font-size: 14.5px;
    line-height: 30px;
  }
}
