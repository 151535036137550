.bw_contact_banner.bw_banner_page_notfound {
  background-color: #fff !important;
  background-image: url(../../Assets/images/home/bg-green-top-banner.webp);
  height: 250px;
}

.bw_page_not_found_img_width {
  width: 600px !important;
  margin: 50px 0;
}

section.bw_pagenot_found_section.bw-404 .bw_all_banner.bw_contact_banner.bw_banner_page_notfound {
  height: max-content !important;
  padding: 55px 0px;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  section.bw_pagenot_found_section.bw-404 .bw_all_banner.bw_contact_banner.bw_banner_page_notfound {
    padding: 34px 0px;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_page_not_found_img_width {
    width: 500px !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  section.bw_pagenot_found_section.bw-404 .bw_all_banner.bw_contact_banner.bw_banner_page_notfound {
    height: max-content !important;
    padding: 46px 0px;
  }

  section.bw_header_section {
    padding: 5px 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  section.bw_pagenot_found_section.bw-404 .bw_all_banner.bw_contact_banner.bw_banner_page_notfound {
    height: max-content !important;
    padding: 50px 0px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_contact_banner.bw_banner_page_notfound {
    height: 320px;
  }
  section.bw_pagenot_found_section.bw-404 .bw_all_banner.bw_contact_banner.bw_banner_page_notfound {
    padding: 53px 0px;
}
}