.bw_consultation_banner {
  background-position: bottom !important;
  justify-content: center;
}
.phone_field_error{
  color: red;
  font-size: 12px;
}
.bw_consulation_from_position {
  margin-top: -372px;
}
.bw_consultation_banner p {
  font-size: 20px !important;
  color: #fff;
  line-height: 35px;
}
.bw_consultation_banner h1 {
  font-weight: 800 !important;
}
.bw_consultation_banner_img {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: right;
}
.bw_consultation_banner_text {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  vertical-align: middle !important;
}

.bw_consulation_from_position select.mb-3.form-control {
  padding: 10px 10px;
}
.bw_consulation_from_position .css-g1d714-ValueContainer {
	padding: 7px 10px !important;
}
.bw_consulation_from_position .css-1pahdxg-control {
  border-color: #00000012 !important;
}
 .bw_time_zone .css-yk16xz-control.css-g1d714-ValueContainer{
      padding: 6px 10px !important;
 }
 .bw_time_zone .css-1hwfws3 {
    padding: 6px 8px !important;
}
.bw_time_zone .css-yk16xz-control {
    border-color:#00000012 !important;
}
.bw_time_zone .css-yk16xz-control:hover {
    border-color: #ced4da;
}
.bw_contact_us_form .react-tel-input .flag-dropdown {
    background-color: #fff !important;
}


.bw_consulation_from_position .css-1pahdxg-control,
.css-1pahdxg-control:hover,
.css-1pahdxg-control:active,
.css-1pahdxg-control:focus {
  border-color: #ced4da !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.bw_consultation_banner_img img {
	width: 70%;
}

.bwn_consultation_form_checkbox .MuiFormControlLabel-label.css-9l3uo3{
  font-family: 'poppins' !important;
  font-size: 15px !important;
}
.bwn_consultation_form_checkbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color:#2c52b0 !important;
}

.bwn_consultation_form_checkbox .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
	font-size: 15px !important;
	font-family: 'poppins' !important;
	color: #000;
	line-height: 30px !important;
}


@media (min-width: 320px) and (max-width: 767px) {
  .bwn_consultation_form_checkbox .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    font-size: 14px !important;
    line-height: 23px !important;
  }
.bw_consultation_banner_img img {
	width: 60%;
	margin: 0 auto;
}
  .bw_consultation_banner h1 {
    font-size: 30px;
    text-align: center !important;
  }
  .bw_consultation_banner p {
    font-size: 18px !important;
    line-height: 30px;
  }
  .bw_consulation_from_position select.mb-3.form-control {
    padding: 9px 10px;
  }
  .bw_consulation_from_position .css-g1d714-ValueContainer {
    padding: 3px 10px;
  }
  .bw_consultation_banner_text {
    text-align: center;
  }
 .bw_contact_banner.bw_consultation_banner {
	background-position: bottom !important;
	height: 500px;
}
.bw_consulation_from_position {
	margin-top: -225px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_consultation_banner h1 {
    font-size: 35px;
  }
  .bw_consulation_from_position select.mb-3.form-control {
    padding: 9px 10px;
  }
  .bw_consulation_from_position .css-g1d714-ValueContainer {
    padding: 3px 10px;
  }
.bw_consulation_from_position {
	margin-top: -410px;
}
.bw_contact_banner.bw_consultation_banner {
	background-position: bottom !important;
	height: 640px;
}
  .bwn_consultation_form_checkbox .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
	font-size: 14px !important;
}
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bwn_consultation_form_checkbox .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
	font-size: 12px !important;
	line-height: 20px !important;
}
.bw_contact_banner.bw_consultation_banner {
	height: 570px;
}
  .bw_new_position_box.bw_consulation_from_position {
    margin-top: -345px;
  }
  .bw_consultation_banner h1 {
    font-size: 31px;
    width: 100%;
  }
  .bw_consulation_from_position .css-yk16xz-control {
    max-height: 33px !important;
    min-height: 33px !important;
  }
  .bw_consulation_from_position .css-1wa3eu0-placeholder {
    top: 33% !important;
  }
  .bw_consulation_from_position .css-2b097c-container {
    max-height: 16px !important;
    min-height: 16px !important;
  }
  .bw_consulation_from_position .css-1okebmr-indicatorSeparator {
    margin-bottom: 7px;
    margin-top: 5px;
    height: 25px;
  }
  .bw_consulation_from_position .css-g1d714-ValueContainer {
    padding: 2px 10px;
    font-size: 12px;
    min-height: 33px !important;
  }
  .bw_consulation_from_position .css-1uccc91-singleValue {
    top: 40%;
  }
  .bw_consulation_from_position .css-tlfecz-indicatorContainer {
    padding: 5px 5px 10px;
    font-size: 12px;
  }
  .bw_consulation_from_position .css-yk16xz-control {
    max-height: 20px !important;
    padding: 0 !important;
  }
.bw_consultation_banner_img img {
	width: 50%;
}
  .bw_consultation_banner_text {
    justify-content: center!important;
}
}
@media (min-width: 1440px) and (max-width: 1650px) {
.bwn_consultation_form_checkbox .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    font-size: 12px !important;
    line-height: 20px !important;
}
}