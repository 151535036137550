.bwn_protfolio_client_section .bwn_clients_images {
  display: inline-block;
  text-align: center !important;
  margin: 0 auto;
}
.bwn_clients_images:hover {
  transition: all 0.5s !important;
}
.bwn_client_img_style {
  width: 150px;
  margin: 20px;
}
.bw_portfolio_slider_mobile .slick-prev,
.bw_portfolio_slider_mobile .slick-next {
  width: 40px !important;
  height: 40px !important;
  z-index: 1;
}

.bw_portfolio_slider_mobile .slick-slide img {
  margin: 0 auto;
}
.bw_portfolio_img_outer_box_height{
  min-height: 300px;
}
/* tabs design  */
/* .bwn_our_clint_tab_section_box{
	min-height: 430px;
} */
.bwn_clint_logo_tabs_design_bbox_prtfolio ul{
  text-align: center !important;
  margin: 50px auto 20px;
}
.bwn_clint_logo_tabs_design_bbox_prtfolio ul li {
	display: inline-block !important;
	width: 15% !important;
	margin: 0 7.5px;
}

.bwn_clint_logo_tabs_design_bbox ul{
  margin: 0 auto;
  text-align: center;
}
.bwn_clint_logo_tabs_design_bbox ul li{
  display: inline-block;
  width: 20%;
}

.bwn_client_tab_btton {
  width:100%;
  background-color: transparent;
  border: 1px solid #2c52b0;
  margin: 0 5px;
  padding: 10px 15px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.bwn_client_tab_btton:hover,
.bwn_client_tab_btton:active,
.bwn_client_tab_btton:focus {
  background-color: #2c52b0;
  color: #fff;
}
.bwn_clint_logo_tabs_design_bbox ul li {
    display: inline-block;
    width: 30%;
    margin: 0 7.5px 15px;
    text-align: center;
}

.bwn_protfolio_client_section ul{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    place-items: center;
    justify-content: center;
    align-items: center;
}
.bwn_client_tab_logo ul {
	width: 100%;
	margin: 0 auto;
}

.bw_portfolio_desktop_tab .bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
    /* border: 1px solid #E2E2E2; */
    width: max-content;
    margin: 0 auto
}
/* server style start */
.bwn_client_mobile_accor .css-17o5nyn.Mui-expanded {
	color: #2c52b0;
	border-bottom: 2px solid #2c52b0;
}
.bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1l4w6pd {
    border-bottom: 1px solid #ccc!important;
    width: max-content !important;
    margin: 0 auto !important;
}
.bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 77px !important;
    font-weight: 600!important;
    font-size: 18px!important;
    text-transform: capitalize!important;
}
.bwn_client_slioder_only_home{
  display: none;
}
.bwn_client_tab_logo ul li img{
      filter: unset;
}
.bw_portfolio_desktop_tab .bwn_client_tab_logo ul li img {
	filter: grayscale(0) !important;
}
/******************* Responsive Style *************************/
@media (min-width: 320px) and (max-width: 767px) {
  .bwn_client_slider_inner_box_mobile_view img {
	width: 160px;
}
  .bwn_client_tab_logo ul {
	width: 100%;
	margin: 0 auto;
}
  .bw_client_logo_imgs {
	min-height: 120px;
} 

.bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 40px !important;
    font-weight: 600!important;
    font-size: 18px!important;
    text-transform: capitalize!important;
}
    .bwn_clint_logo_tabs_design_bbox_prtfolio ul{
      text-align: center;
    }
  .bwn_clint_logo_tabs_design_bbox_prtfolio ul li {
	width: 90% !important;
	margin-bottom: 15px;
}
  .bwn_client_img_style {
    padding-bottom: 0;
    margin-bottom: 20px;
  }
  .bw_portfolio_slider_mobile .slick-next {
    right: 5px !important;
  }
  .bw_portfolio_slider_mobile .slick-prev {
    left: 5px !important;
  }
  .bwn_client_tab_btton {
    width: 100%;
    margin: 0;
  }
.bwn_clint_logo_tabs_design_bbox ul li {
    width: 100%;
    margin: 0 auto 15px;
}
}
/* home client logo tab style */
.bwn_client_tab_btton.bwn_client_logo_btn_home {
  width: 100%;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
}
.bwn_client_tab_btton.bwn_client_logo_btn_home:active,
.bwn_client_tab_btton.bwn_client_logo_btn_home:focus,
.bwn_client_tab_btton.bwn_client_logo_btn_home:hover {
  color: #3d63c3;
  border-color: #3d63c3;
}

/* server css start */
.bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1l4w6pd {
    border-bottom: 1px solid #ccc !important;
}
/* .bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 55px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
} */
.bwn_client_mobile_accor .css-17o5nyn.Mui-expanded {
	margin: 10px 0 !important;
	color: #2c52b0 !important;
	border-bottom: 2px solid #2c52b0 !important;
}



@media (min-width: 768px) and (max-width: 1024px) {
.bwn_clint_logo_tabs_design_bbox ul li {
    display: inline-block;
    width: 17%;
    margin: 0 7.5px 15px;
    text-align: center;
}
  .bwn_protfolio_client_section .bwn_clients_images {
    display: inline-block;
    text-align: center !important;
    margin: 0 auto;
  }
  .bwn_protfolio_client_section ul {
	grid-template-columns: repeat(3, 1fr);
}
.bwn_client_img_style {
	width: 120px;
}
.bw_portfolio_img_outer_box_height {
	min-height: 250px;
}
.bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 35px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}
/* .bwn_our_clint_tab_section_box.mt-5 {
	min-height: 300px;
} */
.bw_portfolio_desktop_tab .bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
	width: max-content;
}
.bw_portfolio_desktop_tab ul.bw_client_logo_img_ul li {
	width: 25%;
}
}
@media(min-width:1200px) and (max-width:1440px){
  .bw_portfolio_desktop_tab .bwn_our_clint_tab .MuiTabs-flexContainer.MuiTabs-centered.css-1wf8b0h-MuiTabs-flexContainer {
	width:max-content;
}
.bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 55px!important;
}
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .bwn_client_img_style {
    width: 110px !important;
    padding-bottom: 50px !important;
  }
}
@media(min-width:1150px) and (max-width:1350px){
  .bwn_our_clint_tab_button.css-1q2h7u5 {
    padding: 12px 55px!important;
}
}