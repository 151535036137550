.bw_paid_plans_checkbox_outer {
  text-align: center;
}
.bw_paid_plans_checkbox_outer h6 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 20px auto;
}
.bw_paid_plans_checkbox_outer
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #2c52b0 !important;
}
.bw_paid_plans_checkbox_outer
  .css-1f2kvjf-MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-size: 18px;
  color: #000;
  line-height: 50px;
  font-weight: 400 !important;
  font-family: "poppins" !important;
}
.bw_contact_us_form label {
  font-family: "poppins" !important;
}
.bw_paid_plans_checkbox_outer
  label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart.css-1f2kvjf-MuiFormControlLabel-root {
  background-color: #cccccc29 !important;
  padding: 2px 20px !important;
  border-radius: 6px !important;
  margin: 20px 10px !important;
  justify-content: space-between !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .bw_paid_plans_checkbox_outer h6 {
    font-size: 18px;
    margin: 20px auto;
  }
  .bw_paid_plans_checkbox_outer
    label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart.css-1f2kvjf-MuiFormControlLabel-root {
    margin: 10px auto !important;
    width: 220px !important;
    justify-content: space-between !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .bw_paid_plans_checkbox_outer h6 {
    font-size: 16px !important;
    margin: 15px auto;
  }
  .bw_paid_plans_checkbox_outer
    .css-1f2kvjf-MuiFormControlLabel-root
    .MuiFormControlLabel-label {
    font-size: 17px;
    line-height: 40px;
  }
}

@media (min-width: 1150px) and (max-width: 1350px) {
  .bw_paid_plans_checkbox_outer h6 {
    font-size: 15px;
    margin: 10px auto;
  }
  .bw_paid_plans_checkbox_outer
    .css-1f2kvjf-MuiFormControlLabel-root
    .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 30px;
  }
  .bw_paid_plans_checkbox_outer
    .css-1f2kvjf-MuiFormControlLabel-root
    .MuiFormControlLabel-label {
    font-size: 15px;
    line-height: 30px;
  }
  .bw_paid_plans_checkbox_outer
    label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart.css-1f2kvjf-MuiFormControlLabel-root {
    padding: 2px 20px !important;
    margin: 15px 10px !important;
  }
  .bw_consultation_banner_img {
    justify-content: right !important;
  }
}
